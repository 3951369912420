<template>
    <div class="container-fluid companiesconfiguration">
        <div class="general_configuration">
            <div class="general_configuration_details">
                <div class="top_pannel">
                    <div class="heading">
                        <h3>Companies Configuration</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex justify-content-end">
                                <button type="button" class="default_btn" @click="communityFilters()">Community Filters</button>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Template:</label>
                                            <div class="radio-box">
                                                <ul>
                                                    <li>
                                                        <input type="radio" id="f-option" name="selector">
                                                        <label for="f-option">Template 1</label>
                                                        <div class="check"></div>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="s-option" name="selector">
                                                        <label for="s-option">Template 2</label>
                                                        <div class="check"><div class="inside"></div></div>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="t-option" name="selector">
                                                        <label for="t-option">Template 3</label>
                                                        <div class="check"><div class="inside"></div></div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>                                                        
                                </div>
                                <div class="row template_one">
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Template One View:</label>
                                            <div class="radio-box">
                                                <ul>
                                                    <li>
                                                        <input type="radio" id="f-option" name="selector">
                                                        <label for="f-option">Normal</label>
                                                        <div class="check"></div>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="s-option" name="selector">
                                                        <label for="s-option">Advance</label>
                                                        <div class="check"><div class="inside"></div></div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Show Available Unit?:</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row template_two">
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Template 2 Options:</label>
                                            <div class="radio-box">
                                                <ul>
                                                    <li>
                                                        <input type="radio" id="f-option" name="selector">
                                                        <label for="f-option">Half Width Map</label>
                                                        <div class="check"></div>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="s-option" name="selector">
                                                        <label for="s-option">Full Width Map</label>
                                                        <div class="check"><div class="inside"></div></div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Property Phone No Show/Hide on Community List:</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Search Filter Position:</label>
                                            <div class="radio-box">
                                                <ul>
                                                    <li>
                                                        <input type="radio" id="f-option" name="selector">
                                                        <label for="f-option">Horizontal</label>
                                                        <div class="check"></div>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="s-option" name="selector">
                                                        <label for="s-option">Vertical</label>
                                                        <div class="check"><div class="inside"></div></div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row template_three">
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Search Filter Position:</label>
                                            <div class="radio-box">
                                                <ul>
                                                    <li>
                                                        <input type="radio" id="f-option" name="selector">
                                                        <label for="f-option">Horizontal</label>
                                                        <div class="check"></div>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="s-option" name="selector">
                                                        <label for="s-option">Vertical</label>
                                                        <div class="check"><div class="inside"></div></div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Community Card Layout:</label>
                                            <div class="radio-box">
                                                <ul>
                                                    <li>
                                                        <input type="radio" id="f-option" name="selector">
                                                        <label for="f-option">Layout 1</label>
                                                        <div class="check"></div>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="s-option" name="selector">
                                                        <label for="s-option">Layout 2</label>
                                                        <div class="check"><div class="inside"></div></div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Corner Design:</label>
                                            <div class="radio-box">
                                                <ul>
                                                    <li>
                                                        <input type="radio" id="f-option" name="selector">
                                                        <label for="f-option">Round Corner</label>
                                                        <div class="check"></div>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="s-option" name="selector">
                                                        <label for="s-option">Round Corner</label>
                                                        <div class="check"><div class="inside"></div></div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Floor Plan Card Layout:</label>
                                            <div class="radio-box">
                                                <ul>
                                                    <li>
                                                        <input type="radio" id="f-option" name="selector">
                                                        <label for="f-option">Layout 1</label>
                                                        <div class="check"></div>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="s-option" name="selector">
                                                        <label for="s-option">Layout 2</label>
                                                        <div class="check"><div class="inside"></div></div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>View List By Position:</label>
                                            <div class="radio-box">
                                                <ul>
                                                    <li>
                                                        <input type="radio" id="f-option" name="selector">
                                                        <label for="f-option">In Filter</label>
                                                        <div class="check"></div>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="s-option" name="selector">
                                                        <label for="s-option">Right</label>
                                                        <div class="check"><div class="inside"></div></div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>View List By Right Side Position:</label>
                                            <div class="radio-box">
                                                <ul>
                                                    <li>
                                                        <input type="radio" id="f-option" name="selector">
                                                        <label for="f-option">Top</label>
                                                        <div class="check"></div>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="s-option" name="selector">
                                                        <label for="s-option">Bottom</label>
                                                        <div class="check"><div class="inside"></div></div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Right Panel Top Template Layout:</label>
                                            <div class="radio-box">
                                                <ul>
                                                    <li>
                                                        <input type="radio" id="f-option" name="selector">
                                                        <label for="f-option">Layout 1</label>
                                                        <div class="check"></div>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="s-option" name="selector">
                                                        <label for="s-option">Layout 2</label>
                                                        <div class="check"><div class="inside"></div></div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Background Type:</label>
                                            <div class="radio-box">
                                                <ul>
                                                    <li>
                                                        <input type="radio" id="f-option" name="selector">
                                                        <label for="f-option">Image</label>
                                                        <div class="check"></div>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="s-option" name="selector">
                                                        <label for="s-option">Color</label>
                                                        <div class="check"><div class="inside"></div></div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Background Overlay Opacity:</label>
                                            <Multiselect v-model="Overlay.value" v-bind="Overlay" placeholder="Set Overlay" />
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Button1 Ordering:(By Community)</label>
                                            <Multiselect v-model="Ordering.value" v-bind="Ordering" placeholder="1" />
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Button1 Default Select:(By Community)</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Button2 Ordering:(By Location)</label>
                                            <Multiselect v-model="Ordering.value" v-bind="Ordering" placeholder="1" />
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Button2 Default Select:(By Location)</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Map Button Toogle:</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Map Button Toogle:</label>
                                            <Multiselect v-model="Ordering.value" v-bind="Ordering" placeholder="1" />
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Map Button Default Select:</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Map Button Open in new window:</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Map Button Url:</label>
                                            <input type="text" placeholder="Enter link here">
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Background Image:</label>
                                            <div class="file_wrapper">
                                                <input type="file" accept="image/*" @change="previewImage" class="form-control-file" id="my-file" />
                                                <div class="preview_Show" @click="openpreviewBox()"><span class="icon"><svg fill="#000000" width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M30 3.25h-28c-0.414 0-0.75 0.336-0.75 0.75v0 24c0 0.022 0.011 0.041 0.013 0.063 0.012 0.103 0.040 0.198 0.082 0.284l-0.002-0.005c0.050 0.087 0.11 0.162 0.181 0.225l0.001 0.001c0.017 0.015 0.027 0.035 0.046 0.048 0.015 0.010 0.033 0.006 0.048 0.015 0.107 0.071 0.238 0.115 0.378 0.119l0.001 0 0.002 0.001h28.001c0.414-0 0.75-0.336 0.75-0.75v0-24c-0-0.414-0.336-0.75-0.75-0.75v0zM29.25 4.75v14.771l-6.625-9.937c-0.144-0.191-0.37-0.313-0.625-0.313s-0.481 0.122-0.624 0.311l-0.001 0.002-7.545 11.318-4.361-3.488c-0.127-0.102-0.29-0.164-0.468-0.164-0.253 0-0.477 0.126-0.613 0.318l-0.002 0.002-5.636 8.051v-20.871zM3.44 27.25l5.716-8.164 4.375 3.5c0.121 0.104 0.28 0.167 0.454 0.167 0.046 0 0.091-0.004 0.135-0.013l-0.004 0.001c0.214-0.033 0.394-0.153 0.507-0.322l0.002-0.003 7.376-11.063 7.25 10.876v5.021zM8 12.75c1.519 0 2.75-1.231 2.75-2.75s-1.231-2.75-2.75-2.75c-1.519 0-2.75 1.231-2.75 2.75v0c0.002 1.518 1.232 2.748 2.75 2.75h0zM8 8.75c0.69 0 1.25 0.56 1.25 1.25s-0.56 1.25-1.25 1.25c-0.69 0-1.25-0.56-1.25-1.25v0c0.001-0.69 0.56-1.249 1.25-1.25h0z"></path></svg></span> Image Preview</div>
                                                <div class="preview_box border p-2 mt-3" id="previewBox">
                                                    <div class="heading">Preview Here: <div class="close"><svg width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="icomoon-ignore"></g><path d="M6.576 6.576c-5.205 5.205-5.205 13.643 0 18.849s13.643 5.205 18.849-0c5.206-5.206 5.206-13.643 0-18.849s-13.643-5.205-18.849 0zM24.67 24.67c-4.781 4.781-12.56 4.781-17.341 0s-4.781-12.56 0-17.341c4.781-4.781 12.56-4.781 17.341 0s4.78 12.56-0 17.341z" fill="#000000"></path><path d="M10.722 9.969l-0.754 0.754 5.278 5.278-5.253 5.253 0.754 0.754 5.253-5.253 5.253 5.253 0.754-0.754-5.253-5.253 5.278-5.278-0.754-0.754-5.278 5.278z" fill="#000000"></path></svg></div></div>
                                                    <template v-if="preview">
                                                        <img :src="preview" class="img-fluid" />
                                                        <p class="mb-0">File name: {{ image.name }}</p>
                                                        <p class="mb-0">Size: {{ image.size/1024 }}KB</p>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Loading Image:</label>
                                            <div class="file_wrapper">
                                                <input type="file" accept="image/*" @change="previewImage" class="form-control-file" id="my-file" />
                                                <div class="preview_Show" @click="openpreviewBox()"><span class="icon"><svg fill="#000000" width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M30 3.25h-28c-0.414 0-0.75 0.336-0.75 0.75v0 24c0 0.022 0.011 0.041 0.013 0.063 0.012 0.103 0.040 0.198 0.082 0.284l-0.002-0.005c0.050 0.087 0.11 0.162 0.181 0.225l0.001 0.001c0.017 0.015 0.027 0.035 0.046 0.048 0.015 0.010 0.033 0.006 0.048 0.015 0.107 0.071 0.238 0.115 0.378 0.119l0.001 0 0.002 0.001h28.001c0.414-0 0.75-0.336 0.75-0.75v0-24c-0-0.414-0.336-0.75-0.75-0.75v0zM29.25 4.75v14.771l-6.625-9.937c-0.144-0.191-0.37-0.313-0.625-0.313s-0.481 0.122-0.624 0.311l-0.001 0.002-7.545 11.318-4.361-3.488c-0.127-0.102-0.29-0.164-0.468-0.164-0.253 0-0.477 0.126-0.613 0.318l-0.002 0.002-5.636 8.051v-20.871zM3.44 27.25l5.716-8.164 4.375 3.5c0.121 0.104 0.28 0.167 0.454 0.167 0.046 0 0.091-0.004 0.135-0.013l-0.004 0.001c0.214-0.033 0.394-0.153 0.507-0.322l0.002-0.003 7.376-11.063 7.25 10.876v5.021zM8 12.75c1.519 0 2.75-1.231 2.75-2.75s-1.231-2.75-2.75-2.75c-1.519 0-2.75 1.231-2.75 2.75v0c0.002 1.518 1.232 2.748 2.75 2.75h0zM8 8.75c0.69 0 1.25 0.56 1.25 1.25s-0.56 1.25-1.25 1.25c-0.69 0-1.25-0.56-1.25-1.25v0c0.001-0.69 0.56-1.249 1.25-1.25h0z"></path></svg></span> Image Preview</div>
                                                <div class="preview_box border p-2 mt-3" id="previewBox">
                                                    <div class="heading">Preview Here: <div class="close"><svg width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="icomoon-ignore"></g><path d="M6.576 6.576c-5.205 5.205-5.205 13.643 0 18.849s13.643 5.205 18.849-0c5.206-5.206 5.206-13.643 0-18.849s-13.643-5.205-18.849 0zM24.67 24.67c-4.781 4.781-12.56 4.781-17.341 0s-4.781-12.56 0-17.341c4.781-4.781 12.56-4.781 17.341 0s4.78 12.56-0 17.341z" fill="#000000"></path><path d="M10.722 9.969l-0.754 0.754 5.278 5.278-5.253 5.253 0.754 0.754 5.253-5.253 5.253 5.253 0.754-0.754-5.253-5.253 5.278-5.278-0.754-0.754-5.278 5.278z" fill="#000000"></path></svg></div></div>
                                                    <template v-if="preview">
                                                        <img :src="preview" class="img-fluid" />
                                                        <p class="mb-0">File name: {{ image.name }}</p>
                                                        <p class="mb-0">Size: {{ image.size/1024 }}KB</p>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Map Marker Icon:</label>
                                            <div class="file_wrapper">
                                                <input type="file" accept="image/*" @change="previewImage" class="form-control-file" id="my-file" />
                                                <div class="preview_Show" @click="openpreviewBox()"><span class="icon"><svg fill="#000000" width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M30 3.25h-28c-0.414 0-0.75 0.336-0.75 0.75v0 24c0 0.022 0.011 0.041 0.013 0.063 0.012 0.103 0.040 0.198 0.082 0.284l-0.002-0.005c0.050 0.087 0.11 0.162 0.181 0.225l0.001 0.001c0.017 0.015 0.027 0.035 0.046 0.048 0.015 0.010 0.033 0.006 0.048 0.015 0.107 0.071 0.238 0.115 0.378 0.119l0.001 0 0.002 0.001h28.001c0.414-0 0.75-0.336 0.75-0.75v0-24c-0-0.414-0.336-0.75-0.75-0.75v0zM29.25 4.75v14.771l-6.625-9.937c-0.144-0.191-0.37-0.313-0.625-0.313s-0.481 0.122-0.624 0.311l-0.001 0.002-7.545 11.318-4.361-3.488c-0.127-0.102-0.29-0.164-0.468-0.164-0.253 0-0.477 0.126-0.613 0.318l-0.002 0.002-5.636 8.051v-20.871zM3.44 27.25l5.716-8.164 4.375 3.5c0.121 0.104 0.28 0.167 0.454 0.167 0.046 0 0.091-0.004 0.135-0.013l-0.004 0.001c0.214-0.033 0.394-0.153 0.507-0.322l0.002-0.003 7.376-11.063 7.25 10.876v5.021zM8 12.75c1.519 0 2.75-1.231 2.75-2.75s-1.231-2.75-2.75-2.75c-1.519 0-2.75 1.231-2.75 2.75v0c0.002 1.518 1.232 2.748 2.75 2.75h0zM8 8.75c0.69 0 1.25 0.56 1.25 1.25s-0.56 1.25-1.25 1.25c-0.69 0-1.25-0.56-1.25-1.25v0c0.001-0.69 0.56-1.249 1.25-1.25h0z"></path></svg></span> Image Preview</div>
                                                <div class="preview_box border p-2 mt-3" id="previewBox">
                                                    <div class="heading">Preview Here: <div class="close"><svg width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="icomoon-ignore"></g><path d="M6.576 6.576c-5.205 5.205-5.205 13.643 0 18.849s13.643 5.205 18.849-0c5.206-5.206 5.206-13.643 0-18.849s-13.643-5.205-18.849 0zM24.67 24.67c-4.781 4.781-12.56 4.781-17.341 0s-4.781-12.56 0-17.341c4.781-4.781 12.56-4.781 17.341 0s4.78 12.56-0 17.341z" fill="#000000"></path><path d="M10.722 9.969l-0.754 0.754 5.278 5.278-5.253 5.253 0.754 0.754 5.253-5.253 5.253 5.253 0.754-0.754-5.253-5.253 5.278-5.278-0.754-0.754-5.278 5.278z" fill="#000000"></path></svg></div></div>
                                                    <template v-if="preview">
                                                        <img :src="preview" class="img-fluid" />
                                                        <p class="mb-0">File name: {{ image.name }}</p>
                                                        <p class="mb-0">Size: {{ image.size/1024 }}KB</p>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Logo Image:</label>
                                            <div class="file_wrapper">
                                                <input type="file" accept="image/*" @change="previewImage" class="form-control-file" id="my-file" />
                                                <div class="preview_Show" @click="openpreviewBox()"><span class="icon"><svg fill="#000000" width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M30 3.25h-28c-0.414 0-0.75 0.336-0.75 0.75v0 24c0 0.022 0.011 0.041 0.013 0.063 0.012 0.103 0.040 0.198 0.082 0.284l-0.002-0.005c0.050 0.087 0.11 0.162 0.181 0.225l0.001 0.001c0.017 0.015 0.027 0.035 0.046 0.048 0.015 0.010 0.033 0.006 0.048 0.015 0.107 0.071 0.238 0.115 0.378 0.119l0.001 0 0.002 0.001h28.001c0.414-0 0.75-0.336 0.75-0.75v0-24c-0-0.414-0.336-0.75-0.75-0.75v0zM29.25 4.75v14.771l-6.625-9.937c-0.144-0.191-0.37-0.313-0.625-0.313s-0.481 0.122-0.624 0.311l-0.001 0.002-7.545 11.318-4.361-3.488c-0.127-0.102-0.29-0.164-0.468-0.164-0.253 0-0.477 0.126-0.613 0.318l-0.002 0.002-5.636 8.051v-20.871zM3.44 27.25l5.716-8.164 4.375 3.5c0.121 0.104 0.28 0.167 0.454 0.167 0.046 0 0.091-0.004 0.135-0.013l-0.004 0.001c0.214-0.033 0.394-0.153 0.507-0.322l0.002-0.003 7.376-11.063 7.25 10.876v5.021zM8 12.75c1.519 0 2.75-1.231 2.75-2.75s-1.231-2.75-2.75-2.75c-1.519 0-2.75 1.231-2.75 2.75v0c0.002 1.518 1.232 2.748 2.75 2.75h0zM8 8.75c0.69 0 1.25 0.56 1.25 1.25s-0.56 1.25-1.25 1.25c-0.69 0-1.25-0.56-1.25-1.25v0c0.001-0.69 0.56-1.249 1.25-1.25h0z"></path></svg></span> Image Preview</div>
                                                <div class="preview_box border p-2 mt-3" id="previewBox">
                                                    <div class="heading">Preview Here: <div class="close"><svg width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="icomoon-ignore"></g><path d="M6.576 6.576c-5.205 5.205-5.205 13.643 0 18.849s13.643 5.205 18.849-0c5.206-5.206 5.206-13.643 0-18.849s-13.643-5.205-18.849 0zM24.67 24.67c-4.781 4.781-12.56 4.781-17.341 0s-4.781-12.56 0-17.341c4.781-4.781 12.56-4.781 17.341 0s4.78 12.56-0 17.341z" fill="#000000"></path><path d="M10.722 9.969l-0.754 0.754 5.278 5.278-5.253 5.253 0.754 0.754 5.253-5.253 5.253 5.253 0.754-0.754-5.253-5.253 5.278-5.278-0.754-0.754-5.278 5.278z" fill="#000000"></path></svg></div></div>
                                                    <template v-if="preview">
                                                        <img :src="preview" class="img-fluid" />
                                                        <p class="mb-0">File name: {{ image.name }}</p>
                                                        <p class="mb-0">Size: {{ image.size/1024 }}KB</p>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>State > Metro > City:</label>
                                            <!-- <input type="text"> -->
                                            <treeselect v-model="value" :multiple="true" :options="options" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">Result URL of BHom</h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group">
                                            <label>Live URL:</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group">
                                            <label>Staging URL:</label>
                                            <input type="text" />
                                        </div>
                                    </div>                                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">Cron Configuration</h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group">
                                            <label>Company From:</label>
                                            <div class="radio-box">
                                                <ul>
                                                    <li>
                                                        <input type="radio" id="f-option" name="selector">
                                                        <label for="f-option">DB</label>
                                                        <div class="check"></div>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="s-option" name="selector">
                                                        <label for="s-option">API</label>
                                                        <div class="check">
                                                            <div class="inside"></div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group">
                                            <label>Searchbar Placeholder Text:</label>
                                            <input type="text" />
                                        </div>
                                    </div>                                                        
                                </div>

                                <div class="row">
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>API URL:</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Image Prefix URL:</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Merge DB Property?:</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Fetch Property Image From Api:</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Microsite Configuration:</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Microsite Details Show:</label>
                                            <div class="radio-box">
                                                <ul>
                                                    <li>
                                                        <input type="radio" id="f-option" name="selector">
                                                        <label for="f-option">Page</label>
                                                        <div class="check"></div>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="s-option" name="selector">
                                                        <label for="s-option"> Popup</label>
                                                        <div class="check">
                                                            <div class="inside"></div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Wildcard Zipcode/City Search:</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Community Site Google Review (Show Minimum):</label>
                                            <Multiselect v-model="GoogleReview.value" v-bind="GoogleReview" placeholder="Select Google Review" />
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Google Map API Key:</label>
                                            <input type="text" placeholder="Enter A valid Google Map API key"/>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Click on Marker to Open Microsite:</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>CTA Button Show On Map Info Area:</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Microsite Data From Duda Collection:</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Duda Dyanamic Page Create Using API:</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Duda Website Site ID:</label>
                                            <input type="text"/>
                                        </div>
                                    </div>
                                </div>

                                <div class="row wildcard_zipcode_city_search">
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Wildcard Search By</label>
                                            <div class="radio-box">
                                                <ul>
                                                    <li>
                                                        <input type="radio" id="f-option" name="selector">
                                                        <label for="f-option">Zipcode</label>
                                                        <div class="check"></div>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="s-option" name="selector">
                                                        <label for="s-option">City</label>
                                                        <div class="check">
                                                            <div class="inside"></div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="s-option" name="selector">
                                                        <label for="s-option">Both</label>
                                                        <div class="check">
                                                            <div class="inside"></div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Zipcode Max Radius (In Miles):</label>
                                            <input type="text">
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Wildcard Zipcode Search Sort By Distance</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Wildcard Zipcode Search From:</label>
                                            <div class="radio-box">
                                                <ul>
                                                    <li>
                                                        <input type="radio" id="f-option" name="selector">
                                                        <label for="f-option">Database</label>
                                                        <div class="check"></div>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="s-option" name="selector">
                                                        <label for="s-option">API</label>
                                                        <div class="check">
                                                            <div class="inside"></div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="row duda_collection_details">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">Duda Collection Details</h4>
                                <button type="button" class="more_btn">Add More</button>
                            </div>
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group">
                                            <label>Duda Site ID:</label>
                                            <input type="text" placeholder="Site Id (like cd769cb4)"/>
                                        </div>
                                    </div>
                                    <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group">
                                            <label>Duda Collection Name:</label>
                                            <input type="text" placeholder="Collection Name (like collection-1)"/>
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                                        <button type="button" class="default_btn"><span><svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m13 7.101.01.001a4.978 4.978 0 0 1 2.526 1.362 5.005 5.005 0 0 1 1.363 2.528 5.061 5.061 0 0 1-.001 2.016 4.976 4.976 0 0 1-1.363 2.527l1.414 1.414a7.014 7.014 0 0 0 1.908-3.54 6.98 6.98 0 0 0 0-2.819 6.957 6.957 0 0 0-1.907-3.539 6.97 6.97 0 0 0-2.223-1.5 6.921 6.921 0 0 0-1.315-.408c-.137-.028-.275-.043-.412-.063V2L9 6l4 4V7.101zm-7.45 7.623c.174.412.392.812.646 1.19.249.37.537.718.854 1.034a7.036 7.036 0 0 0 2.224 1.501c.425.18.868.317 1.315.408.167.034.338.056.508.078v2.944l4-4-4-4v3.03c-.035-.006-.072-.003-.107-.011a4.978 4.978 0 0 1-2.526-1.362 4.994 4.994 0 0 1 .001-7.071L7.051 7.05a7.01 7.01 0 0 0-1.5 2.224A6.974 6.974 0 0 0 5 12a6.997 6.997 0 0 0 .55 2.724z"></path></svg></span> Sync Data</button>
                                    </div>                                                        
                                </div>
                                <div class="row align-items-center remove_duda_collection_details">
                                    <span class="remove"><svg fill="#000000" width="800px" height="800px" viewBox="-2.94 0 31.716 31.716" xmlns="http://www.w3.org/2000/svg"><g transform="translate(-355.957 -579)"><path d="M376.515,610.716H361.231a2.361,2.361,0,0,1-2.358-2.359V584.1a1,1,0,0,1,2,0v24.255a.36.36,0,0,0,.358.359h15.284a.36.36,0,0,0,.358-.359V584.1a1,1,0,0,1,2,0v24.255A2.361,2.361,0,0,1,376.515,610.716Z"></path><path d="M365.457,604.917a1,1,0,0,1-1-1v-14a1,1,0,0,1,2,0v14A1,1,0,0,1,365.457,604.917Z"></path><path d="M372.29,604.917a1,1,0,0,1-1-1v-14a1,1,0,0,1,2,0v14A1,1,0,0,1,372.29,604.917Z"></path><path d="M380.79,585.1H356.957a1,1,0,0,1,0-2H380.79a1,1,0,0,1,0,2Z"></path><path d="M372.79,581h-7.917a1,1,0,1,1,0-2h7.917a1,1,0,0,1,0,2Z"></path></g></svg></span>
                                    <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group">
                                            <label>Duda Site ID:</label>
                                            <input type="text" placeholder="Site Id (like cd769cb4)"/>
                                        </div>
                                    </div>
                                    <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group">
                                            <label>Duda Collection Name:</label>
                                            <input type="text" placeholder="Collection Name (like collection-1)"/>
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                                        <button type="button" class="default_btn"><span><svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m13 7.101.01.001a4.978 4.978 0 0 1 2.526 1.362 5.005 5.005 0 0 1 1.363 2.528 5.061 5.061 0 0 1-.001 2.016 4.976 4.976 0 0 1-1.363 2.527l1.414 1.414a7.014 7.014 0 0 0 1.908-3.54 6.98 6.98 0 0 0 0-2.819 6.957 6.957 0 0 0-1.907-3.539 6.97 6.97 0 0 0-2.223-1.5 6.921 6.921 0 0 0-1.315-.408c-.137-.028-.275-.043-.412-.063V2L9 6l4 4V7.101zm-7.45 7.623c.174.412.392.812.646 1.19.249.37.537.718.854 1.034a7.036 7.036 0 0 0 2.224 1.501c.425.18.868.317 1.315.408.167.034.338.056.508.078v2.944l4-4-4-4v3.03c-.035-.006-.072-.003-.107-.011a4.978 4.978 0 0 1-2.526-1.362 4.994 4.994 0 0 1 .001-7.071L7.051 7.05a7.01 7.01 0 0 0-1.5 2.224A6.974 6.974 0 0 0 5 12a6.997 6.997 0 0 0 .55 2.724z"></path></svg></span> Sync Data</button>
                                    </div>                                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row cta_collection_details">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">Unit CTA of BHom</h4>
                                <button type="button" class="more_btn">Add More</button>
                            </div>
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>CTA Type:</label>
                                            <Multiselect v-model="CTAType.value" v-bind="CTAType" placeholder="Select CTA Type" />
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>CTA Button Text:</label>
                                            <input type="text"/>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group">
                                            <label>Enter CTA URL/Javascript function/duda popup ID:</label>
                                            <input type="text"/>
                                        </div>
                                    </div>                                                       
                                </div>
                                <div class="row align-items-center remove_cta_collection_details">
                                    <span class="remove"><svg fill="#000000" width="800px" height="800px" viewBox="-2.94 0 31.716 31.716" xmlns="http://www.w3.org/2000/svg"><g transform="translate(-355.957 -579)"><path d="M376.515,610.716H361.231a2.361,2.361,0,0,1-2.358-2.359V584.1a1,1,0,0,1,2,0v24.255a.36.36,0,0,0,.358.359h15.284a.36.36,0,0,0,.358-.359V584.1a1,1,0,0,1,2,0v24.255A2.361,2.361,0,0,1,376.515,610.716Z"></path><path d="M365.457,604.917a1,1,0,0,1-1-1v-14a1,1,0,0,1,2,0v14A1,1,0,0,1,365.457,604.917Z"></path><path d="M372.29,604.917a1,1,0,0,1-1-1v-14a1,1,0,0,1,2,0v14A1,1,0,0,1,372.29,604.917Z"></path><path d="M380.79,585.1H356.957a1,1,0,0,1,0-2H380.79a1,1,0,0,1,0,2Z"></path><path d="M372.79,581h-7.917a1,1,0,1,1,0-2h7.917a1,1,0,0,1,0,2Z"></path></g></svg></span>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>CTA Type:</label>
                                            <Multiselect v-model="CTAType.value" v-bind="CTAType" placeholder="Select CTA Type" />
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>CTA Button Text:</label>
                                            <input type="text"/>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group">
                                            <label>Enter CTA URL/Javascript function/duda popup ID:</label>
                                            <input type="text"/>
                                        </div>
                                    </div>                                                         
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row cta_collection_details">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">CTA of BHom</h4>
                                <button type="button" class="more_btn">Add More</button>
                            </div>
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Community CTA merge with site CTA?:</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Slug Prefix:</label>
                                            <input type="text"/>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Slug Sufix:</label>
                                            <input type="text"/>
                                        </div>
                                    </div>                                                    
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>CTA Type:</label>
                                            <Multiselect v-model="CTAType.value" v-bind="CTAType" placeholder="Select CTA Type" />
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>CTA Button Text:</label>
                                            <input type="text"/>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group">
                                            <label>Enter CTA URL/Javascript function/duda popup ID:</label>
                                            <input type="text"/>
                                        </div>
                                    </div>                                                         
                                </div>
                                <div class="row align-items-center remove_cta_collection_details">
                                    <span class="remove"><svg fill="#000000" width="800px" height="800px" viewBox="-2.94 0 31.716 31.716" xmlns="http://www.w3.org/2000/svg"><g transform="translate(-355.957 -579)"><path d="M376.515,610.716H361.231a2.361,2.361,0,0,1-2.358-2.359V584.1a1,1,0,0,1,2,0v24.255a.36.36,0,0,0,.358.359h15.284a.36.36,0,0,0,.358-.359V584.1a1,1,0,0,1,2,0v24.255A2.361,2.361,0,0,1,376.515,610.716Z"></path><path d="M365.457,604.917a1,1,0,0,1-1-1v-14a1,1,0,0,1,2,0v14A1,1,0,0,1,365.457,604.917Z"></path><path d="M372.29,604.917a1,1,0,0,1-1-1v-14a1,1,0,0,1,2,0v14A1,1,0,0,1,372.29,604.917Z"></path><path d="M380.79,585.1H356.957a1,1,0,0,1,0-2H380.79a1,1,0,0,1,0,2Z"></path><path d="M372.79,581h-7.917a1,1,0,1,1,0-2h7.917a1,1,0,0,1,0,2Z"></path></g></svg></span>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>CTA Type:</label>
                                            <Multiselect v-model="CTAType.value" v-bind="CTAType" placeholder="Select CTA Type" />
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>CTA Button Text:</label>
                                            <input type="text"/>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group">
                                            <label>Enter CTA URL/Javascript function/duda popup ID:</label>
                                            <input type="text"/>
                                        </div>
                                    </div>                                                         
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row cta_collection_details">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">Zero Price Message of BHom</h4>
                            </div>
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Message Type:</label>
                                            <Multiselect v-model="MessageType.value" v-bind="MessageType" placeholder=" Shortcode" />
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Message Text:</label>
                                            <input type="text"/>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                        <div class="form-group">
                                            <label>Enter Message HTML/Javascript function/duda:</label>
                                            <input type="text"/>
                                        </div>
                                    </div>                                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="update_bottom">
                <ul>
                    <li><span>*</span> Press update button to save this changes</li>
                    <li>
                        <router-link to="/"><button class="update_btn">Update</button></router-link>
                    </li>
                </ul>
            </div>
        </div>

        <div class="communityFilters_panel" id="communityFilters">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">                                             
                    <div class="heading">
                        <h4>Community Filters:</h4>
                        <div class="close" @click="communityFilters"><svg width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="icomoon-ignore"></g><path d="M6.576 6.576c-5.205 5.205-5.205 13.643 0 18.849s13.643 5.205 18.849-0c5.206-5.206 5.206-13.643 0-18.849s-13.643-5.205-18.849 0zM24.67 24.67c-4.781 4.781-12.56 4.781-17.341 0s-4.781-12.56 0-17.341c4.781-4.781 12.56-4.781 17.341 0s4.78 12.56-0 17.341z" fill="#000000"></path><path d="M10.722 9.969l-0.754 0.754 5.278 5.278-5.253 5.253 0.754 0.754 5.253-5.253 5.253 5.253 0.754-0.754-5.253-5.253 5.278-5.278-0.754-0.754-5.278 5.278z" fill="#000000"></path></svg></div></div>
                    <div class="panel-body">
                        <h3 @click="isOpenA = !isOpenA">BED <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"></path></svg></span></h3>
                        <collapse-transition :duration="300">
                            <div class="content" v-show="isOpenA">
                                <div class="row">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>Bed Show</label>
                                        <div class="check-box"><input type="checkbox"></div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>Custom Label:</label>
                                        <input type="text" placeholder="Bed">
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>Studio Show</label>
                                        <div class="check-box"><input type="checkbox"></div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>Custom Label:</label>
                                        <input type="text" placeholder="Studio">
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>One Bedroom Show</label>
                                        <div class="check-box"><input type="checkbox"></div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>Custom Label:</label>
                                        <input type="text" placeholder="One Bedroom">
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>Two Bedroom Show</label>
                                        <div class="check-box"><input type="checkbox"></div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>Custom Label:</label>
                                        <input type="text" placeholder="Two Bedroom">
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>Three Bedroom Show</label>
                                        <div class="check-box"><input type="checkbox"></div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>Custom Label:</label>
                                        <input type="text" placeholder="Three Bedroom">
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>Four Bedroom Show</label>
                                        <div class="check-box"><input type="checkbox"></div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>Custom Label:</label>
                                        <input type="text" placeholder="Four Bedroom">
                                    </div>
                                </div>
                            </div>
                            </div>
                        </collapse-transition>
                    </div>

                    <div class="panel-body">
                        <h3 @click="isOpenB = !isOpenB">BATH <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"></path></svg></span></h3>
                        <collapse-transition :duration="300">
                            <div class="content" v-show="isOpenB">
                                <div class="row">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>Bed Show</label>
                                        <div class="check-box"><input type="checkbox"></div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>Custom Label:</label>
                                        <input type="text" placeholder="Bath">
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>Bath Show</label>
                                        <div class="check-box"><input type="checkbox"></div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>Custom Label:</label>
                                        <input type="text" placeholder="Studio">
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>One Bedroom Show</label>
                                        <div class="check-box"><input type="checkbox"></div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>Custom Label:</label>
                                        <input type="text" placeholder="One Bedroom">
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>1.5 Bathroom Show</label>
                                        <div class="check-box"><input type="checkbox"></div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>Custom Label:</label>
                                        <input type="text" placeholder="1.5 Bathroom">
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>Two Bathroom Show</label>
                                        <div class="check-box"><input type="checkbox"></div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>Custom Label:</label>
                                        <input type="text" placeholder="Two Bathroom">
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>Three Bathroom Show</label>
                                        <div class="check-box"><input type="checkbox"></div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                    <div class="form-group">
                                        <label>Custom Label:</label>
                                        <input type="text" placeholder="Three Bathroom">
                                    </div>
                                </div>
                            </div>
                            </div>
                        </collapse-transition>
                    </div>

                    <div class="panel-body">
                        <h3 @click="isOpenC = !isOpenC">SQUARE FEET<span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"></path></svg></span></h3>
                        <collapse-transition :duration="300">
                            <div class="content" v-show="isOpenC">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Square Feet Show</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Custom Label:</label>
                                            <input type="text" placeholder="Square Feet">
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Minimum Square Feet:</label>
                                            <input type="text" placeholder="Minimum Square Feet">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </collapse-transition>
                    </div>

                    <div class="panel-body">
                        <h3 @click="isOpenD = !isOpenD">PRICE <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"></path></svg></span></h3>
                        <collapse-transition :duration="300">
                            <div class="content" v-show="isOpenD">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Price Show</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Custom Label:</label>
                                            <input type="text" placeholder="Price">
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Minimum Price:</label>
                                            <input type="text" placeholder="Minimum Price">
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Maximum Price</label>
                                            <input type="text" placeholder="Maximum Price">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </collapse-transition>
                    </div>

                    <div class="panel-body">
                        <h3 @click="isOpenE = !isOpenE">STATE <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"></path></svg></span></h3>
                        <collapse-transition :duration="300">
                            <div class="content" v-show="isOpenE">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>State Show</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Custom Label:</label>
                                            <input type="text" placeholder="State">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </collapse-transition>
                    </div>

                    <div class="panel-body">
                        <h3 @click="isOpenF = !isOpenF">CITY <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"></path></svg></span></h3>
                        <collapse-transition :duration="300">
                            <div class="content" v-show="isOpenF">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>City Show</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Custom Label:</label>
                                            <input type="text" placeholder="City">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </collapse-transition>
                    </div>

                    <div class="panel-body">
                        <h3 @click="isOpenG = !isOpenG">ADDITIONAL FEATURE <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"></path></svg></span></h3>
                        <collapse-transition :duration="300">
                            <div class="content" v-show="isOpenG">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Additional Feature Show</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Custom Label:</label>
                                            <input type="text" placeholder="Additional Feature">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </collapse-transition>
                    </div>

                    <div class="panel-body">
                        <h3 @click="isOpenH = !isOpenH">TAG <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"></path></svg></span></h3>
                        <collapse-transition :duration="300">
                            <div class="content" v-show="isOpenH">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Tag Show</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Custom Label:</label>
                                            <input type="text" placeholder="Tag">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </collapse-transition>
                    </div>

                    <div class="panel-body">
                        <h3 @click="isOpenI = !isOpenI">LIVING OPTION <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"></path></svg></span></h3>
                        <collapse-transition :duration="300">
                            <div class="content" v-show="isOpenI">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Living Option Show</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Custom Label:</label>
                                            <input type="text" placeholder="Living Option">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </collapse-transition>
                    </div>

                    <div class="panel-body">
                        <h3 @click="isOpenJ = !isOpenJ">RATING <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"></path></svg></span></h3>
                        <collapse-transition :duration="300">
                            <div class="content" v-show="isOpenJ">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Rating Show</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Custom Label:</label>
                                            <input type="text" placeholder="Rating">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </collapse-transition>
                    </div>

                    <div class="panel-body">
                        <h3 @click="isOpenK = !isOpenK">LOCATION DROPDOWN TEMPLATE 3 <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"></path></svg></span></h3>
                        <collapse-transition :duration="300">
                            <div class="content" v-show="isOpenK">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Location Dropdown(Template 3) Show</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Custom Label:</label>
                                            <input type="text" placeholder="Location Dropdown(Template 3)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </collapse-transition>
                    </div>

                    <div class="panel-body">
                        <h3 @click="isOpenL = !isOpenL">I'M LOOKING TO <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"></path></svg></span></h3>
                        <collapse-transition :duration="300">
                            <div class="content" v-show="isOpenL">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>I'm looking to Show</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Custom Label:</label>
                                            <input type="text" placeholder="I'm looking to">
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>For Rent</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Custom Label:</label>
                                            <input type="text" placeholder="For Rent">
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>For Buy</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Custom Label:</label>
                                            <input type="text" placeholder="For Buy">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </collapse-transition>
                    </div>

                    <div class="panel-body">
                        <h3 @click="isOpenM = !isOpenM">VIEW LIST BY <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"></path></svg></span></h3>
                        <collapse-transition :duration="300">
                            <div class="content" v-show="isOpenM">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>View List By Show</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Custom Label:</label>
                                            <input type="text" placeholder="View List By">
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Community</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Custom Label:</label>
                                            <input type="text" placeholder="Community">
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Apartment</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                                        <div class="form-group">
                                            <label>Custom Label:</label>
                                            <input type="text" placeholder="Apartment">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </collapse-transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

          
      <script>
        import Multiselect from "@vueform/multiselect";
        import Treeselect from 'vue3-treeselect';
        import 'vue3-treeselect/dist/vue3-treeselect.css';
        import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';
        
        export default {
        components: {
          Multiselect,
          Treeselect,
          CollapseTransition,
      },
        data() {
            return {
                //Collapse
                isOpenA: false,
                isOpenB: false,
                isOpenC: false,
                isOpenD: false,
                isOpenE: false,
                isOpenF: false,
                isOpenG: false,
                isOpenH: false,
                isOpenI: false,
                isOpenJ: false,
                isOpenK: false,
                isOpenL: false,
                isOpenM: false,
                //TreeSelect
                value: null,
                options: [ {
                    id: '1',
                    label: 'Level-1',
                    children: [ {
                    id: '11',
                    label: 'Level-1-1',
                    }, {
                    id: '12',
                    label: 'Level-1-2',
                    } ],
                }, {
                    id: '2',
                    label: 'Level-2',
                }, {
                    id: '3',
                    label: 'Level-3',
                } ],
                //Upload Images
                preview: null,
                image: null,
                preview_list: [],
                image_list: [],
                //Overlay 
                Overlay: {
                    mode: 'single',
                    value: 'Set Overlay',
                    options: [
                        { value: '0.1', label: '0.1' },
                        { value: '0.2', label: '0.2' },
                        { value: '0.3', label: '0.3' },
                        { value: '0.4', label: '0.4' },
                        { value: '0.5', label: '0.5' },
                        { value: '0.6', label: '0.6' },
                        { value: '0.7', label: '0.7' },
                        { value: '0.8', label: '0.8' },
                        { value: '0.9', label: '0.9' },
                        { value: '1', label: '1' }
                    ],
                    searchable: false,
                    createTag: false
                },
                //Ordering 
                Ordering: {
                    mode: 'single',
                    value: '1',
                    options: [
                        { value: '1', label: '1' },
                        { value: '2', label: '2' },
                        { value: '3', label: '3' }
                    ],
                    searchable: false,
                    createTag: false
                },
                //Google Review 
                GoogleReview: {
                    mode: 'single',
                    value: 'Select Google Review',
                    options: [
                        { value: '1 Star', label: '1 Star' },
                        { value: '2 Star', label: '2 Star' },
                        { value: '3 Star', label: '3 Star' },
                        { value: '4 Star', label: '4 Star' },
                        { value: '5 Star', label: '5 Star' },
                    ],
                    searchable: false,
                    createTag: false
                },
                //CTA Type 
                CTAType: {
                    mode: 'single',
                    value: 'Select CTA Type',
                    options: [
                        { value: 'HTML', label: 'HTML' },
                        { value: 'Javascript Function', label: 'Javascript Function' },
                        { value: 'Duda Site Popup', label: 'Duda Site Popup' },
                        { value: 'API', label: 'API' },
                        { value: 'Slug', label: 'Slug' },
                        { value: 'Property Website', label: 'Property Website' },
                        { value: 'Custom URL(Community Specific)', label: 'Custom URL(Community Specific)' },
                    ],
                    searchable: false,
                    createTag: false
                },
                //Message Type
                MessageType: {
                    mode: 'single',
                    value: 'Select Message Type',
                    options: [
                        { value: 'HTML', label: 'HTML' },
                        { value: 'Javascript Function', label: 'Javascript Function' },
                        { value: 'Duda Site Popup', label: 'Duda Site Popup' },
                        { value: 'Text', label: 'Text' },
                        { value: 'Slug', label: 'Slug' },
                    ],
                    searchable: false,
                    createTag: false
                },

            };
        },
        methods: {
            //Filter panle
            communityFilters: function () {
                const x = document.getElementById("communityFilters");
                if (x.classList.contains("communityFilters_panel_active")) {
                    x.classList.remove("communityFilters_panel_active");
                } else {
                    x.classList.add("communityFilters_panel_active");
                }
            },
            //Preview Box
            openpreviewBox: function() {
                const x = document.getElementById('previewBox');
                if(x.classList.contains('previewBox--active')){
                    x.classList.remove('previewBox--active')
                }else{
                    x.classList.add('previewBox--active')
                }
            },
            //Upload Images
            previewImage: function(event) {
                var input = event.target;
                if (input.files) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                    this.preview = e.target.result;
                    }
                    this.image=input.files[0];
                    reader.readAsDataURL(input.files[0]);
                }
            },
            previewMultiImage: function(event) {
                var input = event.target;
                var count = input.files.length;
                var index = 0;
                if (input.files) {
                    while(count --) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        this.preview_list.push(e.target.result);
                    }
                    this.image_list.push(input.files[index]);
                    reader.readAsDataURL(input.files[index]);
                    index ++;
                    }
                }
            },
                reset: function() {
                this.image = null;
                this.preview = null;
                this.image_list = [];
                this.preview_list = [];
                },
            //Action btn panle
            openAction_btn: function () {
                const x = document.getElementById("action_btn_panl");
                if (x.classList.contains("action_btn_panle_active")) {
                    x.classList.remove("action_btn_panle_active");
                } else {
                    x.classList.add("action_btn_panle_active");
                }
            },
        },
        };
        </script>
        <style src="@vueform/multiselect/themes/default.css"></style>