<template>
    <div class="container-fluid d-flex">
        <div class="general_configuration">
            <div class="general_configuration_details">
                <div class="top_pannel">
                    <div class="heading">
                        <h3>Colorstyle Settings</h3>
                    </div>
                    <div class="check-box"><label>Show Preview</label><input type="checkbox" v-model="has_preview"/></div>
                </div>
                <div class="top_pannel">
                    <ul class="tabs">
                        <li :class="{'active' : tab == 'tab1' }" @click="tab = 'tab1'">Default Color (Text)</li>
                        <li :class="{'active' : tab == 'tab2' }" @click="tab = 'tab2'">Tab Header</li>
                        <li :class="{'active' : tab == 'tab3' }" @click="tab = 'tab3'">Tab Content</li>
                        <li :class="{'active' : tab == 'tab4' }" @click="tab = 'tab4'">Modal Area</li>
                        <li :class="{'active' : tab == 'tab5' }" @click="tab = 'tab5'">Put Your Own CSS</li>
                    </ul>
                    <button class="default_btn"><span class="icon"><svg viewBox="0 0 36 36" version="1.1"  preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M33,10.05a5.07,5.07,0,0,0,.1-7.17A5.06,5.06,0,0,0,26,3L20.78,8.15a2.13,2.13,0,0,1-3,0l-.67-.67L15.72,8.92,27.08,20.28l1.42-1.42-.67-.67a2.13,2.13,0,0,1,0-3ZM26.44,13.8a4.07,4.07,0,0,0-1.08,1.92l-5.08-5.08A4.07,4.07,0,0,0,22.2,9.56l5.16-5.17a3.09,3.09,0,0,1,4.35-.1,3.09,3.09,0,0,1-.1,4.35Z" class="clr-i-outline clr-i-outline-path-1"></path><path d="M7.3,31.51a2,2,0,1,1-2.83-2.83L18.58,14.57l-1.42-1.41L3.05,27.27a4,4,0,0,0-.68,4.8L.89,33.55A1,1,0,0,0,.89,35a1,1,0,0,0,1.42,0l1.43-1.44a3.93,3.93,0,0,0,2.09.6,4.06,4.06,0,0,0,2.88-1.2L22.82,18.81,21.41,17.4Z" class="clr-i-outline clr-i-outline-path-2"></path><rect x="0" y="0" width="36" height="36" fill-opacity="0"/></svg></span> Set All Default Color</button>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="Page_content">
                            <div class="row" v-if="tab == 'tab1'">
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Default Color Text<span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate."><svg viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M16 3c-7.18 0-13 5.82-13 13s5.82 13 13 13 13-5.82 13-13-5.82-13-13-13zM18.039 20.783c-0.981 1.473-1.979 2.608-3.658 2.608-1.146-0.187-1.617-1.008-1.369-1.845l2.16-7.154c0.053-0.175-0.035-0.362-0.195-0.419-0.159-0.056-0.471 0.151-0.741 0.447l-1.306 1.571c-0.035-0.264-0.004-0.7-0.004-0.876 0.981-1.473 2.593-2.635 3.686-2.635 1.039 0.106 1.531 0.937 1.35 1.85l-2.175 7.189c-0.029 0.162 0.057 0.327 0.204 0.379 0.16 0.056 0.496-0.151 0.767-0.447l1.305-1.57c0.035 0.264-0.024 0.726-0.024 0.902zM17.748 11.439c-0.826 0-1.496-0.602-1.496-1.488s0.67-1.487 1.496-1.487 1.496 0.602 1.496 1.487c0 0.887-0.67 1.488-1.496 1.488z"></path></svg></span></label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="default_color"/>
                                        </div>
                                    </div>                                                                       
                                </div>
                            </div>
                            <div class="row" v-if="tab == 'tab2'">
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Tab Area BG</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="tab_bg_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Tab Heading BG <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate."><svg viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M16 3c-7.18 0-13 5.82-13 13s5.82 13 13 13 13-5.82 13-13-5.82-13-13-13zM18.039 20.783c-0.981 1.473-1.979 2.608-3.658 2.608-1.146-0.187-1.617-1.008-1.369-1.845l2.16-7.154c0.053-0.175-0.035-0.362-0.195-0.419-0.159-0.056-0.471 0.151-0.741 0.447l-1.306 1.571c-0.035-0.264-0.004-0.7-0.004-0.876 0.981-1.473 2.593-2.635 3.686-2.635 1.039 0.106 1.531 0.937 1.35 1.85l-2.175 7.189c-0.029 0.162 0.057 0.327 0.204 0.379 0.16 0.056 0.496-0.151 0.767-0.447l1.305-1.57c0.035 0.264-0.024 0.726-0.024 0.902zM17.748 11.439c-0.826 0-1.496-0.602-1.496-1.488s0.67-1.487 1.496-1.487 1.496 0.602 1.496 1.487c0 0.887-0.67 1.488-1.496 1.488z"></path></svg></span></label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="tab_heading_bg_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Tab Heading BG (Hover) <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate."><svg viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M16 3c-7.18 0-13 5.82-13 13s5.82 13 13 13 13-5.82 13-13-5.82-13-13-13zM18.039 20.783c-0.981 1.473-1.979 2.608-3.658 2.608-1.146-0.187-1.617-1.008-1.369-1.845l2.16-7.154c0.053-0.175-0.035-0.362-0.195-0.419-0.159-0.056-0.471 0.151-0.741 0.447l-1.306 1.571c-0.035-0.264-0.004-0.7-0.004-0.876 0.981-1.473 2.593-2.635 3.686-2.635 1.039 0.106 1.531 0.937 1.35 1.85l-2.175 7.189c-0.029 0.162 0.057 0.327 0.204 0.379 0.16 0.056 0.496-0.151 0.767-0.447l1.305-1.57c0.035 0.264-0.024 0.726-0.024 0.902zM17.748 11.439c-0.826 0-1.496-0.602-1.496-1.488s0.67-1.487 1.496-1.487 1.496 0.602 1.496 1.487c0 0.887-0.67 1.488-1.496 1.488z"></path></svg></span></label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="tab_heading_hvr_bg_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Tab Heading Text</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="tab_heading_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Tab Heading Text (Hover)</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="tab_heading_hvr_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Tab Heading BG (Active) <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate."><svg viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M16 3c-7.18 0-13 5.82-13 13s5.82 13 13 13 13-5.82 13-13-5.82-13-13-13zM18.039 20.783c-0.981 1.473-1.979 2.608-3.658 2.608-1.146-0.187-1.617-1.008-1.369-1.845l2.16-7.154c0.053-0.175-0.035-0.362-0.195-0.419-0.159-0.056-0.471 0.151-0.741 0.447l-1.306 1.571c-0.035-0.264-0.004-0.7-0.004-0.876 0.981-1.473 2.593-2.635 3.686-2.635 1.039 0.106 1.531 0.937 1.35 1.85l-2.175 7.189c-0.029 0.162 0.057 0.327 0.204 0.379 0.16 0.056 0.496-0.151 0.767-0.447l1.305-1.57c0.035 0.264-0.024 0.726-0.024 0.902zM17.748 11.439c-0.826 0-1.496-0.602-1.496-1.488s0.67-1.487 1.496-1.487 1.496 0.602 1.496 1.487c0 0.887-0.67 1.488-1.496 1.488z"></path></svg></span></label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="tab_heading_act_bg_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Tab Heading Text (Active)</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="tab_heading_txt_act_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Floorplan Details Page Top Section BG Color</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="floorplan_top_bg_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Floorplan Details Page Top Section Text Color</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="floorplan_top_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Tab Heading Border Radius</label>
                                        <div class="has_suffix">
                                            <input type="number" v-model="tab_heading_border_radius"/>
                                            <span class="suffix">PX</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="tab == 'tab3'">
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Tab Content BG</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="tab_content_bg_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Tab Content Border Bottom</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="tab_content_border_bottom_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Apartment Title</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="apartment_title_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Apartment Tagline</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="apartment_tagline_color"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Apartment highlighted Text</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="apartment_heightlight_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Button BG</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="btn_bg_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Button BG (Hover)</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="btn_bg_hvr_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Button Border</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="btn_border_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Button Border Color (Hover)</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="btn_border_hvr_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Button Text</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="btn_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Button Text (Hover)</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="btn_txt_hvr_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Secondary Button BG</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="sec_btn_bg_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Secondary Button BG (Hover)</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="sec_btn_bg_hvr_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Secondary Button Border</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="sec_btn_border_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Secondary Button Border Color (Hover)</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="sec_btn_border_hvr_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Secondary Button Text</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="sec_btn_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Secondary Button Text (Hover)</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="sec_btn_txt_hvr_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Active View Eye & Counter Color (Right)</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="act_eye_counter_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Active View Text Color (Right)</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="act_eye_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>ALL Button Border Radius</label>
                                        <div class="has_suffix">
                                            <input type="number" v-model="all_btn_border_radius"/>
                                            <span class="suffix">PX</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="tab == 'tab4'">
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Modal Content Heading</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="modal_content_heading_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Modal Table Border</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="modal_table_border_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Modal Datepicker Active Date</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="modal_datepkr_act_date_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Modal Datepicker Active Date (BG)</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="modal_datepkr_act_date_bg_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Modal Lease Now Button Text</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="modal_lease_btn_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                    <div class="color_panel">
                                        <label>Modal Lease Now Button (BG)</label>
                                        <div class="color_wpr">
                                            <input type="text" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="modal_lease_btn_bg_clr"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="tab == 'tab5'">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <textarea id="exampleFormControlTextarea5" rows="3" placeholder="Write Your Own CSS Code..."></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="update_bottom">
                <ul>
                    <li><span>*</span> Press update button to save this changes</li>
                    <li>
                        <router-link to="/"><button class="update_btn">Update</button></router-link>
                    </li>
                </ul>
            </div>
        </div>
        <LeftPreviewPanel v-model="has_preview"/>
    </div>
</template>
          
<script>
    import LeftPreviewPanel from "@/views/LeftPreviewPanel.vue"
    import { ColorPicker } from "vue3-colorpicker";
    import "vue3-colorpicker/style.css";
    export default {
        components: {
            ColorPicker,
            LeftPreviewPanel
        },
        data() {
            return {
                tab: 'tab1',
                has_preview: false,
                default_color: '#707070',
                tab_bg_clr: '#ffffff',
                tab_heading_bg_clr: '#3e3e3e',
                tab_heading_hvr_bg_clr: '#000000',
                tab_heading_txt_clr: '#ffffff',
                tab_heading_hvr_txt_clr: '#000000',
                tab_heading_act_bg_clr: '#f75b00',
                tab_heading_txt_act_clr: '#ffffff',
                floorplan_top_bg_clr: '#000000',
                floorplan_top_txt_clr:'#ffffff',
                tab_heading_border_radius: 30,
                tab_content_bg_clr: '#f6f6f6',
                tab_content_border_bottom_clr:'#cccccc',
                apartment_title_clr:'#000000',
                apartment_tagline_color:'#555555',
                apartment_heightlight_txt_clr:'#f75b00',
                btn_bg_clr:'#f75b00',
                btn_bg_hvr_clr:'#d1520c',
                btn_border_clr:'#f75b00',
                btn_border_hvr_clr:'#000000',
                btn_txt_clr:'#ffffff',
                btn_txt_hvr_clr:'#ffffff',
                sec_btn_bg_clr:'#3e3e3e',
                sec_btn_bg_hvr_clr:'#1a1a1a',
                sec_btn_border_clr:'#3e3e3e',
                sec_btn_border_hvr_clr:'#000000',
                sec_btn_txt_clr:'#ffffff',
                sec_btn_txt_hvr_clr:'#ffffff',
                act_eye_counter_clr:'#000000',
                act_eye_txt_clr:'#000000',
                all_btn_border_radius: 20,
                modal_content_heading_clr:'#000000',
                modal_table_border_clr:'#000000',
                modal_datepkr_act_date_clr:'#ffffff',
                modal_datepkr_act_date_bg_clr:'#f75b00',
                modal_lease_btn_txt_clr:'#ffffff',
                modal_lease_btn_bg_clr:'#f75b00',
            };
        },
    };
</script>