<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="general_configuration">
                    <div class="general_configuration_details">
                        <div class="top_pannel">
                            <div class="heading">
                                <h3>Custom</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-header align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">API Type:</h4>
                                        <span class="badge bg-danger align-middle fs-10 text-white ml-1">Required *</span>
                                    </div>
                                    <div class="card-body">
                                        <div class="Page_content">
                                            <div class="row">
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="update_bottom">
                <ul>
                    <li><span>*</span> Press update button to save this changes</li>
                    <li>
                        <router-link to="/"><button class="update_btn">Update</button></router-link>
                    </li>
                </ul>
            </div>
        </div>
        <!-- <LeftPreviewPanel/> -->
    </div>
</template>

<script>
    import LeftPreviewPanel from "@/views/LeftPreviewPanel.vue";
    import Checkbox from "@/components/Checkbox.vue";
    import Multiselect from "@vueform/multiselect";

    export default {
        components: {
            LeftPreviewPanel,
            Checkbox,
            Multiselect,
        },
        data() {
            return {
                //Lease Term Month
                LeaseTermMonth: {
                    mode: "single",
                    value: "12 Months",
                    options: [
                        { value: "2 Months", label: "2 Months" },
                        { value: "3 Months ", label: "3 Months" },
                        { value: "4 Months ", label: "4 Months " },
                        { value: "5 Months ", label: "5 Months " },
                        { value: "5 Months ", label: "6 Months " },
                    ],
                    searchable: false,
                    createTag: false,
                },
            };
        },
    };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
