<template>
    <div class="container-fluid d-flex">
        <div class="general_configuration">
            <div class="general_configuration_details">
                <div class="top_pannel">
                    <div class="heading">
                        <h3>API User Guide</h3>
                    </div>
                    <div class="check-box"><label>Show Preview</label><input type="checkbox" v-model="has_preview"/></div>
                </div>
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">Short Code To Be Added Within Page To Generate Map/List View <span class="badge bg-danger align-middle fs-10 text-white ml-1">Required *</span></h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div class="clipboard">
                                                    <label>Please copy and paste Map Area of your page</label>
                                                    <div class="clipboard_path"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">For Overlay Pop Up use Following Code <span class="badge bg-danger align-middle fs-10 text-white ml-1">Required *</span></h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div class="clipboard">
                                                    <label>Please copy and paste on page</label>
                                                    <div class="clipboard_path"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">Short Code For With Button Search <span class="badge bg-danger align-middle fs-10 text-white ml-1">Required *</span></h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div class="clipboard">
                                                    <label>Please Copy and Paste On Page</label>
                                                    <div class="clipboard_path"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">Short Code For Normal Search <span class="badge bg-danger align-middle fs-10 text-white ml-1">Required *</span></h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div class="clipboard">
                                                    <label>Please copy and paste on specific section</label>
                                                    <div class="clipboard_path"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">Add Event To Appear Overlay Popup <span class="badge bg-danger align-middle fs-10 text-white ml-1">Required *</span></h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div class="clipboard">
                                                    <label>Please copy and paste on popup area</label>
                                                    <div class="clipboard_path"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">Footer Area Code (Without Jquery Library)</h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div class="clipboard">
                                                    <label>Please copy and paste on footer of your page</label>
                                                    <div class="clipboard_path"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">Footer Area Code (With Jquery Library)</h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div class="clipboard">
                                                    <label>Please copy and paste on footer of your page</label>
                                                    <div class="clipboard_path"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="update_bottom">
                <ul>
                    <li><span>*</span> Press update button to save this changes</li>
                    <li>
                        <router-link to="/"><button class="update_btn">Update</button></router-link>
                    </li>
                </ul>
            </div>
        </div>
        <LeftPreviewPanel v-model="has_preview"/>
    </div>
</template>

          
<script>
    import LeftPreviewPanel from "@/views/LeftPreviewPanel.vue"

    export default {
        components: {
            LeftPreviewPanel,
        },
        data() {
            return {
                has_preview: false,
            };
        },
    };
</script>