<template>
    <div class="container-fluid communitysiteedit">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="general_configuration">
                    <div class="general_configuration_details">
                        <div class="top_pannel">
                            <div class="heading">
                                <h3>Community Site Add/Edit</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Site Name</label>
                                                    <input type="text" />
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Site Slug</label>
                                                    <input type="text" />
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Site Url</label>
                                                    <input type="text" />
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Company</label>
                                                    <Multiselect v-model="Company.value" v-bind="Company" placeholder="Select Company" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Module Access</label>
                                                    <div class="radio-box">
                                                        <ul>
                                                            <li>
                                                                <input type="radio" id="f-option" name="selector">
                                                                <label for="f-option">REPLI ApartmentSync</label>
                                                                <div class="check"></div>
                                                            </li>
                                                            <li>
                                                                <input type="radio" id="s-option" name="selector">
                                                                <label for="s-option">Prospect Guide</label>
                                                                <div class="check">
                                                                    <div class="inside"></div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Enable Status</label>
                                                    <div class="radio-box">
                                                        <ul>
                                                            <li>
                                                                <input type="radio" id="f-option" name="selector">
                                                                <label for="f-option">Active</label>
                                                                <div class="check"></div>
                                                            </li>
                                                            <li>
                                                                <input type="radio" id="s-option" name="selector">
                                                                <label for="s-option">Inactive</label>
                                                                <div class="check">
                                                                    <div class="inside"></div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Live Status</label>
                                                    <div class="radio-box">
                                                        <ul>
                                                            <li>
                                                                <input type="radio" id="f-option" name="selector">
                                                                <label for="f-option">Active</label>
                                                                <div class="check"></div>
                                                            </li>
                                                            <li>
                                                                <input type="radio" id="s-option" name="selector">
                                                                <label for="s-option">Inactive</label>
                                                                <div class="check">
                                                                    <div class="inside"></div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Draft Status</label>
                                                    <div class="radio-box">
                                                        <ul>
                                                            <li>
                                                                <input type="radio" id="f-option" name="selector">
                                                                <label for="f-option">Active</label>
                                                                <div class="check"></div>
                                                            </li>
                                                            <li>
                                                                <input type="radio" id="s-option" name="selector">
                                                                <label for="s-option">Inactive</label>
                                                                <div class="check">
                                                                    <div class="inside"></div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Show On Corporate Site</label>
                                                    <div class="check-box"><input type="checkbox"></div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Pet Policy Type</label>
                                                    <Multiselect v-model="PetPolicy.value" v-bind="PetPolicy" placeholder="Select Pet Policy Type" />
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Pet Policy Show On</label>
                                                    <div class="check-box"><input type="checkbox"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row manual_type">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Dogs Allowed</label>
                                                    <div class="check-box"><input type="checkbox"></div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Cats Allowed</label>
                                                    <div class="check-box"><input type="checkbox"></div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Max Pets No</label>
                                                    <input type="text" />
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Weight Limit (lbs)</label>
                                                    <input type="text" />
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Monthly Pet Fee ($)</label>
                                                    <input type="text" />
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>One-Time Pet Fee ($)</label>
                                                    <input type="text" />
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Pet Deposit ($)</label>
                                                    <input type="text" />
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Other Details</label>
                                                    <input type="text" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Special Show/Hide</label>
                                                    <div class="check-box"><input type="checkbox"></div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Special Type</label>
                                                    <div class="custom_radio">
                                                        <input type="radio" id="cat" name="animal" value="" />
                                                        <label for="cat">Custom</label>
                                                        <input type="radio" id="dog" name="animal" value="" />
                                                        <label for="dog">API</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Special Title</label>
                                                    <input type="text" placeholder="Special Offers title"/>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Offers Starts</label>
                                                    <Datepicker v-model="date" calendar-cell-class-name="dp-custom-cell" :enable-time-picker="false" placeholder="To Date" />
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Special Content</label>
                                                    <textarea placeholder="Special Offers Details"/>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Offers Expires</label>
                                                    <Datepicker v-model="date" calendar-cell-class-name="dp-custom-cell" :enable-time-picker="false" placeholder="To Date" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from "@vueform/multiselect";
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';

    export default {
        components: {
            Multiselect,
            Datepicker
        },
        data() {
            return {
                //Company Dropdown
                Company: {
                    mode: "single",
                    value: "Select Company",
                    options: [
                        { value: "Coastal Ridge", label: "Coastal Ridge" },
                        { value: "Audubon", label: "Audubon" },
                        { value: "Waterton", label: "Waterton" },
                        { value: "Pegasus Residential", label: "Pegasus Residential" },
                    ],
                    searchable: false,
                    createTag: false,
                },
                //Pet Policy Type
                PetPolicy: {
                    mode: "single",
                    value: "Select Pet Policy Type",
                    options: [
                        { value: "Manual", label: "Manual" },
                        { value: "API", label: "API" },
                    ],
                    searchable: false,
                    createTag: false,
                },
            };
        },
        methods: {

        },
    };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
