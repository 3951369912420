<template>
    <div class="container-fluid communitysiteedit">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="general_configuration">
                    <div class="general_configuration_details">
                        <div class="top_pannel">
                            <div class="heading">
                                <h3>Community Site Add/Edit</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Site Name</label>
                                                    <input type="text" value="Populus Westside" />
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Site Slug</label>
                                                    <input type="text" value="populus-westside"/>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Site Url</label>
                                                    <input type="text" value="https://www.populuswestsideapts.com/"/>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Company</label>
                                                    <Multiselect v-model="Company.value" v-bind="Company" placeholder="Ram Partners" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Module Access</label>
                                                    <div class="radio-box">
                                                        <ul>
                                                            <li>
                                                                <input type="radio" id="f-option" name="selector" checked>
                                                                <label for="f-option">REPLI ApartmentSync</label>
                                                                <div class="check"></div>
                                                            </li>
                                                            <li>
                                                                <input type="radio" id="s-option" name="selector">
                                                                <label for="s-option">Prospect Guide</label>
                                                                <div class="check">
                                                                    <div class="inside"></div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Enable Status</label>
                                                    <div class="radio-box">
                                                        <ul>
                                                            <li>
                                                                <input type="radio" id="t-option" name="selector" checked>
                                                                <label for="t-option">Active</label>
                                                                <div class="check"></div>
                                                            </li>
                                                            <li>
                                                                <input type="radio" id="y-option" name="selector">
                                                                <label for="y-option">Inactive</label>
                                                                <div class="check">
                                                                    <div class="inside"></div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Live Status</label>
                                                    <div class="radio-box">
                                                        <ul>
                                                            <li>
                                                                <input type="radio" id="w-option" name="selector" checked>
                                                                <label for="w-option">Active</label>
                                                                <div class="check"></div>
                                                            </li>
                                                            <li>
                                                                <input type="radio" id="q-option" name="selector">
                                                                <label for="q-option">Inactive</label>
                                                                <div class="check">
                                                                    <div class="inside"></div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Draft Status</label>
                                                    <div class="radio-box">
                                                        <ul>
                                                            <li>
                                                                <input type="radio" id="d-option" name="selector" checked>
                                                                <label for="d-option">Active</label>
                                                                <div class="check"></div>
                                                            </li>
                                                            <li>
                                                                <input type="radio" id="s-option" name="selector">
                                                                <label for="s-option">Inactive</label>
                                                                <div class="check">
                                                                    <div class="inside"></div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Show On Corporate Site</label>
                                                    <div class="check-box"><input type="checkbox" checked></div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Pet Policy Type</label>
                                                    <Multiselect v-model="PetPolicy.value" v-bind="PetPolicy" placeholder="API" />
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Pet Policy Show On</label>
                                                    <div class="check-box"><input type="checkbox" checked></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row manual_type">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Dogs Allowed</label>
                                                    <div class="check-box"><input type="checkbox" checked></div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Cats Allowed</label>
                                                    <div class="check-box"><input type="checkbox" checked></div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Max Pets No</label>
                                                    <input type="text" value="0"/>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Weight Limit (lbs)</label>
                                                    <input type="text" value="0"/>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Monthly Pet Fee ($)</label>
                                                    <input type="text" value="0"/>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>One-Time Pet Fee ($)</label>
                                                    <input type="text" value="0"/>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Pet Deposit ($)</label>
                                                    <input type="text" value="0"/>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Other Details</label>
                                                    <input type="text" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Special Show/Hide</label>
                                                    <div class="check-box"><input type="checkbox" checked></div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Special Type</label>
                                                    <div class="custom_radio">
                                                        <input type="radio" id="cat" name="animal" value="" checked/>
                                                        <label for="cat">Custom</label>
                                                        <input type="radio" id="dog" name="animal" value="" />
                                                        <label for="dog">API</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Special Title</label>
                                                    <input type="text" placeholder="Special Offers title"/>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Offers Starts</label>
                                                    <Datepicker v-model="date" calendar-cell-class-name="dp-custom-cell" :enable-time-picker="false" placeholder="To Date" />
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Special Content</label>
                                                    <textarea placeholder="Special Offers Details"/>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Offers Expires</label>
                                                    <Datepicker v-model="date" calendar-cell-class-name="dp-custom-cell" :enable-time-picker="false" placeholder="To Date" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Property Address</label>
                                                    <input type="text" placeholder="1315 Northwest Dr. Atlanta, GA 30318"/>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Zip</label>
                                                    <input type="text" placeholder="30318"/>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>State</label>
                                                    <Multiselect v-model="State.value" v-bind="State" placeholder="Ram Partners" />
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>State</label>
                                                    <Multiselect v-model="City.value" v-bind="City" placeholder="Ram Partners" />
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Phone</label>
                                                    <input type="text" placeholder="770-892-6750"/>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Email</label>
                                                    <input type="text" placeholder="populuswestside@ram-mgt.com"/>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Tags</label>
                                                    <multiselect v-model="value" label="name" track-by="code" :options="options" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Site Lat</label>
                                                    <input type="text" placeholder="33.7929132" disabled/>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Site Long</label>
                                                    <input type="text" placeholder="-84.47882899999999" disabled/>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Community Info</label>
                                                    <input type="text" placeholder="Enter Community info"/>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Image</label>
                                                    <div class="file_wrapper">
                                                        <input type="file" accept="image/*" @change="previewImage" class="form-control-file" id="my-file" />
                                                        <div class="preview_Show" @click="openpreviewBox()"><span class="icon"><svg fill="#000000" width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M30 3.25h-28c-0.414 0-0.75 0.336-0.75 0.75v0 24c0 0.022 0.011 0.041 0.013 0.063 0.012 0.103 0.040 0.198 0.082 0.284l-0.002-0.005c0.050 0.087 0.11 0.162 0.181 0.225l0.001 0.001c0.017 0.015 0.027 0.035 0.046 0.048 0.015 0.010 0.033 0.006 0.048 0.015 0.107 0.071 0.238 0.115 0.378 0.119l0.001 0 0.002 0.001h28.001c0.414-0 0.75-0.336 0.75-0.75v0-24c-0-0.414-0.336-0.75-0.75-0.75v0zM29.25 4.75v14.771l-6.625-9.937c-0.144-0.191-0.37-0.313-0.625-0.313s-0.481 0.122-0.624 0.311l-0.001 0.002-7.545 11.318-4.361-3.488c-0.127-0.102-0.29-0.164-0.468-0.164-0.253 0-0.477 0.126-0.613 0.318l-0.002 0.002-5.636 8.051v-20.871zM3.44 27.25l5.716-8.164 4.375 3.5c0.121 0.104 0.28 0.167 0.454 0.167 0.046 0 0.091-0.004 0.135-0.013l-0.004 0.001c0.214-0.033 0.394-0.153 0.507-0.322l0.002-0.003 7.376-11.063 7.25 10.876v5.021zM8 12.75c1.519 0 2.75-1.231 2.75-2.75s-1.231-2.75-2.75-2.75c-1.519 0-2.75 1.231-2.75 2.75v0c0.002 1.518 1.232 2.748 2.75 2.75h0zM8 8.75c0.69 0 1.25 0.56 1.25 1.25s-0.56 1.25-1.25 1.25c-0.69 0-1.25-0.56-1.25-1.25v0c0.001-0.69 0.56-1.249 1.25-1.25h0z"></path></svg></span> Image Preview</div>
                                                        <div class="preview_box border p-2 mt-3" id="previewBox">
                                                            <div class="heading">Preview Here: <div class="close"><svg width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="icomoon-ignore"></g><path d="M6.576 6.576c-5.205 5.205-5.205 13.643 0 18.849s13.643 5.205 18.849-0c5.206-5.206 5.206-13.643 0-18.849s-13.643-5.205-18.849 0zM24.67 24.67c-4.781 4.781-12.56 4.781-17.341 0s-4.781-12.56 0-17.341c4.781-4.781 12.56-4.781 17.341 0s4.78 12.56-0 17.341z" fill="#000000"></path><path d="M10.722 9.969l-0.754 0.754 5.278 5.278-5.253 5.253 0.754 0.754 5.253-5.253 5.253 5.253 0.754-0.754-5.253-5.253 5.278-5.278-0.754-0.754-5.278 5.278z" fill="#000000"></path></svg></div></div>
                                                            <template v-if="preview">
                                                                <img :src="preview" class="img-fluid" />
                                                                <p class="mb-0">File name: {{ image.name }}</p>
                                                                <p class="mb-0">Size: {{ image.size/1024 }}KB</p>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Additional Features</label>
                                                    <div class="custom_radio">
                                                        <input type="radio" id="cat" name="animal" value=""/>
                                                        <label for="cat">Fitness Center</label>
                                                        <input type="radio" id="dog" name="animal" value="" />
                                                        <label for="dog">Pet Friendly</label>
                                                        <input type="radio" id="dog" name="animal" value="" />
                                                        <label for="dog">Swimming Pool</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Floorplan Type</label>
                                                    <div class="custom_radio">
                                                        <input type="radio" id="cat" name="animal" value=""/>
                                                        <label for="cat">Apartment</label>
                                                        <input type="radio" id="dog" name="animal" value="" />
                                                        <label for="dog">Penthouse</label>
                                                        <input type="radio" id="dog" name="animal" value="" />
                                                        <label for="dog">Townhome</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                <div class="form-group">
                                                    <label>Living Option</label>
                                                    <div class="custom_radio">
                                                        <input type="radio" id="cat" name="animal" value=""/>
                                                        <label for="cat">Assisted Living</label>
                                                        <input type="radio" id="dog" name="animal" value="" />
                                                        <label for="dog">Independent Living </label>
                                                        <input type="radio" id="dog" name="animal" value="" />
                                                        <label for="dog">Memory Care</label>
                                                        <input type="radio" id="dog" name="animal" value="" />
                                                        <label for="dog">Senior Apartments</label>
                                                        <input type="radio" id="dog" name="animal" value="" />
                                                        <label for="dog">Supportive Living</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                                <label>CTA type:</label>
                                                <Multiselect v-model="CtaType.value" v-bind="CtaType" placeholder="Select" />
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                                <label>CTA Button Text:</label>
                                                <input type="text" />
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                                <label>Enter CTA URL/Javascript function/duda popup id:</label>
                                                <input type="text" />
                                            </div>
                                        </div>

                                        <div class="more_cta">
                                            <div class="remove_btn">
                                                <svg id="Layer_1" style="enable-background:new 0 0 24 24;" version="1.1" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path class="st0" d="M18,22H6V8H4v13.7C4,22.8,4.9,24,6,24h12c1.1,0,2-1.2,2-2.3V8h-2V22z M15,4V2H9v2H2v2h20V4H15z M9,10v10h2V10H9  z M13,10v10h2V10H13z"/></svg>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                                    <label>CTA type:</label>
                                                    <Multiselect v-model="CtaType.value" v-bind="CtaType" placeholder="Select" />
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                                    <label>CTA Button Text:</label>
                                                    <input type="text" />
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                                    <label>Enter CTA URL/Javascript function/duda popup id:</label>
                                                    <input type="text" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="add_more">
                                            <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <router-link to="/"><button class="default_btn">Add More CTA <span><svg class="feather feather-plus" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><line x1="12" x2="12" y1="5" y2="19"/><line x1="5" x2="19" y1="12" y2="12"/></svg></span></button></router-link>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from "@vueform/multiselect";
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';

    export default {
        components: {
            Multiselect,
            Datepicker
        },
        data() {
            return {
                //Upload Images
                    preview: null,
                    image: null,
                    preview_list: [],
                    image_list: [],
                //Company Dropdown
                Company: {
                    mode: "single",
                    value: "Ram Partners",
                    options: [
                        { value: "Ram Partners", label: "Ram Partners" },
                        { value: "Coastal Ridge", label: "Coastal Ridge" },
                        { value: "Audubon", label: "Audubon" },
                        { value: "Waterton", label: "Waterton" },
                        { value: "Pegasus Residential", label: "Pegasus Residential" },
                    ],
                    searchable: false,
                    createTag: false,
                },
                //Pet Policy Type
                PetPolicy: {
                    mode: "single",
                    value: "API",
                    options: [
                        { value: "Manual", label: "Manual" },
                        { value: "API", label: "API" },
                    ],
                    searchable: false,
                    createTag: false,
                },
                //State
                State: {
                    mode: "single",
                    value: "Atlanta",
                    options: [
                        { value: "Atlanta", label: "Atlanta" },
                        { value: "Acworth", label: "Acworth" },
                        { value: "Adairsville", label: "Adairsville" },
                        { value: "Allenhurst", label: "Allenhurst" },
                        { value: "Alpharetta", label: "Alpharetta" },
                        { value: "Alto", label: "Alto" },
                        { value: "Annawan", label: "Annawan" },
                        { value: "Antwerp", label: "Antwerp" },
                        { value: "Archbold", label: "Archbold" },
                        { value: "Arnoldsville", label: "Arnoldsville" },
                    ],
                    searchable: true,
                    createTag: false,
                },
                //City
                City: {
                    mode: "single",
                    value: "Georgia",
                    options: [
                        { value: "ANDERSONVILLE / EDGEWATER", label: "ANDERSONVILLE / EDGEWATER" },
                        { value: "BUENA PARK", label: "BUENA PARK" },
                        { value: "FULTON MARKET", label: "FULTON MARKET" },
                        { value: "GOLD COAST", label: "GOLD COAST" },
                        { value: "LAKEVIEW", label: "LAKEVIEW" },
                        { value: "LINCOLN PARK", label: "LINCOLN PARK" },
                        { value: "LOGAN SQUARE", label: "LOGAN SQUARE" },
                        { value: "OLD TOWN", label: "OLD TOWN" },
                        { value: "RIVER WEST", label: "RIVER WEST" },
                        { value: "ROGERS PARK", label: "ROGERS PARK" },
                    ],
                    searchable: true,
                    createTag: false,
                },
                //Tags
                value: [
                    { name: 'Javascript', code: 'js' }
                ],
                options: [
                { name: 'Vue.js', code: 'vu' },
                { name: 'Javascript', code: 'js' },
                { name: 'Open Source', code: 'os' }
                ],
                //CTA Type 
                CtaType: {
                    mode: 'single',
                    value: 'Select CTA Type',
                    options: [
                        { value: 'HTML', label: 'HTML' },
                        { value: 'Javascript Function', label: 'Javascript Function' },
                        { value: 'Duda Site Popup', label: 'Duda Site Popup' },
                        { value: 'API', label: 'API' },
                        { value: 'Slug', label: 'Slug' },
                        { value: 'Property Website', label: 'Property Website' },
                        { value: 'Custom URL(Community Specific)', label: 'Custom URL(Community Specific)' },
                    ],
                    searchable: false,
                    createTag: false
                },
            };
        },
        methods: {
            //Preview Box
            openpreviewBox: function() {
                const x = document.getElementById('previewBox');
                if(x.classList.contains('previewBox--active')){
                    x.classList.remove('previewBox--active')
                }else{
                    x.classList.add('previewBox--active')
                }
            },
            //Upload Images
            previewImage: function(event) {
                var input = event.target;
                if (input.files) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                    this.preview = e.target.result;
                    }
                    this.image=input.files[0];
                    reader.readAsDataURL(input.files[0]);
                }
            },
            previewMultiImage: function(event) {
                var input = event.target;
                var count = input.files.length;
                var index = 0;
                if (input.files) {
                    while(count --) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        this.preview_list.push(e.target.result);
                    }
                    this.image_list.push(input.files[index]);
                    reader.readAsDataURL(input.files[index]);
                    index ++;
                    }
                }
            },
                reset: function() {
                this.image = null;
                this.preview = null;
                this.image_list = [];
                this.preview_list = [];
                },
            //Tag
            addTag (newTag) {
            const tag = {
                name: newTag,
                code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
            }
            this.options.push(tag)
            this.value.push(tag)
            },
        },
    };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
