<template>
  <div class="container-fluid d-flex">
    <div class="general_configuration">
      <div class="status_bar">
        <ul>
          <li :class="{ 'active' : step == 1 || step > 1}">
              <span>1</span>
              <h6>Step 1</h6>
          </li>
          <li :class="{ 'active' : step == 2 || step > 2}">
              <span>2</span>
              <h6>Step 2</h6>
          </li>
          <li :class="{ 'active' : step == 3 || step > 3}">
              <span>3</span>
              <h6>Step 3</h6>
          </li>
          <li :class="{ 'active' : step == 4 || step > 4}">
              <span>4</span>
              <h6>Step 4</h6>
          </li>
          <li :class="{ 'active' : step == 5 || step > 5 }">
              <span>5</span>
              <h6>Step 5</h6>
          </li>
        </ul>
        <div class="total_bar"><span :style="`width:${(100 / 4) * (step - 1) }%`"></span></div>
      </div>
      <div class="general_configuration_details">
        <div class="top_pannel">
          <div class="heading">
            <div class="select_fld">
              <Multiselect v-model="configurations.value" v-bind="configurations"/>
            </div>
          </div>
          <div class="check-box"><label>Show Preview</label><input type="checkbox" v-model="has_preview"/></div>
        </div>
        <div class="panel_wpr" v-if="configurations.value == 'general'">
          <div class="card">
            <div class="card-body">
              <div class="Page_content">
                <div class="row mb-0">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> Site Available Page URL:
                        <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                          <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width="427px"
                              height="427px"
                              viewBox="0 0 427 427"
                              enable-background="new 0 0 427 427"
                              xml:space="preserve"
                          >
                              <g>
                                  <polyline
                                      fill="none"
                                      stroke="#E89C9D"
                                      stroke-width="15"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-miterlimit="10"
                                      points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                  />
                                  <circle cx="208" cy="129.5" r="15.5" />
                                  <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                              </g>
                          </svg>
                        </span>
                      </label>
                      <div class="select_fld">
                        <Multiselect v-model="siteAvailable.value" v-bind="siteAvailable" placeholder="https://www.thestandardpinestone.com/apply-now"/>   
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> Move in Date Configuration:
                        <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                          <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width="427px"
                              height="427px"
                              viewBox="0 0 427 427"
                              enable-background="new 0 0 427 427"
                              xml:space="preserve"
                          >
                              <g>
                                  <polyline
                                      fill="none"
                                      stroke="#E89C9D"
                                      stroke-width="15"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-miterlimit="10"
                                      points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                  />
                                  <circle cx="208" cy="129.5" r="15.5" />
                                  <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                              </g>
                          </svg>
                        </span>
                      </label>
                      <div class="custom_radio">
                          <input type="radio" name="date_configuration" id="day_wise" v-model="date_configuration" value="day"/>
                          <label for="day_wise">Day Wise</label>
                          <input type="radio" name="date_configuration" id="date_wise" v-model="date_configuration" value="date"/>
                          <label for="date_wise">Date Wise</label>
                      </div>
                    </div>
                    <!-- <div class="items">
                      <label class="switch btn-color-mode-switch">
                        <input type="checkbox" name="color_mode" id="color_mode" value="1">
                        <label for="color_mode" data-on="Date Wise" data-off="Day Wise" class="btn-color-mode-switch-inner"></label>
                      </label> 
                    </div> -->
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <div class="form-group">
                      <label> Days previous from move in? (for Result):
                        <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                          <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width="427px"
                              height="427px"
                              viewBox="0 0 427 427"
                              enable-background="new 0 0 427 427"
                              xml:space="preserve"
                          >
                              <g>
                                  <polyline
                                      fill="none"
                                      stroke="#E89C9D"
                                      stroke-width="15"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-miterlimit="10"
                                      points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                  />
                                  <circle cx="208" cy="129.5" r="15.5" />
                                  <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                              </g>
                          </svg>
                        </span>
                      </label>                          
                      <div class="select_fld"><Multiselect v-model="Days.value" v-bind="Days" placeholder="120"/></div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <div class="form-group">
                      <label> Days next from move in? (for Result):
                        <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                          <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width="427px"
                              height="427px"
                              viewBox="0 0 427 427"
                              enable-background="new 0 0 427 427"
                              xml:space="preserve"
                          >
                              <g>
                                  <polyline
                                      fill="none"
                                      stroke="#E89C9D"
                                      stroke-width="15"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-miterlimit="10"
                                      points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                  />
                                  <circle cx="208" cy="129.5" r="15.5" />
                                  <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                              </g>
                          </svg>
                        </span>
                      </label>                          
                      <div class="select_fld"><Multiselect v-model="NextDays.value" v-bind="NextDays" placeholder="120"/></div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <div class="form-group">
                      <label> How many upcoming days show on Calendar? :
                        <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                          <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width="427px"
                              height="427px"
                              viewBox="0 0 427 427"
                              enable-background="new 0 0 427 427"
                              xml:space="preserve"
                          >
                              <g>
                                  <polyline
                                      fill="none"
                                      stroke="#E89C9D"
                                      stroke-width="15"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-miterlimit="10"
                                      points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                  />
                                  <circle cx="208" cy="129.5" r="15.5" />
                                  <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                              </g>
                          </svg>
                        </span>
                      </label>                         
                      <div class="select_fld"><Multiselect v-model="UpcomingDays.value" v-bind="UpcomingDays" placeholder="365"/></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="Page_content">
                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> Schedule tour button Action type:
                        <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                          <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width="427px"
                              height="427px"
                              viewBox="0 0 427 427"
                              enable-background="new 0 0 427 427"
                              xml:space="preserve"
                          >
                              <g>
                                  <polyline
                                      fill="none"
                                      stroke="#E89C9D"
                                      stroke-width="15"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-miterlimit="10"
                                      points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                  />
                                  <circle cx="208" cy="129.5" r="15.5" />
                                  <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                              </g>
                          </svg>
                        </span>
                      </label>
                      <div class="select_fld">
                        <Multiselect v-model="UpcomingDays.value" v-bind="UpcomingDays" placeholder="Javascript Function "/>
                      </div>                      
                    </div>             
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> Enter Schedule tour button URL/Javascript function?:
                        <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                          <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width="427px"
                              height="427px"
                              viewBox="0 0 427 427"
                              enable-background="new 0 0 427 427"
                              xml:space="preserve"
                          >
                              <g>
                                  <polyline
                                      fill="none"
                                      stroke="#E89C9D"
                                      stroke-width="15"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-miterlimit="10"
                                      points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                  />
                                  <circle cx="208" cy="129.5" r="15.5" />
                                  <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                              </g>
                          </svg>
                        </span>
                      </label>
                      <div class="select_fld">
                        <Multiselect v-model="UpcomingDays.value" v-bind="UpcomingDays" placeholder="event.preventDefault(); knockDoorway.openScheduling();"/>
                      </div>                     
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> Contact Button Action type:
                        <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                          <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width="427px"
                              height="427px"
                              viewBox="0 0 427 427"
                              enable-background="new 0 0 427 427"
                              xml:space="preserve"
                          >
                              <g>
                                  <polyline
                                      fill="none"
                                      stroke="#E89C9D"
                                      stroke-width="15"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-miterlimit="10"
                                      points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                  />
                                  <circle cx="208" cy="129.5" r="15.5" />
                                  <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                              </g>
                          </svg>
                        </span>
                      </label>
                      <div class="select_fld">
                        <Multiselect v-model="UpcomingDays.value" v-bind="UpcomingDays" placeholder="Javascript Function"/>
                      </div>                      
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> Enter Contact button URL/Javascript /duda popup id?:
                        <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                          <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width="427px"
                              height="427px"
                              viewBox="0 0 427 427"
                              enable-background="new 0 0 427 427"
                              xml:space="preserve"
                          >
                              <g>
                                  <polyline
                                      fill="none"
                                      stroke="#E89C9D"
                                      stroke-width="15"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-miterlimit="10"
                                      points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                  />
                                  <circle cx="208" cy="129.5" r="15.5" />
                                  <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                              </g>
                          </svg>
                        </span>
                      </label>
                      <div class="select_fld">
                        <Multiselect v-model="UpcomingDays.value" v-bind="UpcomingDays" placeholder="event.preventDefault(); knockDoorway.openScheduling();"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> Apply Now Action type:
                        <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                          <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width="427px"
                              height="427px"
                              viewBox="0 0 427 427"
                              enable-background="new 0 0 427 427"
                              xml:space="preserve"
                          >
                              <g>
                                  <polyline
                                      fill="none"
                                      stroke="#E89C9D"
                                      stroke-width="15"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-miterlimit="10"
                                      points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                  />
                                  <circle cx="208" cy="129.5" r="15.5" />
                                  <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                              </g>
                          </svg>
                        </span>
                      </label>  
                      <div class="select_fld">
                        <Multiselect v-model="UpcomingDays.value" v-bind="UpcomingDays" placeholder="Javascript Function"/>
                      </div>                      
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> Enter Apply Now URL/Javascript function?:
                        <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                          <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width="427px"
                              height="427px"
                              viewBox="0 0 427 427"
                              enable-background="new 0 0 427 427"
                              xml:space="preserve"
                          >
                              <g>
                                  <polyline
                                      fill="none"
                                      stroke="#E89C9D"
                                      stroke-width="15"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-miterlimit="10"
                                      points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                  />
                                  <circle cx="208" cy="129.5" r="15.5" />
                                  <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                              </g>
                          </svg>
                        </span>
                      </label>     
                      <div class="select_fld">
                        <input type="text" />
                      </div>                   
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> Schedule Tour Toggle:</label>
                      <div class="check-box"><input type="checkbox"></div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> Schedule tour button Action type:
                        <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                          <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width="427px"
                              height="427px"
                              viewBox="0 0 427 427"
                              enable-background="new 0 0 427 427"
                              xml:space="preserve"
                          >
                              <g>
                                  <polyline
                                      fill="none"
                                      stroke="#E89C9D"
                                      stroke-width="15"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-miterlimit="10"
                                      points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                  />
                                  <circle cx="208" cy="129.5" r="15.5" />
                                  <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                              </g>
                          </svg>
                        </span>
                      </label> 
                      <div class="select_fld">                        
                        <Multiselect v-model="UpcomingDays.value" v-bind="UpcomingDays" placeholder="Select"/>
                      </div>                      
                    </div>                   
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> Enter Schedule tour button URL/Javascript function?:
                        <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                          <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width="427px"
                              height="427px"
                              viewBox="0 0 427 427"
                              enable-background="new 0 0 427 427"
                              xml:space="preserve"
                          >
                              <g>
                                  <polyline
                                      fill="none"
                                      stroke="#E89C9D"
                                      stroke-width="15"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-miterlimit="10"
                                      points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                  />
                                  <circle cx="208" cy="129.5" r="15.5" />
                                  <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                              </g>
                          </svg>
                        </span>
                      </label>
                      <div class="select_fld">
                        <input type="text" />
                      </div>                      
                    </div>                   
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> Virtual Tour Button Action type:
                        <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                          <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width="427px"
                              height="427px"
                              viewBox="0 0 427 427"
                              enable-background="new 0 0 427 427"
                              xml:space="preserve"
                          >
                              <g>
                                  <polyline
                                      fill="none"
                                      stroke="#E89C9D"
                                      stroke-width="15"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-miterlimit="10"
                                      points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                  />
                                  <circle cx="208" cy="129.5" r="15.5" />
                                  <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                              </g>
                          </svg>
                        </span>
                      </label> 
                      <div class="select_fld">                        
                        <Multiselect v-model="UpcomingDays.value" v-bind="UpcomingDays" placeholder="Select"/>
                      </div>                       
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> VirtualTour Toggle With SchedulerTour:</label>
                      <div class="check-box"><input type="checkbox"></div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> Virtual Tour Button URL/Javascript function?:
                        <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                          <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width="427px"
                              height="427px"
                              viewBox="0 0 427 427"
                              enable-background="new 0 0 427 427"
                              xml:space="preserve"
                          >
                              <g>
                                  <polyline
                                      fill="none"
                                      stroke="#E89C9D"
                                      stroke-width="15"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-miterlimit="10"
                                      points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                  />
                                  <circle cx="208" cy="129.5" r="15.5" />
                                  <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                              </g>
                          </svg>
                        </span>
                      </label>  
                      <div class="select_fld">                       
                        <input type="text" />
                      </div>                     
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> Enter Virtual Tour Button Name?:
                        <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                          <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width="427px"
                              height="427px"
                              viewBox="0 0 427 427"
                              enable-background="new 0 0 427 427"
                              xml:space="preserve"
                          >
                              <g>
                                  <polyline
                                      fill="none"
                                      stroke="#E89C9D"
                                      stroke-width="15"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-miterlimit="10"
                                      points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                  />
                                  <circle cx="208" cy="129.5" r="15.5" />
                                  <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                              </g>
                          </svg>
                        </span>
                      </label>
                      <div class="select_fld">                       
                        <input type="text" />
                      </div>                       
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> Gallery Button Action type:
                        <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                          <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width="427px"
                              height="427px"
                              viewBox="0 0 427 427"
                              enable-background="new 0 0 427 427"
                              xml:space="preserve"
                          >
                              <g>
                                  <polyline
                                      fill="none"
                                      stroke="#E89C9D"
                                      stroke-width="15"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-miterlimit="10"
                                      points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                  />
                                  <circle cx="208" cy="129.5" r="15.5" />
                                  <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                              </g>
                          </svg>
                        </span>
                      </label>
                      <div class="select_fld">                       
                        <Multiselect v-model="UpcomingDays.value" v-bind="UpcomingDays" placeholder="Select"/>
                      </div>                       
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> Enter Gallery Button URL?:
                        <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                          <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width="427px"
                              height="427px"
                              viewBox="0 0 427 427"
                              enable-background="new 0 0 427 427"
                              xml:space="preserve"
                          >
                              <g>
                                  <polyline
                                      fill="none"
                                      stroke="#E89C9D"
                                      stroke-width="15"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-miterlimit="10"
                                      points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                  />
                                  <circle cx="208" cy="129.5" r="15.5" />
                                  <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                              </g>
                          </svg>
                        </span>
                      </label>
                      <div class="select_fld">                       
                        <input type="text" />
                      </div>                        
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> Enter Gallery Button Name?:
                        <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                          <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width="427px"
                              height="427px"
                              viewBox="0 0 427 427"
                              enable-background="new 0 0 427 427"
                              xml:space="preserve"
                          >
                              <g>
                                  <polyline
                                      fill="none"
                                      stroke="#E89C9D"
                                      stroke-width="15"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-miterlimit="10"
                                      points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                  />
                                  <circle cx="208" cy="129.5" r="15.5" />
                                  <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                              </g>
                          </svg>
                        </span>
                      </label>
                      <div class="select_fld">                       
                        <input type="text" />
                      </div>                      
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> Email Send To:</label>
                      <div class="select_fld">                       
                        <input type="text" />
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> Website floorplan url / slug:</label>
                      <div class="select_fld">                       
                        <input type="text" value="/floor-plans/"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> Open in New Window (for ShortCode - (″rrac_floorplanGrp_mods″) & (″rrac_search_filter″)):</label>
                      <div class="check-box"><input type="checkbox"></div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> Floorplan Url Parameter Type:</label>
                      <div class="select_fld">
                        <Multiselect v-model="UpcomingDays.value" v-bind="UpcomingDays" placeholder="Select"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> View Available Apts toggle:</label>
                      <div class="check-box"><input type="checkbox"></div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-3 col-sm-12">
                    <div class="form-group">
                      <label> Pop Up Button Choice :</label>
                      <div class="select_fld">
                        <label class="switch btn-color-mode-switch">
                          <input type="checkbox" name="color_mode" id="color_mode1" value="2">
                          <label for="color_mode1" data-on="Scheduler Tour" data-off="Apply Now" class="btn-color-mode-switch-inner choice_btn"></label>
                        </label> 
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label> Enter Custom button Name:
                        <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                          <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width="427px"
                              height="427px"
                              viewBox="0 0 427 427"
                              enable-background="new 0 0 427 427"
                              xml:space="preserve"
                          >
                              <g>
                                  <polyline
                                      fill="none"
                                      stroke="#E89C9D"
                                      stroke-width="15"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-miterlimit="10"
                                      points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                  />
                                  <circle cx="208" cy="129.5" r="15.5" />
                                  <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                              </g>
                          </svg>
                        </span>
                      </label>  
                      <div class="select_fld">
                        <input type="text" placeholder="Enter Custom Button Name"/>
                      </div>                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TemplateConfiguration v-if="configurations.value == 'template'"/>
      </div>
      <div class="update_bottom">
        <ul>
          <li class="mr-auto"><span>*</span> Press update button to save this changes</li>
          <li class="ml-2"><router-link to="/"><button class="update_btn">Update</button></router-link></li>
          <li class="ml-2"><button class="next_btn" @click="nextStep">Next</button></li>
        </ul>
      </div>
    </div>
    <LeftPreviewPanel v-model="has_preview"/>
  </div>
</template>
      
<script>
  import LeftPreviewPanel from "@/views/LeftPreviewPanel.vue"
  import Multiselect from '@vueform/multiselect'
  import TemplateConfiguration from "./TemplateConfiguration.vue"

  export default {
    components: {
      LeftPreviewPanel,
      Multiselect,
      TemplateConfiguration
    },
    data() {
        return {
          date_configuration: 'day',
          has_preview: false,
          siteAvailable: {
            mode: 'single',
            value: 'https://www.thestandardpinestone.com/apply-now',
            options: [
                { value: 'https://www.thestandardpinestone.com/apply-now', label: 'https://www.thestandardpinestone.com/apply-now' }
            ],
            searchable: false,
            createTag: false
          },
          configurations: {
              mode: 'single',
              value: 'general',
              options: [
                  { value: 'general', label: 'General Configuration' },
                  { value: 'template', label: 'Template Configuration' },
                  { value: 'floorplandetails', label: 'Floor Plan Details' },
                  { value: 'floorplansorting', label: 'Floor Plan Sorting' },
                  { value: 'penthouseurbanstudio', label: 'Penthouse/Urban Studio' },
                  { value: 'priceconfiguration', label: 'Price Configuration' },
                  { value: 'depositprice', label: 'Deposit Price Configuration (Unit Wise)' },
                  { value: 'callforpricingmanual ', label: 'Call For Pricing Manual Configuration: ( Price Independent )' },
                  { value: 'callforpricing', label: 'Call For Pricing Configuration: ( Price Dependent - When Price Is 0 )' },
                  { value: 'leasingconfiguration', label: 'Leasing Configuration' },
                  { value: 'allpopupconfiguration', label: 'All Pop Up Configuration' },
                  { value: 'joinourwaitlistsection', label: 'Join Our Waitlist Section:(No Availibility)' },
                  { value: 'availibilitytabsection', label: 'Availibility Tab Section' },
                  { value: 'availibilitymessagesection', label: 'Availibility Message Section' },
                  { value: 'noavailibilitymessagesection', label: 'No Availibility Message Section' },
                  { value: 'landingpage', label: 'Landing Page Configuration' },
                  { value: 'maximumavailableunit', label: 'Maximum Available Unit Configuration' },
                  { value: 'amenitiespagesettings', label: 'Amenities Page Settings' },
                  { value: 'detailspagesettings', label: 'Details Page Settings' },
                  { value: 'floorplanfieldshowhide', label: 'Floorplan Fields Show/Hide' },
                  { value: 'leasenowurl', label: 'Lease Now URL Configuration: ( Unit Base )' },
                  { value: 'unitlistcolumnshowhide', label: 'Unit List Column Show/Hide' },
                  { value: 'interactiveleasing', label: 'Interactive Leasing' },
                  { value: 'othersettings', label: 'Other Settings' },
                  { value: 'listviewsortingsettings', label: 'List View Sorting Settings' },
                  { value: 'showactiveviewes', label: 'Show Active Viewes' },
                  { value: 'displaycustommessage', label: 'Display Custom Message (Below List)' },
                  { value: 'floorplandetails', label: 'Floorplan Details From (Real Page)' },
                  { value: 'floorplangroup', label: 'Floorplan Group Configuration' },
                  { value: 'sisterproperty', label: 'Sister Property Configuration' },
              ],
              searchable: false,
              createTag: false
          },
          Days: {
              mode: 'single',
              value: '120',
              options: [
                  { value: '120', label: '120' },
                  { value: '120', label: '120' },
              ],
              searchable: false,
              createTag: false
          },
          //Next Days
          NextDays: {
              mode: 'single',
              value: '120',
              options: [
                  { value: '120', label: '120' },
                  { value: '120', label: '120' },
              ],
              searchable: false,
              createTag: false
          },
          //Upcoming Days
          UpcomingDays: {
              mode: 'single',
              value: '365',
              options: [
                  { value: '365', label: '365' },
                  { value: '365', label: '365' },
              ],
              searchable: false,
              createTag: false
          },
        };
    },
    watch:{
      has_preview(val){
        const bar = document.getElementById('mySidenav');
        const pnl = document.getElementById('synchronize');
        const btn = document.getElementById('toggle-menu');
        
        if(val && bar.classList.contains('second-sidemenu--active')){
          bar.classList.remove('second-sidemenu--active');
          pnl.classList.remove('main_content_synchronize');
          btn.classList.remove('active');
        }
      }
    },
    methods:{
      nextStep(){
        console.log('fdsfsd');
      }
    }
  };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>