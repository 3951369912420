<template>
    <nav class="second-sidemenu" id="mySidenav">
        <ul class="resp-tabs-container">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <!-- <h4>Site Configuration</h4> -->
                    <h4>{{ $route.meta && $route.meta.title ? $route.meta.title : 'Dashboard' }}</h4>
                    <div class="panel-body " :class="{ 'active': isOpenA }" v-if="$route.meta.title == 'Apartment Sync'">
                        <h3 @click="isOpenA = !isOpenA">Configuration <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg></span></h3>
                        <collapse-transition :duration="300">
                            <div class="menu_details" v-show="isOpenA">
                                <h5>Check APIs</h5>
                                <ul>
                                    <!-- <li @click="isOpenN = !isOpenN" :class="{ 'active': isOpenN }">API Credential <span class="arrow"><svg width="800px" height="800px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#000000" d="M831.872 340.864 512 652.672 192.128 340.864a30.592 30.592 0 0 0-42.752 0 29.12 29.12 0 0 0 0 41.6L489.664 714.24a32 32 0 0 0 44.672 0l340.288-331.712a29.12 29.12 0 0 0 0-41.728 30.592 30.592 0 0 0-42.752 0z"/></svg></span>
                                        <ul class="sub_menu_list" v-show="isOpenN">
                                            <li>Real Page
                                                <ul class="sub_menu_list_child">
                                                    <li><router-link to="/repliapartmentsyncs/real-page-onesite">Onesite</router-link></li>
                                                    <li><router-link to="/repliapartmentsyncs/real-page-rpx">RPX</router-link></li>
                                                    <li><router-link to="/repliapartmentsyncs/real-page-single-site">Single Site ID</router-link></li>
                                                    <li><router-link to="/repliapartmentsyncs/real-page-multiple-site">Multiple Site ID</router-link></li>
                                                </ul>
                                            </li>
                                            <li>Rent Cafe
                                                <ul class="sub_menu_list_child">
                                                    <li><router-link to="/repliapartmentsyncs/rent-cafe-single-site">Single Site ID</router-link></li>
                                                    <li><router-link to="/repliapartmentsyncs/rent-cafe-multiple-site">Multiple Site ID</router-link></li>
                                                </ul>
                                            </li>
                                            <li>Entrata
                                                <ul class="sub_menu_list_child">
                                                    <li><router-link to="/repliapartmentsyncs/entrata-single-site">Single Site ID</router-link></li>
                                                    <li><router-link to="/repliapartmentsyncs/entrata-multiple-site">Multiple Site ID</router-link></li>
                                                </ul>
                                            </li>
                                            <li><router-link to="/repliapartmentsyncs/custom">Custom</router-link></li>
                                        </ul>
                                    </li> -->
                                    <li><router-link to="/repliapartmentsyncs/api-credential">API Credential</router-link></li>
                                    <li><router-link to="/repliapartmentsyncs/general-configuration">API Data Settings</router-link></li>
                                    <li><router-link to="/repliapartmentsyncs/cta-management">CTA Management</router-link></li>
                                    <li><router-link to="/repliapartmentsyncs/corn-setup">Cron Setup</router-link></li>
                                    <li><router-link to="/repliapartmentsyncs/customscript">Custom Script</router-link></li>
                                    <li><router-link to="/repliapartmentsyncs/colorstyle-settings">Color/style Settings</router-link></li>
                                    <li><router-link to="/repliapartmentsyncs/API-user-guide">API User Guide</router-link></li>
                                </ul>
                            </div> 
                        </collapse-transition>                       
                    </div>
                    <div class="panel-body" :class="{ 'active': isOpenB }" v-if="$route.meta.title == 'Apartment Sync'">
                        <h3 @click="isOpenB = !isOpenB">Floor Plan Management <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg></span></h3>
                        <collapse-transition :duration="300">
                            <div class="menu_details" v-show="isOpenB">
                                <h5>Check APIs</h5>
                                <ul>
                                    <li><router-link to="/repliapartmentsyncs/floorplan-group">Floor Plan Group</router-link></li>
                                    <li><router-link to="/repliapartmentsyncs/floorplan-list">Floor Plan List</router-link></li>
                                    <li><router-link to="/repliapartmentsyncs/additional-group">Additional Group</router-link></li>
                                    <li><router-link to="/repliapartmentsyncs/floor-plan-group-sorting">Floor Plan Group Sorting</router-link></li>
                                    <li><router-link to="/repliapartmentsyncs/floor-plan-group-management">Floor Plan Group Management</router-link></li>
                                    <li><router-link to="/repliapartmentsyncs/floor-plan-traffic">Floor Plan Traffic</router-link></li>
                                </ul>
                            </div>
                        </collapse-transition>                      
                    </div>
                    <div class="panel-body" :class="{ 'active': isOpenC }" v-if="$route.meta.title == 'Apartment Sync'">
                        <h3 @click="isOpenC = !isOpenC">Floor Plan Sync <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg></span></h3>
                        <collapse-transition :duration="300">
                            <div class="menu_details" v-show="isOpenC">
                                <ul>
                                    <li><router-link to="/repliapartmentsyncs/floor-plan-sync">Floor Plan Sync</router-link></li>
                                    <li><router-link to="/repliapartmentsyncs/floorplan-group">Floor Plan Group</router-link></li>
                                    <li><router-link to="/repliapartmentsyncs/floorplan-list">Floor Plan List</router-link></li>
                                </ul>
                            </div>
                        </collapse-transition>                        
                    </div>
                    <div class="panel-body" :class="{ 'active': isOpenD}" v-if="$route.meta.title == 'Apartment Sync'">
                        <h3 @click="isOpenD = !isOpenD">Cron Details <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg></span></h3>
                        <collapse-transition :duration="300">
                            <div class="menu_details" v-show="isOpenD">
                                <ul>
                                    <li><router-link to="/repliapartmentsyncs/cron-details">Cron Details</router-link></li>
                                </ul>
                            </div>
                        </collapse-transition>                        
                    </div>
                    <div class="panel-body" :class="{ 'active': isOpenE}" v-if="$route.meta.title == 'Company Management'">
                        <h3 @click="isOpenE = !isOpenE">Company Management <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg></span></h3>
                        <collapse-transition :duration="300">
                            <div class="menu_details" v-show="isOpenE">
                                <ul>
                                    <li><router-link to="/companymanagement/companies">Companies</router-link>
                                        <ul class="sub_menu_list_child">
                                            <li><router-link to="/companymanagement/companies-create">Create Companies</router-link></li>
                                            <li><router-link to="/companymanagement/companies-configuration">Configuration</router-link></li>
                                            <li><router-link to="/companymanagement/colorstyle-setting">Color/Style Settings</router-link></li>
                                            <li><router-link to="/companymanagement/api-user-guide">API User Guide</router-link></li>
                                            <li><router-link to="/companymanagement/dynamic-map">Dynamic Map</router-link></li>
                                        </ul>
                                    </li>
                                    <li><router-link to="/communitywebsites/community">Community Websites</router-link></li>
                                    <li><a>Locations</a></li>
                                    <li><a>Tag List</a></li>
                                    <li><a>Additional Feature</a></li>
                                    <li><router-link to="/companymanagement/microsites">Microsites</router-link></li>
                                    <li><router-link to="/companymanagement/floorplan-type">Floorplan Type</router-link></li>
                                    <li><router-link to="/companymanagement/living-options-list">Living Options</router-link></li>
                                </ul>
                            </div>
                        </collapse-transition>                       
                    </div>
                    <div class="panel-body" :class="{ 'active': isOpenF}" v-if="$route.meta.title == 'Prospect Guide'">
                        <h3 @click="isOpenF = !isOpenF">Prospect Guide <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg></span></h3>
                        <collapse-transition :duration="300">
                            <div class="menu_details" v-show="isOpenF">
                                <ul>
                                    <li><router-link to="/prospectguide/pospects">Pospects</router-link></li>
                                    <li><a>New pospect</a></li>
                                    <li><a>Media</a></li>
                                    <li><router-link to="/prospectguide/template-list">Templates</router-link></li>
                                </ul>
                            </div>
                        </collapse-transition>                       
                    </div>
                </div>
            </div>
        </ul>
    </nav>
</template>
     
 
     <script>
     import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';
     export default {
         name:"Sidebar",
         components: {
            CollapseTransition,
        },
        data() {
            return {
                //Collapse
                isOpenA: true,
                isOpenB: false,
                isOpenC: false,
                isOpenD: false,
                isOpenE: true,
                isOpenF: true,
                isOpenG: false,
                isOpenH: false,
                isOpenI: false,
                isOpenJ: false,
                isOpenK: false,
                isOpenL: false,
                isOpenM: false,
                isOpenN: false,
                isOpenO: false,
            };
        },
     }
     </script>