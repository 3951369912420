<template>
   <div class="container p-5">
    <div class="row align-items-center">
        <div class="col">
            <h1 class="text-center">This is Testing Page...</h1>
        </div>
    </div>
   </div>
</template>
<script>

export default{

}
</script>
