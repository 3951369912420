<template>
    <div class="container-fluid dynamicmap">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="general_configuration">
                    <div class="general_configuration_details">
                        <div class="top_pannel">
                            <div class="heading">
                                <h3>Dynamic Map Options</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-header align-items-center d-flex justify-content-end">
                                        <button class="default_btn"><span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg></span>  Default Map</button>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Map Latitude</label>
                                                    <input type="text" placeholder="34.0655397" />
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Map Longitude</label>
                                                    <input type="text" placeholder="-84.0733668" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card card-height-100">
                                    <div class="card-header align-items-center d-flex justify-content-end">
                                        <h4 class="card-title mb-0 flex-grow-1">Map Control Options</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Map Type</label>
                                                    <div class="radio-box">
                                                        <ul>
                                                            <li>
                                                                <input type="radio" id="f-option" name="selector">
                                                                <label for="f-option">Roadmap</label>
                                                                <div class="check"></div>
                                                            </li>
                                                            <li>
                                                                <input type="radio" id="s-option" name="selector">
                                                                <label for="s-option">Satellite</label>
                                                                <div class="check">
                                                                    <div class="inside"></div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <input type="radio" id="s-option" name="selector">
                                                                <label for="s-option">Both</label>
                                                                <div class="check">
                                                                    <div class="inside"></div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Fullscreen Control</label>
                                                    <div class="check-box"><input type="checkbox"></div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Rotate Control</label>
                                                    <div class="check-box"><input type="checkbox"></div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>StreetView Control</label>
                                                    <div class="check-box"><input type="checkbox"></div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Scale Control</label>
                                                    <div class="check-box"><input type="checkbox"></div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Zoom Control</label>
                                                    <div class="check-box"><input type="checkbox"></div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Keyboard Shortcuts Control</label>
                                                    <div class="check-box"><input type="checkbox"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card card-height-100">
                                    <div class="card-header align-items-center d-flex justify-content-end">
                                        <h4 class="card-title mb-0 flex-grow-1">Map Color Option</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Map Type</label>
                                                    <div class="radio-box">
                                                        <ul>
                                                            <li>
                                                                <input type="radio" id="f-option" name="selector">
                                                                <label for="f-option">Choose Color</label>
                                                                <div class="check"></div>
                                                            </li>
                                                            <li>
                                                                <input type="radio" id="s-option" name="selector">
                                                                <label for="s-option">Custom Style</label>
                                                                <div class="check">
                                                                    <div class="inside"></div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12">
                                                <div class="form-group">
                                                    <label>Map Custom Style:</label>
                                                    <textarea />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card card-height-100">
                                    <div class="card-header align-items-center d-flex justify-content-end">
                                        <h4 class="card-title mb-0 flex-grow-1">State Separater</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>State Separate Stroke</label>
                                                    <div class="check-box"><input type="checkbox"></div>
                                                </div>
                                            </div>

                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <div class="color_panel">
                                                        <label>State Separate Stroke Color</label>
                                                        <color-picker v-model:pureColor="pureColor" v-model:gradientColor="gradientColor"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card card-height-100">
                                    <div class="card-header align-items-center d-flex justify-content-end">
                                        <h4 class="card-title mb-0 flex-grow-1">Land Option</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <div class="color_panel">
                                                        <label>Land Color</label>
                                                        <color-picker v-model:pureColor="pureColor" v-model:gradientColor="gradientColor"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <div class="color_panel">
                                                        <label>Place Name Label Color</label>
                                                        <color-picker v-model:pureColor="pureColor" v-model:gradientColor="gradientColor"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Province Show</label>
                                                    <div class="check-box"><input type="checkbox"></div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <div class="color_panel">
                                                        <label>Province Label Color</label>
                                                        <color-picker v-model:pureColor="pureColor" v-model:gradientColor="gradientColor"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>POI Places Show</label>
                                                    <div class="check-box"><input type="checkbox"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card card-height-100">
                                    <div class="card-header align-items-center d-flex justify-content-end">
                                        <h4 class="card-title mb-0 flex-grow-1">Park Option</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Park Show</label>
                                                    <div class="check-box"><input type="checkbox"></div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <div class="color_panel">
                                                        <label>Park or Ground Land Color</label>
                                                        <color-picker v-model:pureColor="pureColor" v-model:gradientColor="gradientColor"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <div class="color_panel">
                                                        <label>Park or Ground Label Color</label>
                                                        <color-picker v-model:pureColor="pureColor" v-model:gradientColor="gradientColor"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card card-height-100">
                                    <div class="card-header align-items-center d-flex justify-content-end">
                                        <h4 class="card-title mb-0 flex-grow-1">Road Option</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Highway Show</label>
                                                    <div class="check-box"><input type="checkbox"></div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <div class="color_panel">
                                                        <label>Highway Color</label>
                                                        <color-picker v-model:pureColor="pureColor" v-model:gradientColor="gradientColor"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Local Road Show</label>
                                                    <div class="check-box"><input type="checkbox"></div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <div class="color_panel">
                                                        <label>Local Road Color</label>
                                                        <color-picker v-model:pureColor="pureColor" v-model:gradientColor="gradientColor"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <div class="color_panel">
                                                        <label>All Road Label Color</label>
                                                        <color-picker v-model:pureColor="pureColor" v-model:gradientColor="gradientColor"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card card-height-100">
                                    <div class="card-header align-items-center d-flex justify-content-end">
                                        <h4 class="card-title mb-0 flex-grow-1">Transit Option</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <div class="color_panel">
                                                        <label>Transit Line Show</label>
                                                        <div class="check-box"><input type="checkbox"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <div class="color_panel">
                                                        <label>Transit Line Color</label>
                                                        <color-picker v-model:pureColor="pureColor" v-model:gradientColor="gradientColor"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <div class="color_panel">
                                                        <label>Transit Station Show</label>
                                                        <div class="check-box"><input type="checkbox"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <div class="color_panel">
                                                        <label>Transit Station Color</label>
                                                        <color-picker v-model:pureColor="pureColor" v-model:gradientColor="gradientColor"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card card-height-100">
                                    <div class="card-header align-items-center d-flex justify-content-end">
                                        <h4 class="card-title mb-0 flex-grow-1">Water Option</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <div class="color_panel">
                                                        <label>Water Show</label>
                                                        <div class="check-box"><input type="checkbox"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <div class="color_panel">
                                                        <label>Water Color</label>
                                                        <color-picker v-model:pureColor="pureColor" v-model:gradientColor="gradientColor"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <div class="color_panel">
                                                        <label>Water Label Color</label>
                                                        <color-picker v-model:pureColor="pureColor" v-model:gradientColor="gradientColor"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="update_bottom">
                    <ul>
                        <li><span>*</span> Press update button to save this changes</li>
                        <li>
                            <router-link to="/"><button class="update_btn">Update</button></router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="map_preview" @click="map_preview()"><span>Map Preview</span></div>
    <div class="map_preview_panel" id="map_preview_panel">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">                                             
                <div class="heading"><h4>Map Preview</h4> <div class="close"><svg width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="icomoon-ignore"></g><path d="M6.576 6.576c-5.205 5.205-5.205 13.643 0 18.849s13.643 5.205 18.849-0c5.206-5.206 5.206-13.643 0-18.849s-13.643-5.205-18.849 0zM24.67 24.67c-4.781 4.781-12.56 4.781-17.341 0s-4.781-12.56 0-17.341c4.781-4.781 12.56-4.781 17.341 0s4.78 12.56-0 17.341z" fill="#000000"></path><path d="M10.722 9.969l-0.754 0.754 5.278 5.278-5.253 5.253 0.754 0.754 5.253-5.253 5.253 5.253 0.754-0.754-5.253-5.253 5.278-5.278-0.754-0.754-5.278 5.278z" fill="#000000"></path></svg></div></div>
                <div class="panel-body">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3439487.016778956!2d-85.42156027896554!3d32.6627959228634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f136c51d5f8157%3A0x6684bc10ec4f10e7!2sGeorgia%2C%20USA!5e0!3m2!1sen!2sin!4v1676621868617!5m2!1sen!2sin" width="100%" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ColorPicker } from "vue3-colorpicker";
    import "vue3-colorpicker/style.css";

    export default {
        components: {
            ColorPicker,
        },
        data() {
            return {

            };
        },
        methods: {
            //Map Preview Panle
            map_preview: function () {
                const x = document.getElementById("map_preview_panel");
                if (x.classList.contains("map_preview_active")) {
                    x.classList.remove("map_preview_active");
                } else {
                    x.classList.add("map_preview_active");
                }
            },
        },
    };
</script>
