<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="general_configuration">
                    <div class="general_configuration_details">
                        <div class="top_pannel">
                            <div class="heading">
                                <h3>
                                    Unit List Column Show/Hide:
                                    <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                <svg
                                                    version="1.1"
                                                    id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    width="427px"
                                                    height="427px"
                                                    viewBox="0 0 427 427"
                                                    enable-background="new 0 0 427 427"
                                                    xml:space="preserve"
                                                >
                                                    <g>
                                                        <polyline
                                                            fill="none"
                                                            stroke="#E89C9D"
                                                            stroke-width="15"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            stroke-miterlimit="10"
                                                            points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                        />
                                                        <circle cx="208" cy="129.5" r="15.5" />
                                                        <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                    </g>
                                                </svg>
                                            </span>
                                </h3>
                            </div>
                            <div class="back">
                                <router-link to="/SiteConfiguration"><h3><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M109.3 288L480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288z"/></svg></span>Back</h3></router-link>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-header align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">
                                            Info:
                                            <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                <svg
                                                    version="1.1"
                                                    id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    width="427px"
                                                    height="427px"
                                                    viewBox="0 0 427 427"
                                                    enable-background="new 0 0 427 427"
                                                    xml:space="preserve"
                                                >
                                                    <g>
                                                        <polyline
                                                            fill="none"
                                                            stroke="#E89C9D"
                                                            stroke-width="15"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            stroke-miterlimit="10"
                                                            points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                        />
                                                        <circle cx="208" cy="129.5" r="15.5" />
                                                        <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                    </g>
                                                </svg>
                                            </span>
                                        </h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="card card-height-100">
                                                    <div class="card-header align-items-center d-flex">
                                                        <h4 class="card-title mb-0 flex-grow-1">Show Unit Number: </h4>
                                                    </div>
                                                    <div class="card-body">
                                                        <Checkbox />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="card card-height-100">
                                                    <div class="card-header align-items-center d-flex">
                                                        <h4 class="card-title mb-0 flex-grow-1">Show Finish:</h4>
                                                    </div>
                                                    <div class="card-body">
                                                        <Checkbox />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="card card-height-100">
                                                    <div class="card-header align-items-center d-flex">
                                                        <h4 class="card-title mb-0 flex-grow-1">Show Special:</h4>
                                                    </div>
                                                    <div class="card-body">
                                                        <Checkbox />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="card card-height-100">
                                                    <div class="card-header align-items-center d-flex">
                                                        <h4 class="card-title mb-0 flex-grow-1">Show Deposit:</h4>
                                                    </div>
                                                    <div class="card-body">
                                                        <Checkbox />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="card card-height-100">
                                                    <div class="card-header align-items-center d-flex">
                                                        <h4 class="card-title mb-0 flex-grow-1">Show SQFT:</h4>
                                                    </div>
                                                    <div class="card-body">
                                                        <Checkbox />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="card card-height-100">
                                                    <div class="card-header align-items-center d-flex">
                                                        <h4 class="card-title mb-0 flex-grow-1">Show Price:</h4>
                                                    </div>
                                                    <div class="card-body">
                                                        <Checkbox />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="card card-height-100">
                                                    <div class="card-header align-items-center d-flex">
                                                        <h4 class="card-title mb-0 flex-grow-1">Show Availability:</h4>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="switch_toggle">
                                                            <Checkbox />
                                                            <div class="price_lable">
                                                                <input type="text" placeholder="Price Label"/>
                                                            </div>
                                                        </div>                                                                    
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="card card-height-100">
                                                    <div class="card-header align-items-center d-flex">
                                                        <h4 class="card-title mb-0 flex-grow-1">Show Lease:</h4>
                                                    </div>
                                                    <div class="card-body">
                                                        <Checkbox />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="card card-height-100">
                                                    <div class="card-header align-items-center d-flex">
                                                        <h4 class="card-title mb-0 flex-grow-1">Building No Show / Hide (Template 4):</h4>
                                                    </div>
                                                    <div class="card-body">
                                                        <Checkbox />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="update_bottom">
                    <ul>
                        <li><span>*</span> Press update button to save this changes</li>
                        <li>
                            <router-link to="/"><button class="update_btn">Update</button></router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <LeftPreviewPanel/> -->
        </div>
    </div>
</template>

          
<script>
    import LeftPreviewPanel from "@/views/LeftPreviewPanel.vue";
    import Multiselect from '@vueform/multiselect';
    import Checkbox from "@/components/Checkbox.vue";
    import { ColorPicker } from "vue3-colorpicker";
    import "vue3-colorpicker/style.css";


    export default {
        components: {
          LeftPreviewPanel,
          Multiselect,
          Checkbox,
          ColorPicker,
        },
        data() {
            return {
            //Availibility Unit
            availableUnit: {
                mode: 'single',
                value: 'Default',
                options: [
                    { value: 'Default', label: 'Default'},
                    { value: '1', label: '1'},
                    { value: '2', label: '2'},
                    { value: '3', label: '3'},
                    { value: '4', label: '4'}
                ],
                searchable: false,
                createTag: false
            },
            date: null,
            };
        },
    };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>