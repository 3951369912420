<template>
    <div class="container-fluid floorplantypeadd">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="general_configuration">
                    <div class="general_configuration_details">
                        <div class="top_pannel">
                            <div class="heading">
                                <h3>Floorplan Type Add</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Plan Name</label>
                                                    <input type="text" />
                                                </div>
                                            </div>
                                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
                                                <div class="form-group">
                                                    <label>Status</label>
                                                    <div class="check-box"><input type="checkbox" checked></div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <div class="form-group">
                                                    <label>Icon</label>
                                                    <div class="file_wrapper">
                                                        <input type="file" accept="image/*" @change="previewImage" class="form-control-file" id="my-file" />
                                                        <div class="preview_Show" @click="openpreviewBox()"><span class="icon"><svg fill="#000000" width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M30 3.25h-28c-0.414 0-0.75 0.336-0.75 0.75v0 24c0 0.022 0.011 0.041 0.013 0.063 0.012 0.103 0.040 0.198 0.082 0.284l-0.002-0.005c0.050 0.087 0.11 0.162 0.181 0.225l0.001 0.001c0.017 0.015 0.027 0.035 0.046 0.048 0.015 0.010 0.033 0.006 0.048 0.015 0.107 0.071 0.238 0.115 0.378 0.119l0.001 0 0.002 0.001h28.001c0.414-0 0.75-0.336 0.75-0.75v0-24c-0-0.414-0.336-0.75-0.75-0.75v0zM29.25 4.75v14.771l-6.625-9.937c-0.144-0.191-0.37-0.313-0.625-0.313s-0.481 0.122-0.624 0.311l-0.001 0.002-7.545 11.318-4.361-3.488c-0.127-0.102-0.29-0.164-0.468-0.164-0.253 0-0.477 0.126-0.613 0.318l-0.002 0.002-5.636 8.051v-20.871zM3.44 27.25l5.716-8.164 4.375 3.5c0.121 0.104 0.28 0.167 0.454 0.167 0.046 0 0.091-0.004 0.135-0.013l-0.004 0.001c0.214-0.033 0.394-0.153 0.507-0.322l0.002-0.003 7.376-11.063 7.25 10.876v5.021zM8 12.75c1.519 0 2.75-1.231 2.75-2.75s-1.231-2.75-2.75-2.75c-1.519 0-2.75 1.231-2.75 2.75v0c0.002 1.518 1.232 2.748 2.75 2.75h0zM8 8.75c0.69 0 1.25 0.56 1.25 1.25s-0.56 1.25-1.25 1.25c-0.69 0-1.25-0.56-1.25-1.25v0c0.001-0.69 0.56-1.249 1.25-1.25h0z"></path></svg></span> Image Preview</div>
                                                        <div class="preview_box border p-2 mt-3" id="previewBox">
                                                            <div class="heading">Preview Here: <div class="close"><svg width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="icomoon-ignore"></g><path d="M6.576 6.576c-5.205 5.205-5.205 13.643 0 18.849s13.643 5.205 18.849-0c5.206-5.206 5.206-13.643 0-18.849s-13.643-5.205-18.849 0zM24.67 24.67c-4.781 4.781-12.56 4.781-17.341 0s-4.781-12.56 0-17.341c4.781-4.781 12.56-4.781 17.341 0s4.78 12.56-0 17.341z" fill="#000000"></path><path d="M10.722 9.969l-0.754 0.754 5.278 5.278-5.253 5.253 0.754 0.754 5.253-5.253 5.253 5.253 0.754-0.754-5.253-5.253 5.278-5.278-0.754-0.754-5.278 5.278z" fill="#000000"></path></svg></div></div>
                                                            <template v-if="preview">
                                                                <img :src="preview" class="img-fluid" />
                                                                <p class="mb-0">File name: {{ image.name }}</p>
                                                                <p class="mb-0">Size: {{ image.size/1024 }}KB</p>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                                <div class="form-group">
                                                    <label>Description</label>
                                                    <input type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="update_bottom">
                    <ul>
                        <li><span>*</span> Press update button to save this changes</li>
                        <li>
                            <router-link to="/"><button class="update_btn">Update</button></router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        data() {
            return {
            //Upload Images
                preview: null,
                image: null,
                preview_list: [],
                image_list: [],
            };
        },
        methods: {
            //Preview Box
            openpreviewBox: function() {
                const x = document.getElementById('previewBox');
                if(x.classList.contains('previewBox--active')){
                    x.classList.remove('previewBox--active')
                }else{
                    x.classList.add('previewBox--active')
                }
            },
            //Upload Images
            previewImage: function(event) {
                var input = event.target;
                if (input.files) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                    this.preview = e.target.result;
                    }
                    this.image=input.files[0];
                    reader.readAsDataURL(input.files[0]);
                }
            },
            previewMultiImage: function(event) {
                var input = event.target;
                var count = input.files.length;
                var index = 0;
                if (input.files) {
                    while(count --) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        this.preview_list.push(e.target.result);
                    }
                    this.image_list.push(input.files[index]);
                    reader.readAsDataURL(input.files[index]);
                    index ++;
                    }
                }
            },
                reset: function() {
                this.image = null;
                this.preview = null;
                this.image_list = [];
                this.preview_list = [];
                },
        },
    };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
