<template>
  <div class="repli_dashboard">
    <Sidebar />
    <SidePanelMenu />
    <div class="app-content" id="app_content">
        <Header />
        <div class="main_content configuration" id="synchronize">
          <router-view />
        </div>
      </div>
  </div>
</template>

<script>
  import Header from "@/views/Header.vue";
  import Sidebar from '@/views/Sidebar.vue';
  import SidePanelMenu from "@/views/SidePanelMenu.vue";

  export default {
    components: {
      Header,
      Sidebar,
      SidePanelMenu,
    }
  }
</script>