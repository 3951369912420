<template>
    <div class="container-fluid d-flex">
        <div class="general_configuration">
            <div class="general_configuration_details">
                <div class="top_pannel">
                    <div class="heading">
                        <h3>Color and Style Settings</h3>
                    </div>
                    <div class="check-box"><label>Show Preview</label><input type="checkbox" v-model="has_preview"/></div>
                </div>
                <div class="top_pannel">
                    <ul class="tabs">
                        <li :class="{'active' : tab == 'tab1' }" @click="tab = 'tab1'">Search Page</li>
                        <li :class="{'active' : tab == 'tab2' }" @click="tab = 'tab2'">Sidebar/Filter</li>
                        <li :class="{'active' : tab == 'tab3' }" @click="tab = 'tab3'">Search Bar</li>
                        <li :class="{'active' : tab == 'tab4' }" @click="tab = 'tab4'">Overlay Page</li>
                        <li :class="{'active' : tab == 'tab5' }" @click="tab = 'tab5'">Put Your Own CSS</li>
                    </ul>
                    <button class="default_btn"><span class="icon"><svg viewBox="0 0 36 36" version="1.1"  preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M33,10.05a5.07,5.07,0,0,0,.1-7.17A5.06,5.06,0,0,0,26,3L20.78,8.15a2.13,2.13,0,0,1-3,0l-.67-.67L15.72,8.92,27.08,20.28l1.42-1.42-.67-.67a2.13,2.13,0,0,1,0-3ZM26.44,13.8a4.07,4.07,0,0,0-1.08,1.92l-5.08-5.08A4.07,4.07,0,0,0,22.2,9.56l5.16-5.17a3.09,3.09,0,0,1,4.35-.1,3.09,3.09,0,0,1-.1,4.35Z" class="clr-i-outline clr-i-outline-path-1"></path><path d="M7.3,31.51a2,2,0,1,1-2.83-2.83L18.58,14.57l-1.42-1.41L3.05,27.27a4,4,0,0,0-.68,4.8L.89,33.55A1,1,0,0,0,.89,35a1,1,0,0,0,1.42,0l1.43-1.44a3.93,3.93,0,0,0,2.09.6,4.06,4.06,0,0,0,2.88-1.2L22.82,18.81,21.41,17.4Z" class="clr-i-outline clr-i-outline-path-2"></path><rect x="0" y="0" width="36" height="36" fill-opacity="0"/></svg></span> Set All Default Color</button>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="Page_content">
                            <div class="row" v-if="tab == 'tab1'">
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Highlight Background Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="highlight_bg_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="highlight_bg_clr" format="hex"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Highlight Text Color <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate."><svg viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M16 3c-7.18 0-13 5.82-13 13s5.82 13 13 13 13-5.82 13-13-5.82-13-13-13zM18.039 20.783c-0.981 1.473-1.979 2.608-3.658 2.608-1.146-0.187-1.617-1.008-1.369-1.845l2.16-7.154c0.053-0.175-0.035-0.362-0.195-0.419-0.159-0.056-0.471 0.151-0.741 0.447l-1.306 1.571c-0.035-0.264-0.004-0.7-0.004-0.876 0.981-1.473 2.593-2.635 3.686-2.635 1.039 0.106 1.531 0.937 1.35 1.85l-2.175 7.189c-0.029 0.162 0.057 0.327 0.204 0.379 0.16 0.056 0.496-0.151 0.767-0.447l1.305-1.57c0.035 0.264-0.024 0.726-0.024 0.902zM17.748 11.439c-0.826 0-1.496-0.602-1.496-1.488s0.67-1.487 1.496-1.487 1.496 0.602 1.496 1.487c0 0.887-0.67 1.488-1.496 1.488z"></path></svg></span></label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="highlight_txt_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="highlight_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Highlight Active Background Color <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate."><svg viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M16 3c-7.18 0-13 5.82-13 13s5.82 13 13 13 13-5.82 13-13-5.82-13-13-13zM18.039 20.783c-0.981 1.473-1.979 2.608-3.658 2.608-1.146-0.187-1.617-1.008-1.369-1.845l2.16-7.154c0.053-0.175-0.035-0.362-0.195-0.419-0.159-0.056-0.471 0.151-0.741 0.447l-1.306 1.571c-0.035-0.264-0.004-0.7-0.004-0.876 0.981-1.473 2.593-2.635 3.686-2.635 1.039 0.106 1.531 0.937 1.35 1.85l-2.175 7.189c-0.029 0.162 0.057 0.327 0.204 0.379 0.16 0.056 0.496-0.151 0.767-0.447l1.305-1.57c0.035 0.264-0.024 0.726-0.024 0.902zM17.748 11.439c-0.826 0-1.496-0.602-1.496-1.488s0.67-1.487 1.496-1.487 1.496 0.602 1.496 1.487c0 0.887-0.67 1.488-1.496 1.488z"></path></svg></span></label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="highlight_act_bg_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="highlight_act_bg_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Highlight Active Text Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="highlight_act_txt_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="highlight_act_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Primary Button Background Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="primary_btn_bg_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="primary_btn_bg_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Primary Button Text Color <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate."><svg viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M16 3c-7.18 0-13 5.82-13 13s5.82 13 13 13 13-5.82 13-13-5.82-13-13-13zM18.039 20.783c-0.981 1.473-1.979 2.608-3.658 2.608-1.146-0.187-1.617-1.008-1.369-1.845l2.16-7.154c0.053-0.175-0.035-0.362-0.195-0.419-0.159-0.056-0.471 0.151-0.741 0.447l-1.306 1.571c-0.035-0.264-0.004-0.7-0.004-0.876 0.981-1.473 2.593-2.635 3.686-2.635 1.039 0.106 1.531 0.937 1.35 1.85l-2.175 7.189c-0.029 0.162 0.057 0.327 0.204 0.379 0.16 0.056 0.496-0.151 0.767-0.447l1.305-1.57c0.035 0.264-0.024 0.726-0.024 0.902zM17.748 11.439c-0.826 0-1.496-0.602-1.496-1.488s0.67-1.487 1.496-1.487 1.496 0.602 1.496 1.487c0 0.887-0.67 1.488-1.496 1.488z"></path></svg></span></label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="primary_btn_txt_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="primary_btn_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Primary Button Hover Background Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="primary_btn_hvr_bg_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="primary_btn_hvr_bg_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Primary Button Hover Text Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="primary_btn_hvr_txt_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="primary_btn_hvr_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Secondary Button Background Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="secondary_btn_bg_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="secondary_btn_bg_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Secondary Button Text Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="secondary_btn_txt_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="secondary_btn_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Secondary Button Hover Background Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="secondary_btn_hvr_bg_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="secondary_btn_hvr_bg_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Secondary Button Hover Text Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="secondary_btn_hvr_txt_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="secondary_btn_hvr_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Property Heading Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="property_heading_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="property_heading_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Property Heading Hover Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="property_heading_hvr_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="property_heading_hvr_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Close Filter Text Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="close_filter_txt_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="close_filter_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>No Matching Properties Text Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="no_match_property_txt_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="no_match_property_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="tab == 'tab2'">
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Sidebar Heading Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="sidebar_heading_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="sidebar_heading_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Sidebar Heading Hover Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="sidebar_heading_hvr_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="sidebar_heading_hvr_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Sidebar Clear Button Background Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="sidebar_clear_btn_bg_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="sidebar_clear_btn_bg_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Sidebar Clear Button Background Hover Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="sidebar_clear_btn_bg_hvr_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="sidebar_clear_btn_bg_hvr_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Sidebar Clear Button Text Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="sidebar_clear_btn_txt_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="sidebar_clear_btn_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Sidebar Clear Button Text Hover Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="sidebar_clear_btn_txt_hvr_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="sidebar_clear_btn_txt_hvr_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Sidebar Dropdown Background Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="sidebar_dropdown_bg_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="sidebar_dropdown_bg_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Sidebar Dropdown Background Hover Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="sidebar_dropdown_bg_hvr_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="sidebar_dropdown_bg_hvr_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Sidebar Dropdown Heading Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="sidebar_dropdown_heading_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="sidebar_dropdown_heading_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Sidebar Dropdown Heading Hover Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="sidebar_dropdown_heading_hvr_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="sidebar_dropdown_heading_hvr_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Sidebar Dropdown Option Text Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="sidebar_dropdown_optn_txt_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="sidebar_dropdown_optn_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Sidebar Dropdown Option Text Hover Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="sidebar_dropdown_optn_txt_hvr_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="sidebar_dropdown_optn_txt_hvr_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Sidebar Range Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="sidebar_range_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="sidebar_range_clr"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="tab == 'tab3'">
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Search Bar Background Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="search_bar_bg_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="search_bar_bg_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Search Bar Svg Background Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="search_bar_svg_bg_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="search_bar_svg_bg_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Search Box Shadow Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="search_bar_box_shadow_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="search_bar_box_shadow_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Search Font Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="search_font_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="search_font_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Search Bar Button Background Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="search_bar_btn_bg_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="search_bar_btn_bg_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Search Bar Button Active Background /Search Icon / Location Icon Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="search_bar_btn_act_bg_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="search_bar_btn_act_bg_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Search Bar Button Text Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="search_bar_btn_txt_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="search_bar_btn_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Search Bar Button Active Text Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="search_bar_btn_act_txt_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="search_bar_btn_act_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Search Bar Border Radius (PX)</label>
                                        <div class="has_suffix">
                                            <input type="number" v-model="search_bar_border_radius"/>
                                            <span class="suffix">PX</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Search Bar Button Border Radius (PX)</label>
                                        <div class="has_suffix">
                                            <input type="number" v-model="search_bar_btn_border_radius"/>
                                            <span class="suffix">PX</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="tab == 'tab4'">
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Show/Hide All Location Text Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="show_hide_txt_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="show_hide_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Show/Hide All Location Hover Text Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="show_hide_hvr_txt_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="show_hide_hvr_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Dropdown Text Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="dropdown_txt_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="dropdown_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Dropdown Hover Text Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="dropdown_hvr_txt_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="dropdown_hvr_txt_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Search Button Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="search_btn_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="search_btn_clr"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                    <div class="color_panel">
                                        <label>Search Button Hover Color</label>
                                        <div class="color_wpr">
                                            <input type="text" v-model="search_btn_hvr_clr" placeholder="Enter Color Code"/>
                                            <color-picker v-model:pureColor="search_btn_hvr_clr"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="tab == 'tab5'">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <textarea id="exampleFormControlTextarea5" rows="3" placeholder="Write Your Own CSS Code..."></textarea>
                                </div>
                            </div>
                        </div>
                    </div>                                        
                </div>
            </div>
            <div class="update_bottom">
                <ul>
                    <li><span>*</span> Press update button to save this changes</li>
                    <li>
                        <router-link to="/"><button class="update_btn">Update</button></router-link>
                    </li>
                </ul>
            </div>
        </div>
        <LeftPreviewPanel v-model="has_preview"/>
    </div>
</template>
<!-- colorpicker : https://www.npmjs.com/package/vue3-colorpicker -->

          
<script>
    import LeftPreviewPanel from "@/views/LeftPreviewPanel.vue"
    import { ColorPicker } from "vue3-colorpicker";
    import "vue3-colorpicker/style.css";
    export default {
        components: {
          ColorPicker,
          LeftPreviewPanel
        },
        data() {
            return {
                //Tabs
                tab: 'tab1',
                has_preview: false,
                highlight_bg_clr: '#74cc02',
                highlight_txt_clr: '#ffffff',
                highlight_act_bg_clr: '#32a852',
                highlight_act_txt_clr: '#ffffff',
                primary_btn_bg_clr: '#cc0202',
                primary_btn_txt_clr: '#ffffff',
                primary_btn_hvr_bg_clr: '#000000',
                primary_btn_hvr_txt_clr: '#ffffff',
                secondary_btn_bg_clr: '#cad4be',
                secondary_btn_txt_clr: '#000000',
                secondary_btn_hvr_bg_clr: '#000000',
                secondary_btn_hvr_txt_clr: '#ffffff',
                property_heading_clr: '#000000',
                property_heading_hvr_clr: '#000000',
                close_filter_txt_clr: '#74cc02',
                no_match_property_txt_clr: '#74cc02',
                sidebar_heading_clr: '#a9c23f',
                sidebar_heading_hvr_clr: '#a9c23f',
                sidebar_clear_btn_bg_clr: '#cccccc',
                sidebar_clear_btn_bg_hvr_clr: '#cccccc',
                sidebar_clear_btn_txt_clr: '#000000',
                sidebar_clear_btn_txt_hvr_clr: '#000000',
                sidebar_dropdown_bg_clr: '#ffffff',
                sidebar_dropdown_bg_hvr_clr: '#ffffff',
                sidebar_dropdown_heading_clr: '#000000',
                sidebar_dropdown_heading_hvr_clr: '#000000',
                sidebar_dropdown_optn_txt_clr: '#000000',
                sidebar_dropdown_optn_txt_hvr_clr: '#000000',
                sidebar_range_clr: '#cc0202',
                search_bar_bg_clr: '#f1f1f1',
                search_bar_svg_bg_clr: '#f1f1f1',
                search_bar_box_shadow_clr: '#000000',
                search_font_clr: '#000000',
                search_bar_btn_bg_clr: '#f1f1f1',
                search_bar_btn_act_bg_clr: '#a9c23f',
                search_bar_btn_txt_clr: '#000000',
                search_bar_btn_act_txt_clr: '#ffffff',
                search_bar_border_radius: 50,
                search_bar_btn_border_radius: 20,
                show_hide_txt_clr: '#ffffff',
                show_hide_hvr_txt_clr: '#afaa28',
                dropdown_txt_clr: '#fa0064',
                dropdown_hvr_txt_clr: '#afaa28',
                search_btn_clr: '#fa0064',
                search_btn_hvr_clr: '#f59b00',
            };
        },
    };
</script>