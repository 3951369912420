<template>
    <div class="container-fluid companies">
        <div class="general_configuration">
            <div class="row mb-4 align-items-center">
                <div class="col-xl-8 col-lg-6 col-md-6 col-sm-12">
                    <h1>Companies</h1>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <ul class="repli_apartmentsync_block">
                    <li>
                        <router-link to="/">
                            <button class="">
                                <div class="icon"></div>
                                Company Create
                            </button>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/">
                            <button class="">
                                <div class="icon">
                                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m13 7.101.01.001a4.978 4.978 0 0 1 2.526 1.362 5.005 5.005 0 0 1 1.363 2.528 5.061 5.061 0 0 1-.001 2.016 4.976 4.976 0 0 1-1.363 2.527l1.414 1.414a7.014 7.014 0 0 0 1.908-3.54 6.98 6.98 0 0 0 0-2.819 6.957 6.957 0 0 0-1.907-3.539 6.97 6.97 0 0 0-2.223-1.5 6.921 6.921 0 0 0-1.315-.408c-.137-.028-.275-.043-.412-.063V2L9 6l4 4V7.101zm-7.45 7.623c.174.412.392.812.646 1.19.249.37.537.718.854 1.034a7.036 7.036 0 0 0 2.224 1.501c.425.18.868.317 1.315.408.167.034.338.056.508.078v2.944l4-4-4-4v3.03c-.035-.006-.072-.003-.107-.011a4.978 4.978 0 0 1-2.526-1.362 4.994 4.994 0 0 1 .001-7.071L7.051 7.05a7.01 7.01 0 0 0-1.5 2.224A6.974 6.974 0 0 0 5 12a6.997 6.997 0 0 0 .55 2.724z"></path></svg>
                                </div>
                                Reload
                            </button>
                        </router-link>
                    </li>
                </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div class="dataTable_top">
                        <ul>
                            <li>
                                <div class="list_length">
                                    <Multiselect v-model="list.value" v-bind="list" placeholder="5"/>
                                </div>
                            </li>
                            <li v-if="selected_items.length">
                                <div class="bulk_action">
                                    <Multiselect v-model="bulk_action.value" v-bind="bulk_action" placeholder="Bulk Actions" />
                                </div>
                            </li>
                            <li>
                                <div class="show_action_icon">
                                <input
                                    class="styled-checkbox"
                                    id="styled-checkbox-2"
                                    type="checkbox"
                                    value="value2"
                                    hidden
                                />
                                <label for="styled-checkbox-2" @click="showAllAction">Show All Action Icons</label>
                                </div>
                            </li>
                            <li class="ml-auto">
                                <div class="sorting">
                                    <div class="sort_btn" @click="sorting = 'ascending'" v-if="sorting == 'descending'">
                                        <svg
                                        viewBox="0 0 256 256"
                                        xmlns="http://www.w3.org/2000/svg"
                                        >
                                        <rect
                                            fill="none"
                                            height="256"
                                            width="256"
                                        />
                                        <polyline
                                            fill="none"
                                            points="144 168 184 208 224 168"
                                            stroke="#000"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="12"
                                        />
                                        <line
                                            fill="none"
                                            stroke="#000"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="12"
                                            x1="184"
                                            x2="184"
                                            y1="112"
                                            y2="208"
                                        />
                                        <line
                                            fill="none"
                                            stroke="#000"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="12"
                                            x1="48"
                                            x2="120"
                                            y1="128"
                                            y2="128"
                                        />
                                        <line
                                            fill="none"
                                            stroke="#000"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="12"
                                            x1="48"
                                            x2="184"
                                            y1="64"
                                            y2="64"
                                        />
                                        <line
                                            fill="none"
                                            stroke="#000"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="12"
                                            x1="48"
                                            x2="104"
                                            y1="192"
                                            y2="192"
                                        />
                                        </svg>
                                    </div>
                                    <div class="sort_btn" @click="sorting = 'descending'" v-if="sorting == 'ascending'">
                                        <svg
                                        viewBox="0 0 256 256"
                                        xmlns="http://www.w3.org/2000/svg"
                                        >
                                        <rect
                                            fill="none"
                                            height="256"
                                            width="256"
                                        />
                                        <polyline
                                            fill="none"
                                            points="144 88 184 48 224 88"
                                            stroke="#000"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="12"
                                        />
                                        <line
                                            fill="none"
                                            stroke="#000"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="12"
                                            x1="184"
                                            x2="184"
                                            y1="144"
                                            y2="48"
                                        />
                                        <line
                                            fill="none"
                                            stroke="#000"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="12"
                                            x1="48"
                                            x2="120"
                                            y1="128"
                                            y2="128"
                                        />
                                        <line
                                            fill="none"
                                            stroke="#000"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="12"
                                            x1="48"
                                            x2="104"
                                            y1="64"
                                            y2="64"
                                        />
                                        <line
                                            fill="none"
                                            stroke="#000"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="12"
                                            x1="48"
                                            x2="184"
                                            y1="192"
                                            y2="192"
                                        />
                                        </svg>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="sorting">
                                    <div class="sort_btn" @click="filter_panel_btn()">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"/></svg>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="search">
                                <input
                                    type="text"
                                    placeholder="Search Here...."
                                />
                                <div class="search_icon">
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    >
                                    <path
                                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"
                                    />
                                    </svg>
                                </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="card">
                        <div class="card-body p-0">
                            <div class="dataTable">
                                <div class="table-responsive m-0">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col"><div class="custom_check-box"><input type="checkbox" id="all_id" v-model="select_all" @click="toggleAll"><label for="all_id"></label> ID</div></th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Created At</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(items, i) in apartmentsyncsitelist" :key="i">
                                                <td><span class="res-label">ID:</span><div class="custom_check-box"><input type="checkbox" :id="`company-${i}`" :value="items.ID" v-model="selected_items"><label :for="`company-${i}`"></label>{{items.ID}}</div></td>
                                                <td><span class="res-label">Name:</span>{{items.Name}}</td>
                                                <td><span class="res-label">Created At:</span>{{items.CreatedAt}}</td>
                                                <td><span class="res-label">Status:</span>{{items.Status}}</td>
                                                <td>
                                                    <span class="res-label">Action:</span>
                                                    <div class="action_btn" @click="openAction_btn">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                            <path
                                                                d="M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zM120 96c0 30.9-25.1 56-56 56S8 126.9 8 96S33.1 40 64 40s56 25.1 56 56z"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div class="action_btn_panle">
                                                        <div class="action_btn_group">
                                                            <ul>
                                                                <li @click="openSubNav"><div class="icons" title="Configure"><svg enable-background="new 0 0 32 32" id="Editable-line" version="1.1" viewBox="0 0 32 32"  width="auto" height="20"  xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><circle cx="16" cy="16" fill="none" id="XMLID_224_" r="4" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/><path d="  M27.758,10.366l-1-1.732c-0.552-0.957-1.775-1.284-2.732-0.732L23.5,8.206C21.5,9.36,19,7.917,19,5.608V5c0-1.105-0.895-2-2-2h-2  c-1.105,0-2,0.895-2,2v0.608c0,2.309-2.5,3.753-4.5,2.598L7.974,7.902C7.017,7.35,5.794,7.677,5.242,8.634l-1,1.732  c-0.552,0.957-0.225,2.18,0.732,2.732L5.5,13.402c2,1.155,2,4.041,0,5.196l-0.526,0.304c-0.957,0.552-1.284,1.775-0.732,2.732  l1,1.732c0.552,0.957,1.775,1.284,2.732,0.732L8.5,23.794c2-1.155,4.5,0.289,4.5,2.598V27c0,1.105,0.895,2,2,2h2  c1.105,0,2-0.895,2-2v-0.608c0-2.309,2.5-3.753,4.5-2.598l0.526,0.304c0.957,0.552,2.18,0.225,2.732-0.732l1-1.732  c0.552-0.957,0.225-2.18-0.732-2.732L26.5,18.598c-2-1.155-2-4.041,0-5.196l0.526-0.304C27.983,12.546,28.311,11.323,27.758,10.366z  " fill="none" id="XMLID_242_" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/></svg></div></li>
                                                                <li><div class="icons" title="Sync"><svg id="Layer_1" style="enable-background:new 0 0 30 30;" version="1.1" viewBox="0 0 30 30" width="auto" height="20" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M22.143,23.365  C20.221,25.008,17.726,26,15,26C8.925,26,4,21.075,4,15" style="fill:none;stroke:#ffffff;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;"/><path d="M7.857,6.635  C9.779,4.992,12.274,4,15,4c6.075,0,11,4.925,11,11" style="fill:none;stroke:#ffffff;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;"/><polygon points="26,20 22,14 30,14 "/><polygon points="4,10 8,16 0,16 "/></svg></div></li>
                                                                <li><div class="icons"  title="Edit"><svg viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg" width="auto" height="16"><path d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"/></svg></div></li>
                                                                <li><div class="icons" title="Script"><svg viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg" width="auto" height="16"><path d="M416 31.94C416 21.75 408.1 0 384.1 0c-13.98 0-26.87 9.072-30.89 23.18l-128 448c-.8404 2.935-1.241 5.892-1.241 8.801C223.1 490.3 232 512 256 512c13.92 0 26.73-9.157 30.75-23.22l128-448C415.6 37.81 416 34.85 416 31.94zM176 143.1c0-18.28-14.95-32-32-32c-8.188 0-16.38 3.125-22.62 9.376l-112 112C3.125 239.6 0 247.8 0 255.1S3.125 272.4 9.375 278.6l112 112C127.6 396.9 135.8 399.1 144 399.1c17.05 0 32-13.73 32-32c0-8.188-3.125-16.38-9.375-22.63L77.25 255.1l89.38-89.38C172.9 160.3 176 152.2 176 143.1zM640 255.1c0-8.188-3.125-16.38-9.375-22.63l-112-112C512.4 115.1 504.2 111.1 496 111.1c-17.05 0-32 13.73-32 32c0 8.188 3.125 16.38 9.375 22.63l89.38 89.38l-89.38 89.38C467.1 351.6 464 359.8 464 367.1c0 18.28 14.95 32 32 32c8.188 0 16.38-3.125 22.62-9.376l112-112C636.9 272.4 640 264.2 640 255.1z"/></svg></div></li>
                                                                <li><div class="icons" title="View Floorplan Groups"><svg enable-background="new 0 0 32 32" width="auto" height="26" id="Layer_1" version="1.1"  viewBox="0 0 32 32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><polyline fill="none" points="   649,137.999 675,137.999 675,155.999 661,155.999  " stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/><polyline fill="none" points="   653,155.999 649,155.999 649,141.999  " stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/><polyline fill="none" points="   661,156 653,162 653,156  " stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/></g><g><g><path d="M16,25c-4.265,0-8.301-1.807-11.367-5.088c-0.377-0.403-0.355-1.036,0.048-1.413c0.404-0.377,1.036-0.355,1.414,0.048    C8.778,21.419,12.295,23,16,23c4.763,0,9.149-2.605,11.84-7c-2.69-4.395-7.077-7-11.84-7c-4.938,0-9.472,2.801-12.13,7.493    c-0.272,0.481-0.884,0.651-1.363,0.377c-0.481-0.272-0.649-0.882-0.377-1.363C5.147,10.18,10.333,7,16,7    c5.668,0,10.853,3.18,13.87,8.507c0.173,0.306,0.173,0.68,0,0.985C26.853,21.819,21.668,25,16,25z"/></g><g><path d="M16,21c-2.757,0-5-2.243-5-5s2.243-5,5-5s5,2.243,5,5S18.757,21,16,21z M16,13c-1.654,0-3,1.346-3,3s1.346,3,3,3    s3-1.346,3-3S17.654,13,16,13z"/></g></g></svg></div></li>
                                                                <li><div class="icons" title="Amenities"><svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="auto" height="19"><g><path d="M0 0h24v24H0z" fill="none"/><path d="M9 3v2H4v14h16v-9h2v10a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h6zm9.95 2L16 2.05 17.414.636l5.34 5.34A.6.6 0 0 1 22.33 7H14a2 2 0 0 0-2 2v6h-2V9a4 4 0 0 1 4-4h4.95z"/></g></svg></div></li>
                                                                <li><div class="icons" title="Show Floor Plan Traffic Graph"><svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width="auto" height="19"><path d="M396.8 352h22.4c6.4 0 12.8-6.4 12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v230.4c0 6.4 6.4 12.8 12.8 12.8zm-192 0h22.4c6.4 0 12.8-6.4 12.8-12.8V140.8c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v198.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h22.4c6.4 0 12.8-6.4 12.8-12.8V204.8c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8zM496 400H48V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16zm-387.2-48h22.4c6.4 0 12.8-6.4 12.8-12.8v-70.4c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v70.4c0 6.4 6.4 12.8 12.8 12.8z"/></svg></div></li>
                                                                <li><div class="icons" title="Open Link"><svg class="bi bi-link" fill="currentColor" viewBox="0 0 16 16" width="25px" height="auto"  xmlns="http://www.w3.org/2000/svg"><path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/><path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"/></svg></div></li>
                                                                <li class="active_icon"  title="Copy Link"><div class="icons"><svg width="auto" height="20"  viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48h-48z" fill="none"/><path d="M32 2h-24c-2.21 0-4 1.79-4 4v28h4v-28h24v-4zm6 8h-22c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h22c2.21 0 4-1.79 4-4v-28c0-2.21-1.79-4-4-4zm0 32h-22v-28h22v28z"/></svg></div></li>
                                                                <li><div class="icons"  title="Delete"><svg enable-background="new 0 0 32 32" width="auto" height="20"   id="Layer_1" version="1.1" viewBox="0 0 32 32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><polyline fill="none" points="   649,137.999 675,137.999 675,155.999 661,155.999  " stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/><polyline fill="none" points="   653,155.999 649,155.999 649,141.999  " stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/><polyline fill="none" points="   661,156 653,162 653,156  " stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/></g><g><path d="M28,6h-6.382l-1.724-3.447C19.725,2.214,19.379,2,19,2h-6c-0.379,0-0.725,0.214-0.895,0.553L10.382,6H4   C3.448,6,3,6.448,3,7s0.448,1,1,1h20v20H8V11c0-0.552-0.448-1-1-1s-1,0.448-1,1v18c0,0.553,0.448,1,1,1h18c0.553,0,1-0.447,1-1V8h2   c0.553,0,1-0.448,1-1S28.553,6,28,6z M13.618,4h4.764l1,2h-6.764L13.618,4z"/><path d="M14,24V14c0-0.552-0.448-1-1-1s-1,0.448-1,1v10c0,0.553,0.448,1,1,1S14,24.553,14,24z"/><path d="M20,24V14c0-0.552-0.447-1-1-1s-1,0.448-1,1v10c0,0.553,0.447,1,1,1S20,24.553,20,24z"/></g></svg></div></li>
                                                            </ul>
                                                        </div>
                                                        <div class="right_action_panle" @click="openAction_btn">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                                <path
                                                                    d="M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zM120 96c0 30.9-25.1 56-56 56S8 126.9 8 96S33.1 40 64 40s56 25.1 56 56z"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dataTable_footer">
                        <div class="dataTables_length">Showing 1 to 10 of <span>1,087</span></div>
                        <nav class="data-pagination" data-pagination>
                            <a href="#" disabled>
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 384 512"
                                >
                                <path
                                    d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
                                />
                                </svg>
                            </a>
                            <ul>
                                <li><a href="#">1</a></li>
                                <li><a href="#">2</a></li>
                                <li><a href="#">3</a></li>
                                <li><a href="#">4</a></li>
                                <li class="current"><a a href="#">5</a></li>
                                <li><a a href="#">…</a></li>
                                <li><a a href="#">55</a></li>
                            </ul>
                            <a href="#">
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 384 512"
                                >
                                <path
                                    d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
                                />
                                </svg>
                            </a>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="filter_panel" id="filter_panel">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">                                             
                        <div class="heading"><h4>Filters</h4> <div class="close" @click="filter_panel_btn()"><svg width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="icomoon-ignore"></g><path d="M6.576 6.576c-5.205 5.205-5.205 13.643 0 18.849s13.643 5.205 18.849-0c5.206-5.206 5.206-13.643 0-18.849s-13.643-5.205-18.849 0zM24.67 24.67c-4.781 4.781-12.56 4.781-17.341 0s-4.781-12.56 0-17.341c4.781-4.781 12.56-4.781 17.341 0s4.78 12.56-0 17.341z" fill="#000000"></path><path d="M10.722 9.969l-0.754 0.754 5.278 5.278-5.253 5.253 0.754 0.754 5.253-5.253 5.253 5.253 0.754-0.754-5.253-5.253 5.278-5.278-0.754-0.754-5.278 5.278z" fill="#000000"></path></svg></div></div>
                        <div class="panel-body">
                            <div class="form-group">
                                <label>Select Field</label>
                                <Multiselect v-model="filterColumns.value" v-bind="filterColumns" placeholder="Select Field" />
                            </div>
                            <div class="form-group">
                                <label>Select Field</label>
                                <Multiselect v-model="filterOperators.value" v-bind="filterOperators" placeholder="Select Field" />
                            </div>
                            <div class="form-group">
                                <label>Enter Value</label>
                                <input type="text" placeholder="Enter Value">
                            </div>
                        </div>
                        <div class="filter_btn">
                            <div class="form-group"><button type="button" class="additional_btns">Add Additional Filter</button></div>
                            <div class="form-group"><button type="button" class="apply_btns">Apply</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from "@vueform/multiselect";

    export default {
        components: {
            Multiselect,
        },
        data() {
            return {
                select_all: false,
                selected_items: [],
                show_all: false,
                sorting: 'ascending',
                list: {
                    mode: "single",
                    value: "5",
                    options: [
                        { value: "5", label: "5" },
                        { value: "10", label: "10" },
                        { value: "25", label: "25" },
                        { value: "50", label: "50" },
                        { value: "100", label: "100" },
                    ],
                    searchable: false,
                    createTag: false,
                },
                bulk_action: {
                    mode: "single",
                    value: "Bulk Actions",
                    options: [
                        { value: "Bulk Name", label: "Bulk Name" },
                        { value: "Bulk Status", label: "Bulk Status" },
                        { value: "Bulk Created At", label: "Bulk Created At" },
                        { value: "Bulk", label: "Bulk" },
                    ],
                    searchable: false,
                    createTag: false,
                },
                filterColumns: {
                    mode: "single",
                    value: "Select Field",
                    options: [
                        { value: "Select field", label: "Select field" },
                        { value: "Name", label: "Name" },
                        { value: "Status", label: "Status" },
                        { value: "Created At", label: "Created At" },
                    ],
                    searchable: false,
                    createTag: false,
                },
                filterOperators: {
                    mode: "single",
                    value: "Select Field",
                    options: [
                        { value: "Select field", label: "Select field" },
                        { value: "Contains", label: "Contains" },
                        { value: "Is equal to", label: "Is equal to" },
                        { value: "Greater than", label: "Greater than" },
                        { value: "Less than", label: "Less than" },
                    ],
                    searchable: false,
                    createTag: false,
                },
                apartmentsyncsitelist: [
                    {
                        ID: 25,
                        Name: "AlphaCapitalPartners",
                        CreatedAt: "2021-01-20",
                        Status: "Published",
                    },
                    {
                        ID: 35,
                        Name: "RealSource Management",
                        CreatedAt: "2021-08-16",
                        Status: "Published",
                    },
                    {
                        ID: 40,
                        Name: "Reside Living",
                        CreatedAt: "2022-03-30",
                        Status: "Published",
                    },
                    {
                        ID: 45,
                        Name: "American Landmark",
                        CreatedAt: "2022-03-30",
                        Status: "Published",
                    },
                    {
                        ID: 48,
                        Name: "HillPointe",
                        CreatedAt: "2023-03-02",
                        Status: "Published",
                    },
                ],
            };
        },
        methods: {
            //Action btn panle
            openAction_btn(ev) {
                const el = ev.currentTarget.closest('td');
                const elTarget = el.querySelector('.action_btn_panle');
                const allEl = document.querySelectorAll(".action_btn_panle");

                if (elTarget.classList.contains("action_btn_panle_active")) {
                    elTarget.classList.remove("action_btn_panle_active");
                } else {
                    for(let i = 0; i < allEl.length; i++){
                    allEl[i].classList.remove("action_btn_panle_active");
                    }
                    elTarget.classList.add("action_btn_panle_active");
                }

            },

            openSubNav(){
                const vm = this;
                document.getElementById('mySidenav').classList.add('second-sidemenu--active');
                document.getElementById('synchronize').classList.add('main_content_synchronize');
                document.getElementById('toggle-menu').classList.add('active');

                vm.$router.push('/companymanagement/companies-configuration');

                // const bar = document.getElementById('mySidenav');
                // const pnl = document.getElementById('synchronize');
                // const btn = document.getElementById('toggle-menu');

                // if(bar.classList.contains('second-sidemenu--active')){
                //     bar.classList.remove('second-sidemenu--active');
                //     pnl.classList.remove('main_content_synchronize');
                //     btn.classList.remove('active');
                // }else{
                //     bar.classList.add('second-sidemenu--active');
                //     pnl.classList.add('main_content_synchronize');
                //     btn.classList.add('active');
                // }
                
            },
            //Filter panle
            filter_panel_btn: function () {
                const x = document.getElementById("filter_panel");
                if (x.classList.contains("filter_panel_active")) {
                    x.classList.remove("filter_panel_active");
                } else {
                    x.classList.add("filter_panel_active");
                }
            },
            toggleAll(){
                const vm = this;

                if(!vm.select_all){
                    for(let i = 0; i < vm.apartmentsyncsitelist.length; i++ ){
                        vm.selected_items.push(vm.apartmentsyncsitelist[i].ID);
                    }
                }else{
                    vm.selected_items = [];
                }
            },
            showAllAction(){
                const vm = this;
                vm.show_all = !vm.show_all;
                const allEl = document.querySelectorAll(".action_btn_panle");

                if(vm.show_all){
                    for(let i = 0; i < allEl.length; i++){
                    allEl[i].classList.add("action_btn_panle_active");
                    allEl[i].style.transition = `0.${i + 5}s`;
                    }
                }else{
                    for(let i = 0; i < allEl.length; i++){
                    allEl[i].classList.remove("action_btn_panle_active");
                    }
                }
            },
        },
    };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
