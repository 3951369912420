<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="general_configuration">
                    <div class="general_configuration_details">
                        <div class="top_pannel">
                            <div class="heading">
                                <h3>
                                    Details Page Settings:
                                    <span class="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                                        </svg>
                                    </span>
                                </h3>
                            </div>
                            <div class="back">
                                <router-link to="/SiteConfiguration"><h3><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M109.3 288L480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288z"/></svg></span>Back</h3></router-link>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-header align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">
                                            Details Page Type:
                                            <span class="info"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="427px" height="427px" viewBox="0 0 427 427" enable-background="new 0 0 427 427" xml:space="preserve"><g><polyline fill="none" stroke="#E89C9D" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="191.5,200 212.5,200 212.5,309 232.5,309 	"/><circle cx="208" cy="129.5" r="15.5"/><circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5"/></g></svg></span>
                                        </h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="custom_radio">
                                            <input type="radio" name="size" id="size_13" value="small" />
                                            <label for="size_13">Pop Up</label>
                                            <input type="radio" name="size" id="size_14" value="small" checked/>
                                            <label for="size_14">New Page</label>
                                            <input type="radio" name="size" id="size_14" value="small" />
                                            <label for="size_14">URL</label>
                                        </div>
                                        <div class="new_page">
                                            <h4 class="card-title mb-0 flex-grow-1"> Slug Type:</h4>
                                            <div class="custom_radio">
                                            <input type="radio" name="size" id="size_13" value="small" />
                                            <label for="size_13">Query String</label>
                                            <input type="radio" name="size" id="size_14" value="small" />
                                            <label for="size_14">Last Segment</label>
                                            </div>
                                            <h4 class="card-title mb-0 flex-grow-1"> Details Page Url:</h4>
                                            <textarea id="exampleFormControlTextarea5" rows="3"></textarea>
                                            <h4 class="card-title mb-0 flex-grow-1"> Unit List Position:</h4>
                                            <div class="custom_radio">
                                            <input type="radio" name="size" id="size_13" value="small" />
                                            <label for="size_13">Popup</label>
                                            <input type="radio" name="size" id="size_14" value="small" />
                                            <label for="size_14">Page</label>
                                            </div>
                                            <h4 class="card-title mb-0 flex-grow-1"> Details Page Footer Message:</h4>
                                            <textarea id="exampleFormControlTextarea5" rows="3" placeholder="Details Page Footer message(Leave blank if don't need any text)"></textarea>
                                        </div>
                                        <div class="url">
                                            <h4 class="card-title mb-0 flex-grow-1"> Custom Url:</h4>
                                            <textarea id="exampleFormControlTextarea5" rows="3" placeholder="Details Page Footer message(Leave blank if don't need any text)"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-header align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">
                                            Floorplan Description Config Toggle:
                                            <span class="info"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="427px" height="427px" viewBox="0 0 427 427" enable-background="new 0 0 427 427" xml:space="preserve"><g><polyline fill="none" stroke="#E89C9D" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="191.5,200 212.5,200 212.5,309 232.5,309 	"/><circle cx="208" cy="129.5" r="15.5"/><circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5"/></g></svg></span>
                                        </h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="custom_radio">
                                            <input type="radio" name="size" id="size_13" value="small" checked />
                                            <label for="size_13">Off</label>
                                            <input type="radio" name="size" id="size_14" value="small" />
                                            <label for="size_14">All Device</label>
                                            <input type="radio" name="size" id="size_14" value="small" />
                                            <label for="size_14">Mobile</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-header align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">
                                            Floorplan Description Config:
                                            <span class="info"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="427px" height="427px" viewBox="0 0 427 427" enable-background="new 0 0 427 427" xml:space="preserve"><g><polyline fill="none" stroke="#E89C9D" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="191.5,200 212.5,200 212.5,309 232.5,309 	"/><circle cx="208" cy="129.5" r="15.5"/><circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5"/></g></svg></span>
                                        </h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="custom_radio">
                                            <input type="radio" name="size" id="size_13" value="small" checked />
                                            <label for="size_13">Details Page</label>
                                            <input type="radio" name="size" id="size_14" value="small" />
                                            <label for="size_14">Gallery</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="update_bottom">
                    <ul>
                        <li><span>*</span> Press update button to save this changes</li>
                        <li>
                            <router-link to="/"><button class="update_btn">Update</button></router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <LeftPreviewPanel/> -->
        </div>
    </div>
</template>

          
<script>
    import LeftPreviewPanel from "@/views/LeftPreviewPanel.vue";
    import Multiselect from '@vueform/multiselect';
    import { ColorPicker } from "vue3-colorpicker";
    import "vue3-colorpicker/style.css";

    
    export default {
        components: {
          LeftPreviewPanel,
          Multiselect,
          ColorPicker,
        },
        data() {
            return {
            //Availibility Unit
            availableUnit: {
                mode: 'single',
                value: 'Default',
                options: [
                    { value: 'Default', label: 'Default'},
                    { value: '1', label: '1'},
                    { value: '2', label: '2'},
                    { value: '3', label: '3'},
                    { value: '4', label: '4'}
                ],
                searchable: false,
                createTag: false
            },
            date: null,
            };
        },
    };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>