<template>
    <div class="app-content">
        <Header/>
        <MainContent/>
    </div>
</template>

<script>
import Header from '@/views/Header.vue';
import Sidebar from '@/views/Sidebar.vue';
import SidePanelMenu from "@/views/SidePanelMenu.vue";
import MainContent from '@/views/MainContent.vue';

export default {
    components: {
        Header,
        Sidebar,
        SidePanelMenu,
        MainContent,
  }
}
</script>