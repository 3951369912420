<template>
    <div class="left_preview_panel" :class="{'active' : modelValue}">
      <div class="preview_top">
        <div class="preview_top_heading">
          <div class="heading">
            <h4>Preview</h4>
          </div>
          <div class="check-box"><label>Full View</label><input type="checkbox" /></div>
          <!-- <div class="close_btn">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path
                d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"
              />
            </svg>
          </div> -->
        </div>
      </div>
      <div class="phone">
        <div class="app">
          <div class="content">
            <iframe src="https://app.repli360.com/admin/compare_script_view/1243"></iframe>
            <!-- <div class="loader">
              <svg class="filter" version="1.1">
                <defs>
                  <filter id="gooeyness">
                    <feGaussianBlur
                      in="SourceGraphic"
                      stdDeviation="10"
                      result="blur"
                    />
                    <feColorMatrix
                      in="blur"
                      mode="matrix"
                      values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 20 -12"
                      result="gooeyness"
                    />
                    <feComposite
                      in="SourceGraphic"
                      in2="gooeyness"
                      operator="atop"
                    />
                  </filter>
                </defs>
              </svg>
              <div class="dots">
                <div class="dot mainDot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>
            </div> -->
          </div>
        </div>
        <!-- <div class="statusbar">
          <div class="clock">12:39</div>
          <div class="battery">
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="notch">
          <div class="camera"></div>
          <div class="speaker"></div>
        </div>
        <div class="buttons">
          <span class="power"></span>
          <span class="sleep"></span>
          <span class="up"></span>
          <span class="down"></span>
        </div> -->
      </div>
    </div>
</template>
     
 
<script>
  export default {
    name: "LeftPreviewPanel",

    props: {
        modelValue: Boolean,
    },
    emit: [ 'update:modelValue' ],

    components: {},
    methods: {},
  };
</script>