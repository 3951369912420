<template>
    <div class="panel_wpr">
        <ul class="tabs">
            <li :class="{'active' : tab == 'tab1' }" @click="tab = 'tab1'">Classic</li>
            <li :class="{'active' : tab == 'tab2' }" @click="tab = 'tab2'">Standard</li>
            <li :class="{'active' : tab == 'tab3' }" @click="tab = 'tab3'">Compact</li>
            <li :class="{'active' : tab == 'tab4' }" @click="tab = 'tab4'">Tiles / Deluxe</li>
        </ul>
        <div class="card">
            <div class="card-body">
                <div class="Page_content">
                    <div class="row" v-if="tab == 'tab1'">
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label> Number of Columns:(Desktop):</label>
                                <div class="select_fld">
                                    <Multiselect v-model="siteAvailable.value" v-bind="siteAvailable" placeholder="Select" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label> Number of Row:(All):</label>
                                <div class="select_fld">
                                    <Multiselect v-model="siteAvailable.value" v-bind="siteAvailable" placeholder="All" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label> Number of Columns:(Ipad Landscape): </label>
                                <div class="select_fld">
                                    <Multiselect v-model="siteAvailable.value" v-bind="siteAvailable" placeholder="Select" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label> Number of Columns:(Ipad Potrait): </label>
                                <div class="select_fld">
                                    <Multiselect v-model="siteAvailable.value" v-bind="siteAvailable" placeholder="Select" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-15">
                            <div class="form-group">
                                <label>
                                    List View Selection:
                                    <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                        <svg
                                            version="1.1"
                                            id="Layer_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            width="427px"
                                            height="427px"
                                            viewBox="0 0 427 427"
                                            enable-background="new 0 0 427 427"
                                            xml:space="preserve"
                                        >
                                            <g>
                                                <polyline
                                                    fill="none"
                                                    stroke="#E89C9D"
                                                    stroke-width="15"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-miterlimit="10"
                                                    points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                />
                                                <circle cx="208" cy="129.5" r="15.5" />
                                                <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                            </g>
                                        </svg>
                                    </span>
                                </label>
                                <div class="check-box"><input type="checkbox" /></div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label>
                                    Hamburger Filter:
                                    <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                    <svg
                                        version="1.1"
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        width="427px"
                                        height="427px"
                                        viewBox="0 0 427 427"
                                        enable-background="new 0 0 427 427"
                                        xml:space="preserve"
                                    >
                                        <g>
                                            <polyline
                                                fill="none"
                                                stroke="#E89C9D"
                                                stroke-width="15"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-miterlimit="10"
                                                points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                            />
                                            <circle cx="208" cy="129.5" r="15.5" />
                                            <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                        </g>
                                    </svg>
                                    </span>
                                </label>
                                <div class="check-box"><input type="checkbox" /></div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="tab == 'tab2'">
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label> Show From:</label>
                                <div class="radio-box">
                                    <ul>
                                        <li>
                                            <input type="radio" id="f-option" name="selector">
                                            <label for="f-option">Code</label>
                                            <div class="check"></div>
                                        </li>
                                        <li>
                                            <input type="radio" id="s-option" name="selector">
                                            <label for="s-option">File</label>
                                            <div class="check"><div class="inside"></div></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <div class="form-group">   
                                <label> Number of Row:(All):</label>
                                <div class="select_fld">
                                    <Multiselect v-model="siteAvailable.value" v-bind="siteAvailable" placeholder="All" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label>Number of Columns:(Ipad Landscape):</label>
                                <div class="select_fld">
                                    <Multiselect v-model="siteAvailable.value" v-bind="siteAvailable" placeholder="Select" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label>Number of Columns:(Ipad Potrait):</label>
                                <div class="select_fld">
                                    <Multiselect v-model="siteAvailable.value" v-bind="siteAvailable" placeholder="Select" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-15">
                            <div class="form-group">
                                <label>
                                    List View Selection:
                                    <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                        <svg
                                            version="1.1"
                                            id="Layer_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            width="427px"
                                            height="427px"
                                            viewBox="0 0 427 427"
                                            enable-background="new 0 0 427 427"
                                            xml:space="preserve"
                                        >
                                            <g>
                                                <polyline
                                                    fill="none"
                                                    stroke="#E89C9D"
                                                    stroke-width="15"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-miterlimit="10"
                                                    points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                />
                                                <circle cx="208" cy="129.5" r="15.5" />
                                                <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                            </g>
                                        </svg>
                                    </span>
                                </label>
                                <div class="check-box"><input type="checkbox" /></div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label>
                                    Hamburger Filter:
                                    <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                        <svg
                                            version="1.1"
                                            id="Layer_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            width="427px"
                                            height="427px"
                                            viewBox="0 0 427 427"
                                            enable-background="new 0 0 427 427"
                                            xml:space="preserve"
                                        >
                                            <g>
                                                <polyline
                                                    fill="none"
                                                    stroke="#E89C9D"
                                                    stroke-width="15"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-miterlimit="10"
                                                    points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                />
                                                <circle cx="208" cy="129.5" r="15.5" />
                                                <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                            </g>
                                        </svg>
                                    </span>
                                </label>
                                <div class="check-box"><input type="checkbox" /></div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label> Floor Plan Description Position:
                                    <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                            <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                width="427px"
                                                height="427px"
                                                viewBox="0 0 427 427"
                                                enable-background="new 0 0 427 427"
                                                xml:space="preserve"
                                            >
                                                <g>
                                                    <polyline
                                                        fill="none"
                                                        stroke="#E89C9D"
                                                        stroke-width="15"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-miterlimit="10"
                                                        points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                    />
                                                    <circle cx="208" cy="129.5" r="15.5" />
                                                    <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                </g>
                                            </svg>
                                    </span>
                                </label>
                                <div class="custom_radio">
                                    <input type="radio" name="size" id="size_1" value="small" checked />
                                    <label for="size_1">Top</label>

                                    <input type="radio" name="size" id="size_2" value="small" />
                                    <label for="size_2">Below Availibility</label>

                                    <input type="radio" name="size" id="size_3" value="small" />
                                    <label for="size_3">Below</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="tab == 'tab3'">
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label> Number of Columns (Desktop):</label>
                                <div class="select_fld">
                                    <Multiselect v-model="siteAvailable.value" v-bind="siteAvailable" placeholder="All" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label> Number of Row (All): </label>
                                <div class="select_fld">
                                    <Multiselect v-model="siteAvailable.value" v-bind="siteAvailable" placeholder="Select" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label> Number of Columns (Ipad Landscape): </label>
                                <div class="select_fld">
                                    <Multiselect v-model="siteAvailable.value" v-bind="siteAvailable" placeholder="Select" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label> Number of Columns (Ipad Potrait): </label>
                                <div class="select_fld">
                                    <Multiselect v-model="siteAvailable.value" v-bind="siteAvailable" placeholder="Select" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-15">
                            <div class="form-group">
                                <label>
                                    List View Selection:
                                    <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                        <svg
                                            version="1.1"
                                            id="Layer_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            width="427px"
                                            height="427px"
                                            viewBox="0 0 427 427"
                                            enable-background="new 0 0 427 427"
                                            xml:space="preserve"
                                        >
                                            <g>
                                                <polyline
                                                    fill="none"
                                                    stroke="#E89C9D"
                                                    stroke-width="15"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-miterlimit="10"
                                                    points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                />
                                                <circle cx="208" cy="129.5" r="15.5" />
                                                <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                            </g>
                                        </svg>
                                    </span>
                                </label>
                                <div class="check-box"><input type="checkbox" /></div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label>
                                    Hamburger Filter:
                                    <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                        <svg
                                            version="1.1"
                                            id="Layer_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            width="427px"
                                            height="427px"
                                            viewBox="0 0 427 427"
                                            enable-background="new 0 0 427 427"
                                            xml:space="preserve"
                                        >
                                            <g>
                                                <polyline
                                                    fill="none"
                                                    stroke="#E89C9D"
                                                    stroke-width="15"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-miterlimit="10"
                                                    points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                />
                                                <circle cx="208" cy="129.5" r="15.5" />
                                                <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                            </g>
                                        </svg>
                                </span>
                                </label>
                                <div class="check-box"><input type="checkbox" /></div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="tab == 'tab4'">
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label> Number of Columns (Desktop):</label>
                                <div class="select_fld">
                                    <Multiselect v-model="siteAvailable.value" v-bind="siteAvailable" placeholder="All" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label> Number of Row (All): </label>
                                <div class="select_fld">
                                    <Multiselect v-model="siteAvailable.value" v-bind="siteAvailable" placeholder="Select" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label> Number of Columns (Ipad Landscape): </label>
                                <div class="select_fld">
                                    <Multiselect v-model="siteAvailable.value" v-bind="siteAvailable" placeholder="Select" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label> Number of Columns (Ipad Potrait): </label>
                                <div class="select_fld">
                                    <Multiselect v-model="siteAvailable.value" v-bind="siteAvailable" placeholder="Select" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-15">
                            <div class="form-group">
                                <label> Filter By tag (Template 6):
                                    <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                            <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                width="427px"
                                                height="427px"
                                                viewBox="0 0 427 427"
                                                enable-background="new 0 0 427 427"
                                                xml:space="preserve"
                                            >
                                                <g>
                                                    <polyline
                                                        fill="none"
                                                        stroke="#E89C9D"
                                                        stroke-width="15"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-miterlimit="10"
                                                        points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                    />
                                                    <circle cx="208" cy="129.5" r="15.5" />
                                                    <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                </g>
                                            </svg>
                                    </span>
                                </label>
                                <div class="check-box"><input type="checkbox" /></div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-15">
                            <div class="form-group">
                                <label>Sort By (Template 6):</label>
                                <div class="check-box"><input type="checkbox" /></div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-15">
                            <div class="form-group">
                                <label>Default Sort By Availibility (Template 6):</label>
                                <div class="check-box"><input type="checkbox" /></div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-15">
                            <div class="form-group">
                                <label>List View Selection: 
                                    <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                            <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                width="427px"
                                                height="427px"
                                                viewBox="0 0 427 427"
                                                enable-background="new 0 0 427 427"
                                                xml:space="preserve"
                                            >
                                                <g>
                                                    <polyline
                                                        fill="none"
                                                        stroke="#E89C9D"
                                                        stroke-width="15"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-miterlimit="10"
                                                        points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                    />
                                                    <circle cx="208" cy="129.5" r="15.5" />
                                                    <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                </g>
                                            </svg>
                                    </span>
                                </label>
                                <div class="check-box"><input type="checkbox" /></div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-15">
                            <div class="form-group">
                                <label>Hamburger Filter:
                                    <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                            <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                width="427px"
                                                height="427px"
                                                viewBox="0 0 427 427"
                                                enable-background="new 0 0 427 427"
                                                xml:space="preserve"
                                            >
                                                <g>
                                                    <polyline
                                                        fill="none"
                                                        stroke="#E89C9D"
                                                        stroke-width="15"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-miterlimit="10"
                                                        points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                    />
                                                    <circle cx="208" cy="129.5" r="15.5" />
                                                    <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                </g>
                                            </svg>
                                    </span>
                                </label>
                                <div class="check-box"><input type="checkbox" /></div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-15">
                            <div class="form-group">
                                <label>List View Selection (Tiles / Deluxe):
                                    <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                        <svg
                                            version="1.1"
                                            id="Layer_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            width="427px"
                                            height="427px"
                                            viewBox="0 0 427 427"
                                            enable-background="new 0 0 427 427"
                                            xml:space="preserve"
                                        >
                                            <g>
                                                <polyline
                                                    fill="none"
                                                    stroke="#E89C9D"
                                                    stroke-width="15"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-miterlimit="10"
                                                    points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                />
                                                <circle cx="208" cy="129.5" r="15.5" />
                                                <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                            </g>
                                        </svg>
                                    </span>
                                </label>
                                <div class="check-box"><input type="checkbox" /></div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-15">
                            <div class="form-group">
                                <label>Site View Selection (Tiles / Deluxe):
                                    <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                        <svg
                                            version="1.1"
                                            id="Layer_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            width="427px"
                                            height="427px"
                                            viewBox="0 0 427 427"
                                            enable-background="new 0 0 427 427"
                                            xml:space="preserve"
                                        >
                                            <g>
                                                <polyline
                                                    fill="none"
                                                    stroke="#E89C9D"
                                                    stroke-width="15"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-miterlimit="10"
                                                    points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                />
                                                <circle cx="208" cy="129.5" r="15.5" />
                                                <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                            </g>
                                        </svg>
                                    </span>
                                </label>
                                <div class="check-box"><input type="checkbox" /></div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label> Choose Default view (Template 6):</label>
                                <div class="custom_radio">
                                    <input type="radio" name="size" id="size_4" value="small" checked />
                                    <label for="size_4">Desktop</label>

                                    <input type="radio" name="size" id="size_5" value="small" />
                                    <label for="size_5">Tablet</label>

                                    <input type="radio" name="size" id="size_6" value="small" />
                                    <label for="size_6">Mobile</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label> Default View for Desktop:</label>
                                <div class="custom_radio">
                                    <input type="radio" name="size" id="size_7" value="small" checked />
                                    <label for="size_7">Site view</label>

                                    <input type="radio" name="size" id="size_8" value="small" />
                                    <label for="size_8">Floor view</label>

                                    <input type="radio" name="size" id="size_9" value="small" />
                                    <label for="size_9">List view</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
        
<script>
    import LeftPreviewPanel from "@/views/LeftPreviewPanel.vue";
    import Multiselect from '@vueform/multiselect'

    export default {
        components: {
            LeftPreviewPanel,
            Multiselect,
        },
        data() {
            return {
                //Tabs
                tab: 'tab1',

                //Site Available Page URL
                siteAvailable: {
                mode: 'single',
                value: 'Select',
                options: [
                    { value: 'Select', label: 'Select' }
                ],
                searchable: false,
                createTag: false
            },
            };
        },
    };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>