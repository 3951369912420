<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="general_configuration">
                    <div class="general_configuration_details">
                        <div class="top_pannel">
                            <div class="heading">
                                <h3>API Credential</h3>
                            </div>
                        </div>
                        <div class="api_top_panel">
                            <div class="select_fld">
                                <Multiselect v-model="api_type.value" v-bind="api_type" placeholder="Select One" />
                            </div>
                            <div class="select_fld" v-if="api_type.value == 'realpage'">
                                <Multiselect v-model="stream_type.value" v-bind="stream_type" placeholder="Select One" />
                            </div>
                            <div class="check-box">
                                <label>Multiple Site ID</label>
                                <input type="checkbox" v-model="multi_id_type" :true-value="1" :false-value="0">
                            </div>
                        </div>
                        <div class="row" v-if="api_type.value == 'realpage'">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-body">
                                        <div class="Page_content">
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-5" v-if="stream_type.value == 'onesite'">
                                                    <label class="d-flex flex-wrap align-items-center">
                                                        API Username:
                                                        <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                            <svg
                                                                version="1.1"
                                                                id="Layer_1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                x="0px"
                                                                y="0px"
                                                                width="427px"
                                                                height="427px"
                                                                viewBox="0 0 427 427"
                                                                enable-background="new 0 0 427 427"
                                                                xml:space="preserve"
                                                            >
                                                                <g>
                                                                    <polyline
                                                                        fill="none"
                                                                        stroke="#E89C9D"
                                                                        stroke-width="15"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        stroke-miterlimit="10"
                                                                        points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                    />
                                                                    <circle cx="208" cy="129.5" r="15.5" />
                                                                    <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <span class="small text-danger ml-auto">Required *</span>
                                                    </label>
                                                    <input type="text" placeholder="Repli" />
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-5" v-if="stream_type.value == 'onesite'">
                                                    <label class="d-flex flex-wrap align-items-center">
                                                        API Password:
                                                        <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                            <svg
                                                                version="1.1"
                                                                id="Layer_1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                x="0px"
                                                                y="0px"
                                                                width="427px"
                                                                height="427px"
                                                                viewBox="0 0 427 427"
                                                                enable-background="new 0 0 427 427"
                                                                xml:space="preserve"
                                                            >
                                                                <g>
                                                                    <polyline
                                                                        fill="none"
                                                                        stroke="#E89C9D"
                                                                        stroke-width="15"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        stroke-miterlimit="10"
                                                                        points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                    />
                                                                    <circle cx="208" cy="129.5" r="15.5" />
                                                                    <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <span class="small text-danger ml-auto">Required *</span>
                                                    </label>
                                                    <input type="password" placeholder="*****" />
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-5">
                                                    <label class="d-flex flex-wrap align-items-center">
                                                        API PmcID:
                                                        <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                            <svg
                                                                version="1.1"
                                                                id="Layer_1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                x="0px"
                                                                y="0px"
                                                                width="427px"
                                                                height="427px"
                                                                viewBox="0 0 427 427"
                                                                enable-background="new 0 0 427 427"
                                                                xml:space="preserve"
                                                            >
                                                                <g>
                                                                    <polyline
                                                                        fill="none"
                                                                        stroke="#E89C9D"
                                                                        stroke-width="15"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        stroke-miterlimit="10"
                                                                        points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                    />
                                                                    <circle cx="208" cy="129.5" r="15.5" />
                                                                    <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <span class="small text-danger ml-auto">Required *</span>
                                                    </label>
                                                    <input type="text" placeholder="123456789" />
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-5" v-if="stream_type.value == 'rpx'">
                                                    <label class="d-flex flex-wrap align-items-center">
                                                        RPX API Token:
                                                        <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                            <svg
                                                                version="1.1"
                                                                id="Layer_1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                x="0px"
                                                                y="0px"
                                                                width="427px"
                                                                height="427px"
                                                                viewBox="0 0 427 427"
                                                                enable-background="new 0 0 427 427"
                                                                xml:space="preserve"
                                                            >
                                                                <g>
                                                                    <polyline
                                                                        fill="none"
                                                                        stroke="#E89C9D"
                                                                        stroke-width="15"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        stroke-miterlimit="10"
                                                                        points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                    />
                                                                    <circle cx="208" cy="129.5" r="15.5" />
                                                                    <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <span class="small text-danger ml-auto">Required *</span>
                                                    </label>
                                                    <input type="text" placeholder="7EC17120-8C73" />
                                                </div>
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                                            <div class="unit_show_group">
                                                                <label>
                                                                    Available Unit Show By(Optional):
                                                                    <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                        <svg
                                                                            version="1.1"
                                                                            id="Layer_1"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                            x="0px"
                                                                            y="0px"
                                                                            width="427px"
                                                                            height="427px"
                                                                            viewBox="0 0 427 427"
                                                                            enable-background="new 0 0 427 427"
                                                                            xml:space="preserve"
                                                                        >
                                                                            <g>
                                                                                <polyline
                                                                                    fill="none"
                                                                                    stroke="#E89C9D"
                                                                                    stroke-width="15"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-miterlimit="10"
                                                                                    points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                                />
                                                                                <circle cx="208" cy="129.5" r="15.5" />
                                                                                <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                </label>
                                                                <div class="custom_check-box"><input type="checkbox" id="available_bit" v-model="available_bit" :true-value="1" :false-value="0"/><label for="available_bit">Value of Available Bit</label></div>
                                                                <div class="radio-box" v-if="available_bit">
                                                                    <ul>
                                                                        <li>
                                                                            <input type="radio" id="test1" name="radio-group">
                                                                            <label for="test1">True</label>
                                                                        </li>
                                                                        <li>
                                                                            <input type="radio" id="test2" name="radio-group">
                                                                            <label for="test2">False</label>
                                                                        </li>
                                                                    </ul>
                                                                </div>                                                                            
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                                            <div class="unit_show_group">
                                                                <label>
                                                                    Available Unit Show By(Optional):
                                                                    <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                        <svg
                                                                            version="1.1"
                                                                            id="Layer_1"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                            x="0px"
                                                                            y="0px"
                                                                            width="427px"
                                                                            height="427px"
                                                                            viewBox="0 0 427 427"
                                                                            enable-background="new 0 0 427 427"
                                                                            xml:space="preserve"
                                                                        >
                                                                            <g>
                                                                                <polyline
                                                                                    fill="none"
                                                                                    stroke="#E89C9D"
                                                                                    stroke-width="15"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-miterlimit="10"
                                                                                    points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                                />
                                                                                <circle cx="208" cy="129.5" r="15.5" />
                                                                                <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                </label>
                                                                <div class="custom_check-box"><input type="checkbox" id="vacant_bit" v-model="vacant_bit" :true-value="1" :false-value="0"/><label for="vacant_bit">Value of Vacant Bit</label></div>
                                                                <div class="radio-box" v-if="vacant_bit">
                                                                    <ul>
                                                                        <li>
                                                                            <input type="radio" id="f-option" name="selector">
                                                                            <label for="f-option">True</label>
                                                                            <div class="check"></div>
                                                                        </li>
                                                                        <li>
                                                                            <input type="radio" id="s-option" name="selector">
                                                                            <label for="s-option">False</label>
                                                                            <div class="check"><div class="inside"></div></div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="api_type.value == 'rentcafe' && !multi_id_type">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-body">
                                        <div class="Page_content">
                                            <div class="row">
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label class="d-flex flex-wrap align-items-center">
                                                            RentCafe API Token:
                                                            <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                <svg
                                                                    version="1.1"
                                                                    id="Layer_1"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    x="0px"
                                                                    y="0px"
                                                                    width="427px"
                                                                    height="427px"
                                                                    viewBox="0 0 427 427"
                                                                    enable-background="new 0 0 427 427"
                                                                    xml:space="preserve"
                                                                >
                                                                    <g>
                                                                        <polyline
                                                                            fill="none"
                                                                            stroke="#E89C9D"
                                                                            stroke-width="15"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                        />
                                                                        <circle cx="208" cy="129.5" r="15.5" />
                                                                        <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span class="small text-danger ml-auto">Required *</span>
                                                        </label>
                                                        <input type="text" placeholder="45ad556ac92f496fa497" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label class="d-flex flex-wrap align-items-center">
                                                            RentCafe Property Code:
                                                            <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                <svg
                                                                    version="1.1"
                                                                    id="Layer_1"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    x="0px"
                                                                    y="0px"
                                                                    width="427px"
                                                                    height="427px"
                                                                    viewBox="0 0 427 427"
                                                                    enable-background="new 0 0 427 427"
                                                                    xml:space="preserve"
                                                                >
                                                                    <g>
                                                                        <polyline
                                                                            fill="none"
                                                                            stroke="#E89C9D"
                                                                            stroke-width="15"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                        />
                                                                        <circle cx="208" cy="129.5" r="15.5" />
                                                                        <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span class="small text-danger ml-auto">Required *</span>
                                                        </label>
                                                        <input type="text" placeholder="p12356" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label class="d-flex flex-wrap align-items-center">
                                                            RentCafe Marketing Api Key:
                                                            <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                <svg
                                                                    version="1.1"
                                                                    id="Layer_1"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    x="0px"
                                                                    y="0px"
                                                                    width="427px"
                                                                    height="427px"
                                                                    viewBox="0 0 427 427"
                                                                    enable-background="new 0 0 427 427"
                                                                    xml:space="preserve"
                                                                >
                                                                    <g>
                                                                        <polyline
                                                                            fill="none"
                                                                            stroke="#E89C9D"
                                                                            stroke-width="15"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                        />
                                                                        <circle cx="208" cy="129.5" r="15.5" />
                                                                        <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span class="small text-danger ml-auto">Required *</span>
                                                        </label>
                                                        <input type="text" placeholder="2a7d5f43-e942-43ac" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label class="d-flex flex-wrap align-items-center">
                                                            RentCafe Company Code:
                                                            <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                <svg
                                                                    version="1.1"
                                                                    id="Layer_1"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    x="0px"
                                                                    y="0px"
                                                                    width="427px"
                                                                    height="427px"
                                                                    viewBox="0 0 427 427"
                                                                    enable-background="new 0 0 427 427"
                                                                    xml:space="preserve"
                                                                >
                                                                    <g>
                                                                        <polyline
                                                                            fill="none"
                                                                            stroke="#E89C9D"
                                                                            stroke-width="15"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                        />
                                                                        <circle cx="208" cy="129.5" r="15.5" />
                                                                        <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span class="small text-danger ml-auto">Required *</span>
                                                        </label>
                                                        <input type="text" placeholder="d78901" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label class="d-flex flex-wrap align-items-center">
                                                            RentCafe Property ID:
                                                            <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                <svg
                                                                    version="1.1"
                                                                    id="Layer_1"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    x="0px"
                                                                    y="0px"
                                                                    width="427px"
                                                                    height="427px"
                                                                    viewBox="0 0 427 427"
                                                                    enable-background="new 0 0 427 427"
                                                                    xml:space="preserve"
                                                                >
                                                                    <g>
                                                                        <polyline
                                                                            fill="none"
                                                                            stroke="#E89C9D"
                                                                            stroke-width="15"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                        />
                                                                        <circle cx="208" cy="129.5" r="15.5" />
                                                                        <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span class="small text-danger ml-auto">Required *</span>
                                                        </label>
                                                        <input type="text" placeholder="1234587" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label>Price:</label>
                                                        <div class="custom_radio">
                                                            <input type="radio" name="price_api" id="availibility_api" v-model="price_api" value="availibility"/>
                                                            <label for="availibility_api">Availability API</label>
                                                            <input type="radio" name="price_api" id="pricematix_api" v-model="price_api" value="pricematix"/>
                                                            <label for="pricematix_api">PriceMatix API</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-for="(rentcafe, r) in multi_rent_cafe" :key="r" v-if="api_type.value == 'rentcafe' && multi_id_type">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <span class="remove_property" @click="removeProperty(r)" v-if="r !== 0">Remove</span>
                                    <div class="card-body">
                                        <div class="Page_content">
                                            <div class="row">
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label class="d-flex flex-wrap align-items-center">
                                                            <span class="sl_no">{{ r + 1 }}</span>RentCafe API Token:
                                                            <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                <svg
                                                                    version="1.1"
                                                                    id="Layer_1"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    x="0px"
                                                                    y="0px"
                                                                    width="427px"
                                                                    height="427px"
                                                                    viewBox="0 0 427 427"
                                                                    enable-background="new 0 0 427 427"
                                                                    xml:space="preserve"
                                                                >
                                                                    <g>
                                                                        <polyline
                                                                            fill="none"
                                                                            stroke="#E89C9D"
                                                                            stroke-width="15"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                        />
                                                                        <circle cx="208" cy="129.5" r="15.5" />
                                                                        <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span class="small text-danger ml-auto">Required *</span>
                                                        </label>
                                                        <input type="text" placeholder="45ad556ac92f496fa497" v-model="rentcafe.api_token" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label class="d-flex flex-wrap align-items-center">
                                                            <span class="sl_no">{{ r + 1 }}</span>RentCafe Property Code:
                                                            <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                <svg
                                                                    version="1.1"
                                                                    id="Layer_1"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    x="0px"
                                                                    y="0px"
                                                                    width="427px"
                                                                    height="427px"
                                                                    viewBox="0 0 427 427"
                                                                    enable-background="new 0 0 427 427"
                                                                    xml:space="preserve"
                                                                >
                                                                    <g>
                                                                        <polyline
                                                                            fill="none"
                                                                            stroke="#E89C9D"
                                                                            stroke-width="15"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                        />
                                                                        <circle cx="208" cy="129.5" r="15.5" />
                                                                        <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span class="small text-danger ml-auto">Required *</span>
                                                        </label>
                                                        <input type="text" placeholder="p12356" v-model="rentcafe.property_code"/>
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label class="d-flex flex-wrap align-items-center">
                                                            <span class="sl_no">{{ r + 1 }}</span>RentCafe Marketing Api Key:
                                                            <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                <svg
                                                                    version="1.1"
                                                                    id="Layer_1"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    x="0px"
                                                                    y="0px"
                                                                    width="427px"
                                                                    height="427px"
                                                                    viewBox="0 0 427 427"
                                                                    enable-background="new 0 0 427 427"
                                                                    xml:space="preserve"
                                                                >
                                                                    <g>
                                                                        <polyline
                                                                            fill="none"
                                                                            stroke="#E89C9D"
                                                                            stroke-width="15"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                        />
                                                                        <circle cx="208" cy="129.5" r="15.5" />
                                                                        <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span class="small text-danger ml-auto">Required *</span>
                                                        </label>
                                                        <input type="text" placeholder="2a7d5f43-e942-43ac" v-model="rentcafe.api_key"/>
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label class="d-flex flex-wrap align-items-center">
                                                            <span class="sl_no">{{ r + 1 }}</span>RentCafe Company Code:
                                                            <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                <svg
                                                                    version="1.1"
                                                                    id="Layer_1"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    x="0px"
                                                                    y="0px"
                                                                    width="427px"
                                                                    height="427px"
                                                                    viewBox="0 0 427 427"
                                                                    enable-background="new 0 0 427 427"
                                                                    xml:space="preserve"
                                                                >
                                                                    <g>
                                                                        <polyline
                                                                            fill="none"
                                                                            stroke="#E89C9D"
                                                                            stroke-width="15"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                        />
                                                                        <circle cx="208" cy="129.5" r="15.5" />
                                                                        <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span class="small text-danger ml-auto">Required *</span>
                                                        </label>
                                                        <input type="text" placeholder="d78901" v-model="rentcafe.company_code"/>
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label class="d-flex flex-wrap align-items-center">
                                                            <span class="sl_no">{{ r + 1 }}</span>RentCafe Property ID:
                                                            <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                <svg
                                                                    version="1.1"
                                                                    id="Layer_1"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    x="0px"
                                                                    y="0px"
                                                                    width="427px"
                                                                    height="427px"
                                                                    viewBox="0 0 427 427"
                                                                    enable-background="new 0 0 427 427"
                                                                    xml:space="preserve"
                                                                >
                                                                    <g>
                                                                        <polyline
                                                                            fill="none"
                                                                            stroke="#E89C9D"
                                                                            stroke-width="15"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                        />
                                                                        <circle cx="208" cy="129.5" r="15.5" />
                                                                        <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span class="small text-danger ml-auto">Required *</span>
                                                        </label>
                                                        <input type="text" placeholder="1234587" v-model="rentcafe.property_id"/>
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label class="d-flex flex-wrap align-items-center"><span class="sl_no">{{ r + 1 }}</span>Price:</label>
                                                        <div class="custom_radio">
                                                            <input type="radio" name="price_api" :id="`availibility_api_${r}`" v-model="rentcafe.price_api" value="availibility"/>
                                                            <label :for="`availibility_api_${r}`">Availability API</label>
                                                            <input type="radio" name="price_api" :id="`pricematix_api_${r}`" v-model="rentcafe.price_api" value="pricematix"/>
                                                            <label :for="`pricematix_api_${r}`">PriceMatix API</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-start mb-5" v-if="api_type.value == 'rentcafe' && multi_id_type">
                            <button type="button" class="default_btn" @click="addNewProperty">+ Add New Property ID</button>
                        </div>
                        <div class="row" v-if="api_type.value == 'entrata'">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-body">
                                        <div class="Page_content">
                                            <div class="row">
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label class="d-flex flex-wrap align-items-center">
                                                            Entrata API URL:
                                                            <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                <svg
                                                                    version="1.1"
                                                                    id="Layer_1"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    x="0px"
                                                                    y="0px"
                                                                    width="427px"
                                                                    height="427px"
                                                                    viewBox="0 0 427 427"
                                                                    enable-background="new 0 0 427 427"
                                                                    xml:space="preserve"
                                                                >
                                                                    <g>
                                                                        <polyline
                                                                            fill="none"
                                                                            stroke="#E89C9D"
                                                                            stroke-width="15"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                        />
                                                                        <circle cx="208" cy="129.5" r="15.5" />
                                                                        <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span class="small text-danger ml-auto">Required *</span>
                                                        </label>
                                                        <input type="text" placeholder="45ad556ac92f496fa497" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label class="d-flex flex-wrap align-items-center">
                                                            Entrata API Username:
                                                            <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                <svg
                                                                    version="1.1"
                                                                    id="Layer_1"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    x="0px"
                                                                    y="0px"
                                                                    width="427px"
                                                                    height="427px"
                                                                    viewBox="0 0 427 427"
                                                                    enable-background="new 0 0 427 427"
                                                                    xml:space="preserve"
                                                                >
                                                                    <g>
                                                                        <polyline
                                                                            fill="none"
                                                                            stroke="#E89C9D"
                                                                            stroke-width="15"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                        />
                                                                        <circle cx="208" cy="129.5" r="15.5" />
                                                                        <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span class="small text-danger ml-auto">Required *</span>
                                                        </label>
                                                        <input type="text" placeholder="p12356" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label class="d-flex flex-wrap align-items-center">
                                                            Entrata API Password:
                                                            <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                <svg
                                                                    version="1.1"
                                                                    id="Layer_1"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    x="0px"
                                                                    y="0px"
                                                                    width="427px"
                                                                    height="427px"
                                                                    viewBox="0 0 427 427"
                                                                    enable-background="new 0 0 427 427"
                                                                    xml:space="preserve"
                                                                >
                                                                    <g>
                                                                        <polyline
                                                                            fill="none"
                                                                            stroke="#E89C9D"
                                                                            stroke-width="15"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                        />
                                                                        <circle cx="208" cy="129.5" r="15.5" />
                                                                        <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span class="small text-danger ml-auto">Required *</span>
                                                        </label>
                                                        <input type="text" placeholder="2a7d5f43-e942-43ac" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label class="d-flex flex-wrap align-items-center">
                                                            Entrata Leadsource ID:
                                                            <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                <svg
                                                                    version="1.1"
                                                                    id="Layer_1"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    x="0px"
                                                                    y="0px"
                                                                    width="427px"
                                                                    height="427px"
                                                                    viewBox="0 0 427 427"
                                                                    enable-background="new 0 0 427 427"
                                                                    xml:space="preserve"
                                                                >
                                                                    <g>
                                                                        <polyline
                                                                            fill="none"
                                                                            stroke="#E89C9D"
                                                                            stroke-width="15"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                        />
                                                                        <circle cx="208" cy="129.5" r="15.5" />
                                                                        <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span class="small text-danger ml-auto">Required *</span>
                                                        </label>
                                                        <input type="text" placeholder="d78901" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label class="d-flex flex-wrap align-items-center">
                                                            Entrata Request ID:
                                                            <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                <svg
                                                                    version="1.1"
                                                                    id="Layer_1"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    x="0px"
                                                                    y="0px"
                                                                    width="427px"
                                                                    height="427px"
                                                                    viewBox="0 0 427 427"
                                                                    enable-background="new 0 0 427 427"
                                                                    xml:space="preserve"
                                                                >
                                                                    <g>
                                                                        <polyline
                                                                            fill="none"
                                                                            stroke="#E89C9D"
                                                                            stroke-width="15"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                        />
                                                                        <circle cx="208" cy="129.5" r="15.5" />
                                                                        <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span class="small text-danger ml-auto">Required *</span>
                                                        </label>
                                                        <input type="text" placeholder="1234587" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label class="d-flex flex-wrap align-items-center">
                                                            Entrata Available Url:
                                                            <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                <svg
                                                                    version="1.1"
                                                                    id="Layer_1"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    x="0px"
                                                                    y="0px"
                                                                    width="427px"
                                                                    height="427px"
                                                                    viewBox="0 0 427 427"
                                                                    enable-background="new 0 0 427 427"
                                                                    xml:space="preserve"
                                                                >
                                                                    <g>
                                                                        <polyline
                                                                            fill="none"
                                                                            stroke="#E89C9D"
                                                                            stroke-width="15"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                        />
                                                                        <circle cx="208" cy="129.5" r="15.5" />
                                                                        <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span class="small text-danger ml-auto">Required *</span>
                                                        </label>
                                                        <input type="text" placeholder="1234587" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12" v-if="!multi_id_type">
                                                    <div class="form-group">
                                                        <label class="d-flex flex-wrap align-items-center">
                                                            Entrata Property ID:
                                                            <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                <svg
                                                                    version="1.1"
                                                                    id="Layer_1"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    x="0px"
                                                                    y="0px"
                                                                    width="427px"
                                                                    height="427px"
                                                                    viewBox="0 0 427 427"
                                                                    enable-background="new 0 0 427 427"
                                                                    xml:space="preserve"
                                                                >
                                                                    <g>
                                                                        <polyline
                                                                            fill="none"
                                                                            stroke="#E89C9D"
                                                                            stroke-width="15"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                        />
                                                                        <circle cx="208" cy="129.5" r="15.5" />
                                                                        <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span class="small text-danger ml-auto">Required *</span>
                                                        </label>
                                                        <input type="text" placeholder="1234587" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12" v-else v-for="(property, p) in multi_entrata" :key="p">
                                                    <div class="form-group">
                                                        <label class="d-flex flex-wrap align-items-center">
                                                            <span class="sl_no">{{ p + 1 }}</span>Entrata Property ID:
                                                            <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                <svg
                                                                    version="1.1"
                                                                    id="Layer_1"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                    x="0px"
                                                                    y="0px"
                                                                    width="427px"
                                                                    height="427px"
                                                                    viewBox="0 0 427 427"
                                                                    enable-background="new 0 0 427 427"
                                                                    xml:space="preserve"
                                                                >
                                                                    <g>
                                                                        <polyline
                                                                            fill="none"
                                                                            stroke="#E89C9D"
                                                                            stroke-width="15"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                            stroke-miterlimit="10"
                                                                            points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                        />
                                                                        <circle cx="208" cy="129.5" r="15.5" />
                                                                        <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span class="small text-danger ml-auto" v-if="p == 0">Required *</span>
                                                            <span class="remove text-danger ml-auto" v-else @click="removeEntrataId(p)">Remove</span>
                                                        </label>
                                                        <input type="text" placeholder="1234587" v-model="property.property_id"/>
                                                    </div>
                                                </div>
                                                <div class="col-12 d-flex justify-content-start mb-3" v-if="multi_id_type">
                                                    <button type="button" class="default_btn" @click="addNewEntrataId">+ Add New Property ID</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="api_type.value == 'realpage'">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-body">
                                        <div class="Page_content">
                                            <div class="row">
                                                <div class="col-12" v-if="!multi_id_type">
                                                    <div class="row">
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                            <div class="form-group">
                                                                <label class="d-flex flex-wrap align-items-center">
                                                                    API SiteID:
                                                                    <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                        <svg
                                                                            version="1.1"
                                                                            id="Layer_1"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                            x="0px"
                                                                            y="0px"
                                                                            width="427px"
                                                                            height="427px"
                                                                            viewBox="0 0 427 427"
                                                                            enable-background="new 0 0 427 427"
                                                                            xml:space="preserve"
                                                                        >
                                                                            <g>
                                                                                <polyline
                                                                                    fill="none"
                                                                                    stroke="#E89C9D"
                                                                                    stroke-width="15"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-miterlimit="10"
                                                                                    points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                                />
                                                                                <circle cx="208" cy="129.5" r="15.5" />
                                                                                <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                    <span class="small text-danger ml-auto">Required *</span>
                                                                </label>
                                                                <input type="text" placeholder="Repli" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12" v-else>
                                                    <div class="row" v-for="(site, s) in multi_api_site_id" :key="s">
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                            <div class="form-group">
                                                                <label class="d-flex flex-wrap align-items-center">
                                                                    <span class="sl_no">{{ s + 1 }}</span>API SiteID :
                                                                    <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                        <svg
                                                                            version="1.1"
                                                                            id="Layer_1"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                            x="0px"
                                                                            y="0px"
                                                                            width="427px"
                                                                            height="427px"
                                                                            viewBox="0 0 427 427"
                                                                            enable-background="new 0 0 427 427"
                                                                            xml:space="preserve"
                                                                        >
                                                                            <g>
                                                                                <polyline
                                                                                    fill="none"
                                                                                    stroke="#E89C9D"
                                                                                    stroke-width="15"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-miterlimit="10"
                                                                                    points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                                />
                                                                                <circle cx="208" cy="129.5" r="15.5" />
                                                                                <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                    <span class="small text-danger ml-auto">Required *</span>
                                                                </label>
                                                                <input type="text" placeholder="Repli" v-model="site.site_id"/>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                            <div class="form-group">
                                                                <label class="d-flex flex-wrap align-items-center">
                                                                    <span class="sl_no">{{ s + 1 }}</span>API Site Available Url:
                                                                    <span class="remove text-danger ml-auto" @click="removeSite(s)" v-if="s !== 0">Remove</span>
                                                                    <!-- <span class="remove"><svg fill="#000000" width="800px" height="800px" viewBox="-2.94 0 31.716 31.716" xmlns="http://www.w3.org/2000/svg"><g transform="translate(-355.957 -579)"><path d="M376.515,610.716H361.231a2.361,2.361,0,0,1-2.358-2.359V584.1a1,1,0,0,1,2,0v24.255a.36.36,0,0,0,.358.359h15.284a.36.36,0,0,0,.358-.359V584.1a1,1,0,0,1,2,0v24.255A2.361,2.361,0,0,1,376.515,610.716Z"/><path d="M365.457,604.917a1,1,0,0,1-1-1v-14a1,1,0,0,1,2,0v14A1,1,0,0,1,365.457,604.917Z"/><path d="M372.29,604.917a1,1,0,0,1-1-1v-14a1,1,0,0,1,2,0v14A1,1,0,0,1,372.29,604.917Z"/><path d="M380.79,585.1H356.957a1,1,0,0,1,0-2H380.79a1,1,0,0,1,0,2Z"/><path d="M372.79,581h-7.917a1,1,0,1,1,0-2h7.917a1,1,0,0,1,0,2Z"/></g></svg></span> -->
                                                                </label>
                                                                <div class="select_fld">
                                                                    <input type="text" v-model="site.site_url"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-start" v-if="multi_id_type">
                                                <button type="button" class="default_btn" @click="addNewSite">+ Add New Site ID</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-body">
                                        <div class="Page_content">
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label>Would you like to split related floor plans?</label>
                                                        <div class="custom_radio">
                                                            <input type="radio" name="is_split_floor_plans" id="split_flor_plan" v-model="is_split_floor_plans" :value="1"/>
                                                            <label for="split_flor_plan">Yes</label>
                                                            <input type="radio" name="is_split_floor_plans" id="no_split_flor_plan" v-model="is_split_floor_plans" :value="0"/>
                                                            <label for="no_split_flor_plan">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12" v-if="!is_split_floor_plans">
                                                    <div class="form-group">
                                                        <label>Related floor Plan Configuration (Not Depend Marketing Name):</label>
                                                        <div class="custom_radio">
                                                            <input type="radio" name="is_plan_configuration" id="plan_configuration" v-model="is_plan_configuration" :value="1"/>
                                                            <label for="plan_configuration">Yes</label>
                                                            <input type="radio" name="is_plan_configuration" id="no_plan_configuration" v-model="is_plan_configuration" :value="0"/>
                                                            <label for="no_plan_configuration">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label>Floorplan Group By:</label>
                                                        <div class="select_fld">
                                                            <Multiselect v-model="FloorPlan.value" v-bind="FloorPlan" placeholder="Custom" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-body">
                                        <div class="Page_content">
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label>Lease Term Type:</label>
                                                        <div class="custom_radio">
                                                            <input type="radio" name="term_type" id="fixed_term" v-model="term_type" :value="1"/>
                                                            <label for="fixed_term">Fixed</label>
                                                            <input type="radio" name="term_type" id="range_term" v-model="term_type" :value="2"/>
                                                            <label for="range_term">Range</label>
                                                            <input type="radio" name="term_type" id="selected_term" v-model="term_type" :value="3"/>
                                                            <label for="selected_term">Selected</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12" v-if="term_type == 1">
                                                    <div class="form-group">
                                                        <label>Fixed Lease Term :</label>
                                                        <div class="select_fld">
                                                            <Multiselect v-model="LeaseTerm.value" v-bind="LeaseTerm" placeholder="Lease Term - 12 (LeaseTermMin - 09 | LeaseTermMax - 15)" />
                                                        </div>  
                                                    </div>                                     
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12" v-if="term_type == 2">
                                                    <div class="row">
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                            <div class="form-group">
                                                                <label>Range Lease Term Start: :</label>
                                                                <div class="select_fld">
                                                                    <Multiselect v-model="range_list_term_start.value" v-bind="range_list_term_start" placeholder="Lease Term - 12 (LeaseTermMin - 09 | LeaseTermMax - 15)" />
                                                                </div>  
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                            <div class="form-group">
                                                                <label>Range Lease Term End: :</label>
                                                                <div class="select_fld">
                                                                    <Multiselect v-model="range_list_term_end.value" v-bind="range_list_term_end" placeholder="Lease Term - 12 (LeaseTermMin - 09 | LeaseTermMax - 15)" />
                                                                </div>  
                                                            </div>
                                                        </div>
                                                    </div>                                
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12" v-if="term_type == 3">
                                                    <div class="form-group">
                                                        <label>Selected Lease Term:(enter lease term value with ',' seperator)</label>
                                                        <div class="select_fld">
                                                            <input type="text" placeholder="Enter Lease Term Like : 9,11,12,14">
                                                        </div>  
                                                    </div>                                     
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-body">
                                        <div class="Page_content">
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label>Lease Term Template choice (Device Wise) :</label>
                                                        <div class="custom_radio">
                                                            <input type="radio" name="device_choice" id="all_device" v-model="device_choice" value="all"/>
                                                            <label for="all_device">All Device</label>
                                                            <input type="radio" name="device_choice" id="custom_device" v-model="device_choice" value="custom"/>
                                                            <label for="custom_device">Custom Device</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12" v-if="device_choice == 'custom'">
                                                    <div class="form-group">
                                                        <label>Lease Term Template choice (Device Wise) :</label>
                                                        <div class="custom_radio">
                                                            <input type="radio" name="custom_device" id="desktop" v-model="custom_device" value="desktop"/>
                                                            <label for="desktop">Desktop</label>
                                                            <input type="radio" name="custom_device" id="tablet" v-model="custom_device" value="tablet"/>
                                                            <label for="tablet">Tablet</label>
                                                            <input type="radio" name="custom_device" id="mobile" v-model="custom_device" value="mobile"/>
                                                            <label for="mobile">Mobile</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                                    <div class="form-group" v-if="custom_device == 'desktop'">
                                                        <label>Template choice (Lease Term - Desktop) :</label>
                                                        <div class="custom_radio">
                                                            <input type="radio" name="desktop_spec" id="desk_tooltip" v-model="desktop_spec" value="tooltip"/>
                                                            <label for="desk_tooltip">Tooltip</label>
                                                            <input type="radio" name="desktop_spec" id="desk_popup" v-model="desktop_spec" value="popup"/>
                                                            <label for="desk_popup">Popup</label>
                                                            <input type="radio" name="desktop_spec" id="desk_dropdown" v-model="desktop_spec" value="dropdown"/>
                                                            <label for="desk_dropdown">Dropdown</label>
                                                        </div>
                                                    </div>
                                                    <div class="form-group" v-if="custom_device == 'tablet'">
                                                        <label>Template choice (Lease Term - Tablet) :</label>
                                                        <div class="custom_radio">
                                                            <input type="radio" name="tablet_spec" id="tab_tooltip" v-model="tablet_spec" value="tooltip"/>
                                                            <label for="tab_tooltip">Tooltip</label>
                                                            <input type="radio" name="tablet_spec" id="tab_popup" v-model="tablet_spec" value="popup"/>
                                                            <label for="tab_popup">Popup</label>
                                                            <input type="radio" name="tablet_spec" id="tab_dropdown" v-model="tablet_spec" value="dropdown"/>
                                                            <label for="tab_dropdown">Dropdown</label>
                                                        </div>
                                                    </div>
                                                    <div class="form-group" v-if="custom_device == 'mobile'">
                                                        <label>Template choice (Lease Term - Tablet) :</label>
                                                        <div class="custom_radio">
                                                            <input type="radio" name="mobile_spec" id="mob_tooltip" v-model="mobile_spec" value="tooltip"/>
                                                            <label for="mob_tooltip">Tooltip</label>
                                                            <input type="radio" name="mobile_spec" id="mob_popup" v-model="mobile_spec" value="popup"/>
                                                            <label for="mob_popup">Popup</label>
                                                            <input type="radio" name="mobile_spec" id="mob_dropdown" v-model="mobile_spec" value="dropdown"/>
                                                            <label for="mob_dropdown">Dropdown</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-body">
                                        <div class="Page_content">
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label>Lease Term Type:</label>
                                                        <div class="custom_radio">
                                                            <input type="radio" name="lease_term_type" id="static" v-model="lease_term_type" value="static"/>
                                                            <label for="static">Static</label>
                                                            <input type="radio" name="lease_term_type" id="dynamic" v-model="lease_term_type" value="dynamic"/>
                                                            <label for="dynamic">Dynamic</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12" v-if="lease_term_type == 'static'">
                                                    <div class="form-group">
                                                        <label>Lease Term Applied On:</label>
                                                        <div class="custom_radio">
                                                            <input type="radio" name="term_apply_cat" id="apply_all" v-model="term_apply_cat" value="all"/>
                                                            <label for="apply_all">All</label>
                                                            <input type="radio" name="term_apply_cat" id="apply_url" v-model="term_apply_cat" value="url"/>
                                                            <label for="apply_url">Only Leasing URL</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12" v-if="lease_term_type == 'static'">
                                                    <div class="form-group">
                                                        <label>Select Lease Term (Month):</label>
                                                        <div class="select_fld">
                                                            <Multiselect v-model="LeaseTermMonth.value" v-bind="LeaseTermMonth" placeholder="12 Months " />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-body">
                                        <div class="Page_content">
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                                    <div class="form-group">
                                                        <label>Lease Term Wise Price (Tooltip):</label>
                                                        <div class="custom_radio">
                                                            <input type="radio" name="price_tooltip_type" id="informatic_tooltip" v-model="price_tooltip_type" value="informatic"/>
                                                            <label for="informatic_tooltip">Informatic Only</label>
                                                            <input type="radio" name="price_tooltip_type" id="clickable_tooltip" v-model="price_tooltip_type" value="clickable"/>
                                                            <label for="clickable_tooltip">Clickable</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-6 col-12" v-if="price_tooltip_type == 'clickable'">
                                                    <div class="form-group">
                                                        <label>If Clickable Lease Now Page Open In :</label>
                                                        <div class="custom_radio">
                                                            <input type="radio" name="size" id="size_13" value="small" checked />
                                                            <label for="size_13">Same Page</label>
                                                            <input type="radio" name="size" id="size_14" value="small" />
                                                            <label for="size_14">New Page</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                                    <div class="form-group">
                                                        <label>Lease Term Wise Price Info:</label>
                                                        <div class="custom_radio">
                                                            <input type="radio" name="is_wise_price_info" id="wise_price_info" v-model="is_wise_price_info" :value="1"/>
                                                            <label for="wise_price_info">Yes</label>
                                                            <input type="radio" name="is_wise_price_info" id="no_wise_price_info" v-model="is_wise_price_info" :value="0"/>
                                                            <label for="no_wise_price_info">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="update_bottom">
                    <ul>
                        <li><span>*</span> Press update button to save this changes</li>
                        <li>
                            <router-link to="/"><button class="update_btn">Update</button></router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <LeftPreviewPanel/> -->
        </div>
    </div>
</template>

          
<script>
    import LeftPreviewPanel from "@/views/LeftPreviewPanel.vue"
    import Multiselect from '@vueform/multiselect'
    import Checkbox from "@/components/Checkbox.vue"

    export default {
        name: 'Api Credential',
        components: {
          LeftPreviewPanel,
          Multiselect,
          Checkbox
        },
        data() {
            return {
                tab: 'tab1',
                tab: 'tab2',
                vacant_bit: 0,
                available_bit: 0,
                multi_id_type: 1,
                is_split_floor_plans: 1,
                is_plan_configuration: 1,
                term_type: 1,
                is_wise_price_info: 1,
                device_choice: 'all',
                custom_device: 'desktop',
                desktop_spec: 'popup',
                tablet_spec: 'dropdown',
                mobile_spec: 'tooltip',
                lease_term_type: 'static',
                term_apply_cat: 'all',
                price_tooltip_type: 'informatic',
                price_api: 'availibility',
                multi_api_site_id:[
                    {
                        site_id: '',
                        site_url: '',
                    }
                ],
                multi_rent_cafe:[
                    {
                        api_token: '',
                        property_code: '',
                        api_key: '',
                        company_code: '',
                        property_id: '',
                        price_api: 'availibility',
                    }
                ],
                multi_entrata:[
                    {
                        property_id: '',
                    }
                ],
                api_type: {
                    mode: 'single',
                    value: 'realpage',
                    options: [
                        { value: 'realpage', label: 'Real Page' },
                        { value: 'rentcafe', label: 'Rent Cafe' },
                        { value: 'entrata', label: 'Entrata' },
                        { value: 'custom', label: 'Custom' }
                    ],
                },
                stream_type: {
                    mode: 'single',
                    value: 'onesite',
                    options: [
                        { value: 'onesite', label: 'Onesite' },
                        { value: 'rpx', label: 'RPX' },
                    ],
                },
                FloorPlan: {
                    mode: 'single',
                    value: 'Custom',
                    options: [
                        { value: 'Custom', label: 'Custom' },
                        { value: 'API - FloorPlanName', label: 'API - FloorPlanName' },
                        { value: 'API - FloorPlanNameMarketing', label: 'API - FloorPlanNameMarketing' },
                        { value: 'API - FloorPlanGroupName', label: 'API - FloorPlanGroupName' }
                    ],
                },
                LeaseTerm: {
                    mode: 'single',
                    value: 'Lease Term - 12 (LeaseTermMin - 09 | LeaseTermMax - 15)',
                    options: [
                        { value: 'Lease Term - 12 (LeaseTermMin - 09 | LeaseTermMax - 15)', label: 'Lease Term - 12 (LeaseTermMin - 09 | LeaseTermMax - 15)' },
                        { value: 'Lease Term - 11 (LeaseTermMin - 08 | LeaseTermMax - 14)', label: 'Lease Term - 11 (LeaseTermMin - 08 | LeaseTermMax - 14)' },
                        { value: 'Lease Term - 10 (LeaseTermMin - 07 | LeaseTermMax - 13)', label: 'Lease Term - 10 (LeaseTermMin - 07 | LeaseTermMax - 13)' },
                        { value: 'Lease Term - 09 (LeaseTermMin - 06 | LeaseTermMax - 12)', label: 'Lease Term - 09 (LeaseTermMin - 06 | LeaseTermMax - 12)' },
                        { value: 'Lease Term - 08 (LeaseTermMin - 05 | LeaseTermMax - 11)', label: 'Lease Term - 08 (LeaseTermMin - 05 | LeaseTermMax - 11)' }
                    ],
                },
                range_list_term_start:{
                    mode: 'single',
                    value: 'Start LeaseTermMin - 05',
                    options: [
                        { value: 'Start LeaseTermMin - 05', label: 'Start LeaseTermMin - 05' },
                        { value: 'Start LeaseTermMin - 06', label: 'Start LeaseTermMin - 06' },
                        { value: 'Start LeaseTermMin - 07', label: 'Start LeaseTermMin - 07' },
                        { value: 'Start LeaseTermMin - 08', label: 'Start LeaseTermMin - 08' },
                        { value: 'Start LeaseTermMin - 09', label: 'Start LeaseTermMin - 09' },
                        { value: 'Start LeaseTermMin - 10', label: 'Start LeaseTermMin - 10' },
                        { value: 'Start LeaseTermMin - 11', label: 'Start LeaseTermMin - 11' },
                        { value: 'Start LeaseTermMin - 12', label: 'Start LeaseTermMin - 12' },
                        { value: 'Start LeaseTermMin - 13', label: 'Start LeaseTermMin - 13' },
                        { value: 'Start LeaseTermMin - 14', label: 'Start LeaseTermMin - 14' },
                    ],
                },
                range_list_term_end:{
                    mode: 'single',
                    value: 'Start LeaseTermMax - 15',
                    options: [
                        { value: 'End LeaseTermMax - 15', label: 'End LeaseTermMax - 15' },
                        { value: 'End LeaseTermMax - 14', label: 'End LeaseTermMax - 14' },
                        { value: 'End LeaseTermMax - 13', label: 'End LeaseTermMax - 13' },
                        { value: 'End LeaseTermMax - 12', label: 'End LeaseTermMax - 12' },
                        { value: 'End LeaseTermMax - 11', label: 'End LeaseTermMax - 11' },
                        { value: 'End LeaseTermMax - 10', label: 'End LeaseTermMax - 10' },
                        { value: 'End LeaseTermMax - 09', label: 'End LeaseTermMax - 09' },
                        { value: 'End LeaseTermMax - 08', label: 'End LeaseTermMax - 08' },
                        { value: 'End LeaseTermMax - 07', label: 'End LeaseTermMax - 07' },
                        { value: 'End LeaseTermMax - 06', label: 'End LeaseTermMax - 06' },
                    ],
                },
                LeaseTermMonth: {
                    mode: 'single',
                    value: '2 Months',
                    options: [
                        { value: '2 Months', label: '2 Months' },
                        { value: '3 Months ', label: '3 Months' },
                        { value: '4 Months ', label: '4 Months ' },
                        { value: '5 Months ', label: '5 Months ' },
                        { value: '5 Months ', label: '6 Months ' }
                    ],
                },
            };
        },
        methods:{
            addNewSite(){
                const vm = this;

                vm.multi_api_site_id.push({
                    site_id: '',
                    site_url: '',
                })
            },
            removeSite(el){
                const vm = this;

                vm.multi_api_site_id.splice(el, 1);
            },

            addNewProperty(){
                const vm = this;

                vm.multi_rent_cafe.push({
                    api_token: '',
                    property_code: '',
                    api_key: '',
                    company_code: '',
                    property_id: '',
                    price_api: 'availibility',
                })
            },
            removeProperty(el){
                const vm = this;

                vm.multi_rent_cafe.splice(el, 1);
            },

            addNewEntrataId(){
                const vm = this;

                vm.multi_entrata.push({
                    property_id: '',
                })
            },
            removeEntrataId(el){
                const vm = this;

                vm.multi_entrata.splice(el, 1);
            },
        }
    };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
