<template>
    <div class="container-fluid">
        <div class="general_configuration">
            <div class="general_configuration_details">
                <div class="row mb-4 align-items-center">
                    <div class="col-12">
                        <h1>Pospect Guide List</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div class="dataTable_top">
                            <ul>
                                <li>
                                    <div class="list_length">
                                    <Multiselect
                                        v-model="list.value"
                                        v-bind="list"
                                        placeholder="5"
                                    />
                                    </div>
                                </li>
                                <li>
                                    <div class="show_action_icon">
                                    <input
                                        class="styled-checkbox"
                                        id="styled-checkbox-2"
                                        type="checkbox"
                                        value="value2"
                                        hidden
                                    />
                                    <label for="styled-checkbox-2" @click="showAllAction">Show All Action Icons</label>
                                    </div>
                                </li>
                                <li class="ml-auto">
                                    <div class="sorting">
                                    <div class="sort_btn" @click="sorting = 'ascending'" v-if="sorting == 'descending'">
                                        <svg
                                        viewBox="0 0 256 256"
                                        xmlns="http://www.w3.org/2000/svg"
                                        >
                                        <rect
                                            fill="none"
                                            height="256"
                                            width="256"
                                        />
                                        <polyline
                                            fill="none"
                                            points="144 168 184 208 224 168"
                                            stroke="#000"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="12"
                                        />
                                        <line
                                            fill="none"
                                            stroke="#000"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="12"
                                            x1="184"
                                            x2="184"
                                            y1="112"
                                            y2="208"
                                        />
                                        <line
                                            fill="none"
                                            stroke="#000"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="12"
                                            x1="48"
                                            x2="120"
                                            y1="128"
                                            y2="128"
                                        />
                                        <line
                                            fill="none"
                                            stroke="#000"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="12"
                                            x1="48"
                                            x2="184"
                                            y1="64"
                                            y2="64"
                                        />
                                        <line
                                            fill="none"
                                            stroke="#000"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="12"
                                            x1="48"
                                            x2="104"
                                            y1="192"
                                            y2="192"
                                        />
                                        </svg>
                                    </div>
                                    <div class="sort_btn" @click="sorting = 'descending'" v-if="sorting == 'ascending'">
                                        <svg
                                        viewBox="0 0 256 256"
                                        xmlns="http://www.w3.org/2000/svg"
                                        >
                                        <rect
                                            fill="none"
                                            height="256"
                                            width="256"
                                        />
                                        <polyline
                                            fill="none"
                                            points="144 88 184 48 224 88"
                                            stroke="#000"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="12"
                                        />
                                        <line
                                            fill="none"
                                            stroke="#000"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="12"
                                            x1="184"
                                            x2="184"
                                            y1="144"
                                            y2="48"
                                        />
                                        <line
                                            fill="none"
                                            stroke="#000"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="12"
                                            x1="48"
                                            x2="120"
                                            y1="128"
                                            y2="128"
                                        />
                                        <line
                                            fill="none"
                                            stroke="#000"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="12"
                                            x1="48"
                                            x2="104"
                                            y1="64"
                                            y2="64"
                                        />
                                        <line
                                            fill="none"
                                            stroke="#000"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="12"
                                            x1="48"
                                            x2="184"
                                            y1="192"
                                            y2="192"
                                        />
                                        </svg>
                                    </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="search">
                                    <input
                                        type="text"
                                        placeholder="Search Here...."
                                    />
                                    <div class="search_icon">
                                        <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                        >
                                        <path
                                            d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"
                                        />
                                        </svg>
                                    </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="card">
                            <div class="card-body p-0">
                                <div class="dataTable">
                                    <div class="table-responsive m-0">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Sl No</th>
                                                    <th scope="col">Website</th>
                                                    <th scope="col">Pospect Name</th>
                                                    <th scope="col">Used On</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="items in apartmentsyncsitelist" :key="items">
                                                    <td class="serial">
                                                        <span class="res-label">Sl No:</span>
                                                        <div class="content justify-content-center"><p>{{items.SlNo}}</p></div>
                                                    </td>
                                                    <td>
                                                        <span class="res-label">Website:</span>
                                                        <div class="content"><p>{{items.Website}}</p></div>
                                                    </td>
                                                    <td>
                                                        <span class="res-label">Pospect Name:</span>
                                                        <div class="content"><p>{{items.PospectName}}</p></div>
                                                    </td>
                                                    <td>
                                                        <span class="res-label">Used On:</span>
                                                        <div class="content"><p>{{items.UsedOn}}</p></div>
                                                    </td>
                                                    <td>
                                                        <span class="res-label">Status:</span>
                                                        <div class="content"><p>{{items.Status}}</p></div>
                                                    </td>
                                                    <td>
                                                        <span class="res-label">Date:</span>
                                                        <div class="content"><p>{{items.Date}}</p></div>
                                                    </td>
                                                    <td>
                                                        <span class="res-label">Action:</span>
                                                        <div class="action_btn" @click="openAction_btn">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                                <path
                                                                    d="M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zM120 96c0 30.9-25.1 56-56 56S8 126.9 8 96S33.1 40 64 40s56 25.1 56 56z"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div class="action_btn_panle prospect">
                                                            <div class="action_btn_group">
                                                                <ul>                                                                                               
                                                                    <li>
                                                                        <div class="icons">
                                                                            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <g clip-path="url(#clip0_301_4)">
                                                                                <path d="M16.75 7C15.3711 7 14.25 8.12109 14.25 9.5V18.25C14.25 19.6289 15.3711 20.75 16.75 20.75H25.5C26.8789 20.75 28 19.6289 28 18.25V9.5C28 8.12109 26.8789 7 25.5 7H16.75ZM10.5 13.25C9.12109 13.25 8 14.3711 8 15.75V24.5C8 25.8789 9.12109 27 10.5 27H19.25C20.6289 27 21.75 25.8789 21.75 24.5V22H19.875V24.5C19.875 24.8438 19.5938 25.125 19.25 25.125H10.5C10.1562 25.125 9.875 24.8438 9.875 24.5V15.75C9.875 15.4062 10.1562 15.125 10.5 15.125H13V13.25H10.5Z" fill="white"/>
                                                                                </g>
                                                                                <defs>
                                                                                <clipPath id="clip0_301_4">
                                                                                <rect width="20" height="20" fill="white" transform="translate(8 7)"/>
                                                                                </clipPath>
                                                                                </defs>
                                                                            </svg>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div class="icons">
                                                                            <svg
                                                                                viewBox="0 0 576 512"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="auto"
                                                                                height="16"
                                                                                >
                                                                                <path
                                                                                    d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div class="icons">
                                                                            <svg
                                                                                enable-background="new 0 0 32 32"
                                                                                width="auto"
                                                                                height="26"
                                                                                id="Layer_1"
                                                                                version="1.1"
                                                                                viewBox="0 0 32 32"
                                                                                xml:space="preserve"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                                >
                                                                                <g>
                                                                                    <polyline
                                                                                    fill="none"
                                                                                    points="   649,137.999 675,137.999 675,155.999 661,155.999  "
                                                                                    stroke="#FFFFFF"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-miterlimit="10"
                                                                                    stroke-width="2"
                                                                                    />
                                                                                    <polyline
                                                                                    fill="none"
                                                                                    points="   653,155.999 649,155.999 649,141.999  "
                                                                                    stroke="#FFFFFF"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-miterlimit="10"
                                                                                    stroke-width="2"
                                                                                    />
                                                                                    <polyline
                                                                                    fill="none"
                                                                                    points="   661,156 653,162 653,156  "
                                                                                    stroke="#FFFFFF"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-miterlimit="10"
                                                                                    stroke-width="2"
                                                                                    />
                                                                                </g>
                                                                                <g>
                                                                                    <g>
                                                                                    <path
                                                                                        d="M16,25c-4.265,0-8.301-1.807-11.367-5.088c-0.377-0.403-0.355-1.036,0.048-1.413c0.404-0.377,1.036-0.355,1.414,0.048    C8.778,21.419,12.295,23,16,23c4.763,0,9.149-2.605,11.84-7c-2.69-4.395-7.077-7-11.84-7c-4.938,0-9.472,2.801-12.13,7.493    c-0.272,0.481-0.884,0.651-1.363,0.377c-0.481-0.272-0.649-0.882-0.377-1.363C5.147,10.18,10.333,7,16,7    c5.668,0,10.853,3.18,13.87,8.507c0.173,0.306,0.173,0.68,0,0.985C26.853,21.819,21.668,25,16,25z"
                                                                                    />
                                                                                    </g>
                                                                                    <g>
                                                                                    <path
                                                                                        d="M16,21c-2.757,0-5-2.243-5-5s2.243-5,5-5s5,2.243,5,5S18.757,21,16,21z M16,13c-1.654,0-3,1.346-3,3s1.346,3,3,3    s3-1.346,3-3S17.654,13,16,13z"
                                                                                    />
                                                                                    </g>
                                                                                </g>
                                                                            </svg>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div class="icons">
                                                                            <svg
                                                                                viewBox="0 0 640 512"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="auto"
                                                                                height="16"
                                                                                >
                                                                                <path
                                                                                    d="M416 31.94C416 21.75 408.1 0 384.1 0c-13.98 0-26.87 9.072-30.89 23.18l-128 448c-.8404 2.935-1.241 5.892-1.241 8.801C223.1 490.3 232 512 256 512c13.92 0 26.73-9.157 30.75-23.22l128-448C415.6 37.81 416 34.85 416 31.94zM176 143.1c0-18.28-14.95-32-32-32c-8.188 0-16.38 3.125-22.62 9.376l-112 112C3.125 239.6 0 247.8 0 255.1S3.125 272.4 9.375 278.6l112 112C127.6 396.9 135.8 399.1 144 399.1c17.05 0 32-13.73 32-32c0-8.188-3.125-16.38-9.375-22.63L77.25 255.1l89.38-89.38C172.9 160.3 176 152.2 176 143.1zM640 255.1c0-8.188-3.125-16.38-9.375-22.63l-112-112C512.4 115.1 504.2 111.1 496 111.1c-17.05 0-32 13.73-32 32c0 8.188 3.125 16.38 9.375 22.63l89.38 89.38l-89.38 89.38C467.1 351.6 464 359.8 464 367.1c0 18.28 14.95 32 32 32c8.188 0 16.38-3.125 22.62-9.376l112-112C636.9 272.4 640 264.2 640 255.1z"
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div class="icons">
                                                                            <svg
                                                                                enable-background="new 0 0 32 32"
                                                                                width="auto"
                                                                                height="20"
                                                                                id="Layer_1"
                                                                                version="1.1"
                                                                                viewBox="0 0 32 32"
                                                                                xml:space="preserve"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                                >
                                                                                <g>
                                                                                    <polyline
                                                                                    fill="none"
                                                                                    points="   649,137.999 675,137.999 675,155.999 661,155.999  "
                                                                                    stroke="#FFFFFF"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-miterlimit="10"
                                                                                    stroke-width="2"
                                                                                    />
                                                                                    <polyline
                                                                                    fill="none"
                                                                                    points="   653,155.999 649,155.999 649,141.999  "
                                                                                    stroke="#FFFFFF"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-miterlimit="10"
                                                                                    stroke-width="2"
                                                                                    />
                                                                                    <polyline
                                                                                    fill="none"
                                                                                    points="   661,156 653,162 653,156  "
                                                                                    stroke="#FFFFFF"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-miterlimit="10"
                                                                                    stroke-width="2"
                                                                                    />
                                                                                </g>
                                                                                <g>
                                                                                    <path
                                                                                    d="M28,6h-6.382l-1.724-3.447C19.725,2.214,19.379,2,19,2h-6c-0.379,0-0.725,0.214-0.895,0.553L10.382,6H4   C3.448,6,3,6.448,3,7s0.448,1,1,1h20v20H8V11c0-0.552-0.448-1-1-1s-1,0.448-1,1v18c0,0.553,0.448,1,1,1h18c0.553,0,1-0.447,1-1V8h2   c0.553,0,1-0.448,1-1S28.553,6,28,6z M13.618,4h4.764l1,2h-6.764L13.618,4z"
                                                                                    />
                                                                                    <path
                                                                                    d="M14,24V14c0-0.552-0.448-1-1-1s-1,0.448-1,1v10c0,0.553,0.448,1,1,1S14,24.553,14,24z"
                                                                                    />
                                                                                    <path
                                                                                    d="M20,24V14c0-0.552-0.447-1-1-1s-1,0.448-1,1v10c0,0.553,0.447,1,1,1S20,24.553,20,24z"
                                                                                    />
                                                                                </g>
                                                                            </svg>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="right_action_panle" @click="openAction_btn">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                                    <path
                                                                        d="M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zM120 96c0 30.9-25.1 56-56 56S8 126.9 8 96S33.1 40 64 40s56 25.1 56 56z"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dataTable_footer">
                            <div class="dataTables_length">Showing 1 to 10 of <span>1,087</span></div>
                            <nav class="data-pagination" data-pagination>
                                <a href="#" disabled>
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                    >
                                    <path
                                        d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
                                    />
                                    </svg>
                                </a>
                                <ul>
                                    <li><a href="#">1</a></li>
                                    <li><a href="#">2</a></li>
                                    <li><a href="#">3</a></li>
                                    <li><a href="#">4</a></li>
                                    <li class="current"><a a href="#">5</a></li>
                                    <li><a a href="#">…</a></li>
                                    <li><a a href="#">55</a></li>
                                </ul>
                                <a href="#">
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                    >
                                    <path
                                        d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
                                    />
                                    </svg>
                                </a>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from "@vueform/multiselect";
    export default {
        components: {
            Multiselect,
        },
        data() {
            return {
                show_all: false,
                sorting: 'ascending',
                list: {
                    mode: "single",
                    value: "5",
                    options: [
                        { value: "10", label: "10" },
                        { value: "25", label: "25" },
                        { value: "50", label: "50" },
                        { value: "100", label: "100" },
                    ],
                    searchable: false,
                    createTag: false,
                },
                apartmentsyncsitelist: [
                    {
                        SlNo: 1,
                        Website: "Beacon Station",
                        PospectName: "Beacon Station",
                        UsedOn: "https://beaconstationaugusta.com/",
                        Status: "Active",
                        Date: "19 Jan 2021"
                    },
                    {
                        SlNo: 2,
                        Website: "Pegasus - Discovery on Broad",
                        PospectName: "Discovery on Broad",
                        UsedOn: "https://www.discoveryonbroad.com/",
                        Status: "Active",
                        Date: "14 Jan 2021"
                    },
                    {
                        SlNo: 3,
                        Website: "Olive DTLA",
                        PospectName: "Olive DTLA",
                        UsedOn: "https://olive-dtla.multiscreensite.com/",
                        Status: "Active",
                        Date: "28 Dec 2020"
                    },
                    {
                        SlNo: 4,
                        Website: "The Reserve at Crowfield",
                        PospectName: "Reserve at Crowfield",
                        UsedOn: "http://dev-reserve-at-crowfield.pantheonsite.io/",
                        Status: "Active",
                        Date: "15 Oct 2020"
                    },
                    {
                        SlNo: 5,
                        Website: "Mill The Holland",
                        PospectName: "The Mill At New Holland",
                        UsedOn: "http://dev-the-mill-at-new-holland.pantheonsite.io/",
                        Status: "Active",
                        Date: "13 Oct 2020"
                    },
                ],
            };
        },
        methods: {
            openAction_btn(ev) {
                const el = ev.currentTarget.closest('td');
                const elTarget = el.querySelector('.action_btn_panle');
                const allEl = document.querySelectorAll(".action_btn_panle");

                if (elTarget.classList.contains("action_btn_panle_active")) {
                    elTarget.classList.remove("action_btn_panle_active");
                } else {
                    for(let i = 0; i < allEl.length; i++){
                    allEl[i].classList.remove("action_btn_panle_active");
                    }
                    elTarget.classList.add("action_btn_panle_active");
                }
            },

            showAllAction(){
                const vm = this;
                vm.show_all = !vm.show_all;
                const allEl = document.querySelectorAll(".action_btn_panle");

                if(vm.show_all){
                    for(let i = 0; i < allEl.length; i++){
                    allEl[i].classList.add("action_btn_panle_active");
                    allEl[i].style.transition = `0.${i + 5}s`;
                    }
                }else{
                    for(let i = 0; i < allEl.length; i++){
                    allEl[i].classList.remove("action_btn_panle_active");
                    }
                }
            },
        },
    };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
