import { createWebHistory, createRouter } from "vue-router";
import Dashboard from '../views/Dashboard.vue'
import SiteConfiguration from '../views/SiteConfiguration.vue'
import ApiCredential from '@/views/repliapartmentsyncs/ApiCredential.vue'
import ApartmentsyncSiteList from '@/views/repliapartmentsyncs/ApartmentsyncSiteList'
import GeneralConfiguration from '@/views/repliapartmentsyncs/GeneralConfiguration.vue'
import TemplateConfiguration from '@/views/repliapartmentsyncs/TemplateConfiguration.vue'
import FloorPlanDetails from '@/views/repliapartmentsyncs/FloorPlanDetails.vue'
import FloorPlanSorting from '@/views/repliapartmentsyncs/FloorPlanSorting.vue'
import PenthouseUrbanStudioConfiguration from '@/views/repliapartmentsyncs/PenthouseUrbanStudioConfiguration.vue'
import PriceConfiguration from '@/views/repliapartmentsyncs/PriceConfiguration.vue'
import DepositPriceConfiguration from '@/views/repliapartmentsyncs/DepositPriceConfiguration.vue'
import CallForPricingManualConfiguration from '@/views/repliapartmentsyncs/CallForPricingManualConfiguration.vue'
import CallForPricingConfiguration from '@/views/repliapartmentsyncs/CallForPricingConfiguration.vue'
import LeasingConfiguration from '@/views/repliapartmentsyncs/LeasingConfiguration.vue'
import AllPopUpConfiguration from '@/views/repliapartmentsyncs/AllPopUpConfiguration.vue'
import JoinOurWaitlistSection from '@/views/repliapartmentsyncs/JoinOurWaitlistSection.vue'
import AvailibilityTabSection from '@/views/repliapartmentsyncs/AvailibilityTabSection.vue'
import AvailibilityMessageSection from '@/views/repliapartmentsyncs/AvailibilityMessageSection.vue'
import ExtraAvailibilityMessageSection from '@/views/repliapartmentsyncs/ExtraAvailibilityMessageSection.vue'
import NoAvailibilityMessageSection from '@/views/repliapartmentsyncs/NoAvailibilityMessageSection.vue'
import LandingPageConfiguration from '@/views/repliapartmentsyncs/LandingPageConfiguration.vue'
import MaximumAvailableUnitConfiguration from '@/views/repliapartmentsyncs/MaximumAvailableUnitConfiguration.vue'
import AmenitiesPageSettings from '@/views/repliapartmentsyncs/AmenitiesPageSettings.vue'
import DetailsPageSettings from '@/views/repliapartmentsyncs/DetailsPageSettings.vue'
import FloorplanFieldsShowHide from '@/views/repliapartmentsyncs/FloorplanFieldsShowHide.vue'
import LeaseNowConfiguration from '@/views/repliapartmentsyncs/LeaseNowConfiguration.vue'
import UnitListColumn from '@/views/repliapartmentsyncs/UnitListColumn.vue'
import InteractiveLeasing from '@/views/repliapartmentsyncs/InteractiveLeasing.vue'
import OtherSettings from '@/views/repliapartmentsyncs/OtherSettings.vue'
import ListViewSortingSettings from '@/views/repliapartmentsyncs/ListViewSortingSettings.vue'
import ShowActiveViewes from '@/views/repliapartmentsyncs/ShowActiveViewes.vue'
import DisplayCustomMessageFloorPlanList from '@/views/repliapartmentsyncs/DisplayCustomMessageFloorPlanList.vue'
import DisplayCustomMessageUnitList from '@/views/repliapartmentsyncs/DisplayCustomMessageUnitList.vue'
import FloorplanDetailsFromRealPage from '@/views/repliapartmentsyncs/FloorplanDetailsFromRealPage.vue'
import FloorplanGroupConfiguration from '@/views/repliapartmentsyncs/FloorplanGroupConfiguration.vue'
import CTAManagement from '@/views/repliapartmentsyncs/CTAManagement.vue'
import CornSetup from '@/views/repliapartmentsyncs/CornSetup.vue'
import CustomScript from '@/views/repliapartmentsyncs/CustomScript.vue'
import ColorstyleSettings from '@/views/repliapartmentsyncs/ColorstyleSettings.vue'
import APIUserGuide from '@/views/repliapartmentsyncs/APIUserGuide.vue'
import RealPageOnesite from '@/views/repliapartmentsyncs/RealPageOnesite.vue'
import RealPageRPX from '@/views/repliapartmentsyncs/RealPageRPX.vue'
import RealPageSingleSiteID from '@/views/repliapartmentsyncs/RealPageSingleSiteID.vue'
import RealPageMultipleSiteID from '@/views/repliapartmentsyncs/RealPageMultipleSiteID.vue'
import RentCafeSingleSiteID from '@/views/repliapartmentsyncs/RentCafeSingleSiteID.vue'
import RentCafeMultipleSiteID from '@/views/repliapartmentsyncs/RentCafeMultipleSiteID.vue'
import EntrataSingleSiteID from '@/views/repliapartmentsyncs/EntrataSingleSiteID.vue'
import EntrataMultipleSiteID from '@/views/repliapartmentsyncs/EntrataMultipleSiteID.vue'
import Custom from '@/views/repliapartmentsyncs/Custom.vue'
import FloorPlanGroup from '@/views/floorplanmanagement/FloorPlanGroup.vue'
import FloorPlanGroupEdit from '@/views/floorplanmanagement/FloorPlanGroupEdit.vue'
import FloorPlanList from '@/views/floorplanmanagement/FloorPlanList.vue'
import FloorPlanListEdit from '@/views/floorplanmanagement/FloorPlanListEdit.vue'
import AdditionalGroup from '@/views/floorplanmanagement/AdditionalGroup.vue'
import FloorPlanGroupSorting from '@/views/floorplanmanagement/FloorPlanGroupSorting.vue'
import FloorPlanGroupManagement from '@/views/floorplanmanagement/FloorPlanGroupManagement.vue'
import FloorPlanTraffic from '@/views/floorplanmanagement/FloorPlanTraffic.vue'
import FloorPlanSync from '@/views/floorplanmanagement/FloorPlanSync.vue'
import CronDetails from '@/views/floorplanmanagement/CronDetails.vue'
import Companies from '@/views/companymanagement/Companies.vue'
import CompaniesCreate from '@/views/companymanagement/CompaniesCreate.vue'
import CompaniesConfiguration from '@/views/companymanagement/CompaniesConfiguration.vue'
import ColorstyleSetting from '@/views/companymanagement/ColorstyleSetting.vue'
import APIuserguide from '@/views/companymanagement/APIuserguide.vue'
import DynamicMap from '@/views/companymanagement/DynamicMap.vue'
import Community from '@/views/communitywebsites/Community.vue'
import CommunitySiteAdd from '@/views/communitywebsites/CommunitySiteAdd.vue'
import CommunitySiteEdit from '@/views/communitywebsites/CommunitySiteEdit.vue'
import Pospects from '@/views/prospectguide/Pospects.vue'
import TemplateList from '@/views/prospectguide/TemplateList.vue'
import AdditionalFeature from '@/views/companymanagement/AdditionalFeature.vue'
import AdditionalFeatureAdd from '@/views/companymanagement/AdditionalFeatureAdd.vue'
import AdditionalFeatureEdit from '@/views/companymanagement/AdditionalFeatureEdit.vue'
import Microsites from '@/views/companymanagement/Microsites.vue'
import MicrositesAdd from '@/views/companymanagement/MicrositesAdd.vue'
import FloorplanType from '@/views/companymanagement/FloorplanType.vue'
import FloorplanTypeAdd from '@/views/companymanagement/FloorplanTypeAdd.vue'
import FloorplanTypeEdit from '@/views/companymanagement/FloorplanTypeEdit.vue'
import LivingOptionsList from '@/views/companymanagement/LivingOptionsList.vue'
import LivingOptionsAdd from '@/views/companymanagement/LivingOptionsAdd.vue'
import LivingOptionsEdit from '@/views/companymanagement/LivingOptionsEdit.vue'
import PageNotFound from '../views/PageNotFound.vue'
import Testing from '../views/Testing.vue'
const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard',
    }
  },
  {
    path: '/repliapartmentsyncs/api-credential',
    name: 'ApiCredential',
    component: ApiCredential,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/siteconfiguration',
    name: 'SiteConfiguration',
    component: SiteConfiguration,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/general-configuration',
    name: 'GeneralConfiguration',
    component: GeneralConfiguration,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/apartmentsyncsitelist',
    name: 'ApartmentsyncSiteList',
    component: ApartmentsyncSiteList,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/template-configuration',
    name: 'TemplateConfiguration',
    component:TemplateConfiguration,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/floorPlan-details',
    name: 'FloorPlanDetails',
    component:FloorPlanDetails,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/floorPlan-sorting',
    name: 'FloorPlanSorting',
    component:FloorPlanSorting,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/penthouse-urban-studio-configuration',
    name: 'PenthouseUrbanStudioConfiguration',
    component:PenthouseUrbanStudioConfiguration,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/price-configuration',
    name: 'PriceConfiguration',
    component:PriceConfiguration,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/deposit-price-configuration',
    name: 'DepositPriceConfiguration',
    component:DepositPriceConfiguration,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/call-for-pricing-manual-configuration',
    name: 'CallForPricingManualConfiguration',
    component:CallForPricingManualConfiguration,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/call-for-pricing-configuration',
    name: 'CallForPricingConfiguration',
    component:CallForPricingConfiguration,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/leasing-configuration',
    name: 'LeasingConfiguration',
    component:LeasingConfiguration,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/all-popUp-configuration',
    name: 'AllPopUpConfiguration',
    component:AllPopUpConfiguration,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/join-our-waitlist-section',
    name: 'JoinOurWaitlistSection',
    component:JoinOurWaitlistSection,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/availibility-tab-section',
    name: 'AvailibilityTabSection',
    component:AvailibilityTabSection,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/availibility-message-section',
    name: 'AvailibilityMessageSection',
    component:AvailibilityMessageSection,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/extra-availibility-message-section',
    name: 'ExtraAvailibilityMessageSection',
    component:ExtraAvailibilityMessageSection,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/no-availibility-message-section',
    name: 'NoAvailibilityMessageSection',
    component:NoAvailibilityMessageSection,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/landing-page-configuration',
    name: 'LandingPageConfiguration',
    component:LandingPageConfiguration,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/maximum-available-unit-configuration',
    name: 'MaximumAvailableUnitConfiguration',
    component:MaximumAvailableUnitConfiguration,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/amenities-page-settings',
    name: 'AmenitiesPageSettings',
    component:AmenitiesPageSettings,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/details-page-settings',
    name: 'DetailsPageSettings',
    component:DetailsPageSettings,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/floor-plan-fields-show-hide',
    name: 'FloorplanFieldsShowHide',
    component:FloorplanFieldsShowHide,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/lease-now-configuration',
    name: 'LeaseNowConfiguration',
    component:LeaseNowConfiguration,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/unit-list-column-show-hide',
    name: 'UnitListColumn',
    component:UnitListColumn,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/interactive-leasing',
    name: 'InteractiveLeasing',
    component:InteractiveLeasing,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/other-settings',
    name: 'OtherSettings',
    component:OtherSettings,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/list-view-sorting-settings',
    name: 'ListViewSortingSettings',
    component:ListViewSortingSettings,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/show-active-viewes',
    name: 'ShowActiveViewes',
    component:ShowActiveViewes,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/display-custom-message-floor-plan-list',
    name: 'DisplayCustomMessageFloorPlanList',
    component:DisplayCustomMessageFloorPlanList,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/display-custom-message-unit-list',
    name: 'DisplayCustomMessageUnitList',
    component:DisplayCustomMessageUnitList,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/floor-plan-details-from-real-page',
    name: 'FloorplanDetailsFromRealPage',
    component:FloorplanDetailsFromRealPage,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/floor-plan-group-configuration',
    name: 'FloorplanGroupConfiguration',
    component:FloorplanGroupConfiguration,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/cta-management',
    name: 'CTAManagement',
    component:CTAManagement,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/corn-setup',
    name: 'CornSetup',
    component:CornSetup,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/customscript',
    name: 'CustomScript',
    component:CustomScript,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/colorstyle-settings',
    name: 'ColorstyleSettings',
    component:ColorstyleSettings,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/API-user-guide',
    name: 'APIUserGuide',
    component:APIUserGuide,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/api-credential',
    name: 'ApiCredential',
    component:ApiCredential,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/real-page-onesite',
    name: 'RealPageOnesite',
    component:RealPageOnesite,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/real-page-rpx',
    name: 'RealPageRPX',
    component:RealPageRPX,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/real-page-single-site',
    name: 'RealPageSingleSiteID',
    component:RealPageSingleSiteID,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/real-page-multiple-site',
    name: 'RealPageMultipleSiteID',
    component:RealPageMultipleSiteID,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/rent-cafe-single-site',
    name: 'RentCafeSingleSiteID',
    component:RentCafeSingleSiteID,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/rent-cafe-multiple-site',
    name: 'RentCafeMultipleSiteID',
    component:RentCafeMultipleSiteID,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/entrata-single-site',
    name: 'EntrataSingleSiteID',
    component:EntrataSingleSiteID,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/entrata-multiple-site',
    name: 'EntrataMultipleSiteID',
    component:EntrataMultipleSiteID,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/custom',
    name: 'Custom',
    component:Custom,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/floorplan-group',
    name: 'FloorPlanGroup',
    component:FloorPlanGroup,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/floorplan-group-Edit',
    name: 'FloorPlanGroupEdit',
    component:FloorPlanGroupEdit,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/floorplan-list',
    name: 'FloorPlanList',
    component:FloorPlanList,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/floorplan-list-Edit',
    name: 'FloorPlanListEdit',
    component:FloorPlanListEdit,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/additional-group',
    name: 'AdditionalGroup',
    component:AdditionalGroup,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/floor-plan-group-sorting',
    name: 'FloorPlanGroupSorting',
    component:FloorPlanGroupSorting,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/floor-plan-group-management',
    name: 'FloorPlanGroupManagement',
    component:FloorPlanGroupManagement,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/floor-plan-traffic',
    name: 'FloorPlanTraffic',
    component:FloorPlanTraffic,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/floor-plan-sync',
    name: 'FloorPlanSync',
    component:FloorPlanSync,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/repliapartmentsyncs/cron-details',
    name: 'CronDetails',
    component:CronDetails,
    meta: {
      title: 'Apartment Sync',
    }
  },
  {
    path: '/companymanagement/companies',
    name: 'Companies',
    component:Companies,
    meta: {
      title: 'Company Management',
    }
  },
  {
    path: '/companymanagement/companies-create',
    name: 'CompaniesCreate',
    component:CompaniesCreate,
    meta: {
      title: 'Company Management',
    }
  },
  {
    path: '/companymanagement/companies-configuration',
    name: 'CompaniesConfiguration',
    component:CompaniesConfiguration,
    meta: {
      title: 'Company Management',
    }
  },
  {
    path: '/companymanagement/colorstyle-setting',
    name: 'ColorstyleSetting',
    component:ColorstyleSetting,
    meta: {
      title: 'Company Management',
    }
  },
  {
    path: '/companymanagement/api-user-guide',
    name: 'APIuserguide',
    component:APIuserguide,
    meta: {
      title: 'Company Management',
    }
  },
  {
    path: '/companymanagement/dynamic-map',
    name: 'DynamicMap',
    component:DynamicMap,
    meta: {
      title: 'Company Management',
    }
  },
  {
    path: '/companymanagement/community',
    name: 'Community',
    component:Community,
    meta: {
      title: 'Company Websites',
    }
  },
  {
    path: '/companymanagement/community-site-add',
    name: 'CommunitySiteAdd',
    component:CommunitySiteAdd,
    meta: {
      title: 'Company Websites',
    }
  },
  {
    path: '/companymanagement/community-site-edit',
    name: 'CommunitySiteEdit',
    component:CommunitySiteEdit,
    meta: {
      title: 'Company Websites',
    }
  },

  {
    path: '/prospectguide/pospects',
    name: 'Pospects',
    component:Pospects,
    meta: {
      title: 'Prospect Guide',
    }
  },
  {
    path: '/prospectguide/template-list',
    name: 'TemplateList',
    component:TemplateList,
    meta: {
      title: 'Prospect Guide',
    }
  },





  {
    path: '/companymanagement/additional-feature',
    name: 'AdditionalFeature',
    component:AdditionalFeature,
    meta: {
      title: 'Company Management',
    }
  },
  {
    path: '/companymanagement/additional-feature-add',
    name: 'AdditionalFeatureAdd',
    component:AdditionalFeatureAdd,
    meta: {
      title: 'Company Management',
    }
  },
  {
    path: '/companymanagement/additional-feature-edit',
    name: 'AdditionalFeatureEdit',
    component:AdditionalFeatureEdit,
    meta: {
      title: 'Company Management',
    }
  },
  {
    path: '/companymanagement/microsites-add',
    name: 'MicrositesAdd',
    component:MicrositesAdd,
    meta: {
      title: 'Company Management',
    }
  },
  {
    path: '/companymanagement/microsites',
    name: 'Microsites',
    component:Microsites,
    meta: {
      title: 'Company Management',
    }
  },
  {
    path: '/companymanagement/floorplan-type',
    name: 'FloorplanType',
    component:FloorplanType,
    meta: {
      title: 'Company Management',
    }
  },
  {
    path: '/companymanagement/floorplan-type-add',
    name: 'FloorplanTypeAdd',
    component:FloorplanTypeAdd,
    meta: {
      title: 'Company Management',
    }
  },
  {
    path: '/companymanagement/floorplan-type-edit',
    name: 'FloorplanTypeEdit',
    component:FloorplanTypeEdit,
    meta: {
      title: 'Company Management',
    }
  },
  {
    path: '/companymanagement/living-options-list',
    name: 'LivingOptionsList',
    component:LivingOptionsList,
    meta: {
      title: 'Company Management',
    }
  },
  {
    path: '/companymanagement/living-options-add',
    name: 'LivingOptionsAdd',
    component:LivingOptionsAdd,
    meta: {
      title: 'Company Management',
    }
  },
  {
    path: '/companymanagement/living-options-edit',
    name: 'LivingOptionsEdit',
    component:LivingOptionsEdit,
    meta: {
      title: 'Company Management',
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: PageNotFound,
  },
  {
    path: '/testing',
    name: 'Testing',
    component: Testing,
  },
]

const router = createRouter({
  // mode: "history",
  history: createWebHistory(),
  routes,
});

export default router;