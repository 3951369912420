<template>
    <div class="header-sticky w-100">
        <div class="container-fluid d-flex">
            <div class="left_panel">
                <div class="toggle-menu mobile-toggle-menu">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path
                            d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
                        />
                    </svg>
                </div>
                <div class="repli_logo">
                    <router-link to="/">
                        <img src="@/assets/images/repli_logo.png" />
                    </router-link>
                </div>
                <div class="mobile_filter" @click="show = !show">
                    <button class="filter_btns">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"
                                />
                            </svg>
                        </div>
                        Filter
                    </button>
                </div>
                <Transition>
                <div class="mobile_filter_content" v-if="show">
                    <div class="content">
                    <div class="site_list">
                        <div class="company_list">
                            <div class="company_drop">
                                <label>
                                    <div class="icon"></div>
                                    Company
                                </label>
                            </div>
                            <Multiselect v-model="company.value" v-bind="company" placeholder="Weidnier" />
                        </div>
                        <div class="sites_list">
                            <div class="sites_list_drop">
                                <label>
                                    <div class="icon"></div>
                                    Sites
                                </label>
                            </div>
                            <Multiselect v-model="Sites.value" v-bind="Sites" placeholder="Weidnier" />
                        </div>
                    </div>
                    <div class="bottom_btn">
                        <div class="check_api_btn">
                            <router-link to="/">
                                <button class="check_api_btns">
                                    <div class="icon">
                                        <svg height="20" id="icon" viewBox="0 0 32 32" width="20" xmlns="http://www.w3.org/2000/svg">
                                            <defs></defs>
                                            <path
                                                d="M17,18.1841V13.8159a3.0007,3.0007,0,1,0-2,0v4.3687a3,3,0,1,0,2,0ZM16,10a1,1,0,1,1-1,1A1.0009,1.0009,0,0,1,16,10Zm0,12a1,1,0,1,1,1-1A1.0009,1.0009,0,0,1,16,22Z"
                                                transform="translate(0 0)"
                                            />
                                            <path
                                                d="M30.4141,17.4141a1.9995,1.9995,0,0,0,0-2.8282L24.6272,8.7993l2.9006-2.8628a2.0018,2.0018,0,1,0-1.4416-1.3872L23.2129,7.3848,17.4141,1.5859a1.9995,1.9995,0,0,0-2.8282,0L8.7993,7.3726,5.9368,4.4717A2.002,2.002,0,1,0,4.55,5.9136l2.835,2.8735L1.5859,14.5859a1.9995,1.9995,0,0,0,0,2.8282l5.7989,5.7988L4.55,26.0864a1.9977,1.9977,0,1,0,1.387,1.4419l2.8625-2.9009,5.7866,5.7867a1.9995,1.9995,0,0,0,2.8282,0l5.7988-5.7989,2.8733,2.8355a1.998,1.998,0,1,0,1.4416-1.3872l-2.9006-2.8628ZM16,29,3,16,16,3,29,16Z"
                                                transform="translate(0 0)"
                                            />
                                            <rect class="cls-1" data-name="&lt;Transparent Rectangle&gt;" height="32" id="_Transparent_Rectangle_" width="32" />
                                        </svg>
                                    </div>
                                    Check APIs
                                </button>
                            </router-link>
                        </div>
                        <div class="sync_details_btn">
                            <router-link to="/">
                                <button class="sync_details_btns">
                                    <div class="icon">
                                        <svg data-name="Layer 1" id="Layer_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M218.39,320.61,246.77,349H157a93,93,0,0,1,0-186h18V133H157a123,123,0,0,0,0,246h89.77l-28.38,28.38,21.22,21.23L304.22,364l-64.61-64.61Z" />
                                            <path d="M355,133H265.23l28.38-28.38L272.39,83.39,207.78,148l64.61,64.61,21.22-21.22L265.23,163H355a93,93,0,0,1,0,186H336.44v30H355a123,123,0,0,0,0-246Z" />
                                        </svg>
                                    </div>
                                    Show
                                </button>
                            </router-link>
                        </div>
                    </div>
                    </div>
                </div>
            </Transition>

                <div class="site_list">
                    <div class="company_list">
                        <label>
                            Company
                        </label>
                        <Multiselect v-model="company.value" v-bind="company" placeholder="Weidnier" />
                    </div>
                    <div class="sites_list">
                            <label>
                                Sites
                            </label>
                        <Multiselect v-model="Sites.value" v-bind="Sites" placeholder="Weidnier" />
                    </div>
                </div>
            </div>
            <div class="right_panel">
                <div class="check_api_btn">
                    <router-link to="/">
                        <button class="check_api_btns">
                            <div class="icon">
                                <svg height="16" id="icon" viewBox="0 0 32 32" width="16" xmlns="http://www.w3.org/2000/svg">
                                    <defs></defs>
                                    <path
                                        d="M17,18.1841V13.8159a3.0007,3.0007,0,1,0-2,0v4.3687a3,3,0,1,0,2,0ZM16,10a1,1,0,1,1-1,1A1.0009,1.0009,0,0,1,16,10Zm0,12a1,1,0,1,1,1-1A1.0009,1.0009,0,0,1,16,22Z"
                                        transform="translate(0 0)"
                                    />
                                    <path
                                        d="M30.4141,17.4141a1.9995,1.9995,0,0,0,0-2.8282L24.6272,8.7993l2.9006-2.8628a2.0018,2.0018,0,1,0-1.4416-1.3872L23.2129,7.3848,17.4141,1.5859a1.9995,1.9995,0,0,0-2.8282,0L8.7993,7.3726,5.9368,4.4717A2.002,2.002,0,1,0,4.55,5.9136l2.835,2.8735L1.5859,14.5859a1.9995,1.9995,0,0,0,0,2.8282l5.7989,5.7988L4.55,26.0864a1.9977,1.9977,0,1,0,1.387,1.4419l2.8625-2.9009,5.7866,5.7867a1.9995,1.9995,0,0,0,2.8282,0l5.7988-5.7989,2.8733,2.8355a1.998,1.998,0,1,0,1.4416-1.3872l-2.9006-2.8628ZM16,29,3,16,16,3,29,16Z"
                                        transform="translate(0 0)"
                                    />
                                    <rect class="cls-1" data-name="&lt;Transparent Rectangle&gt;" height="32" id="_Transparent_Rectangle_" width="32" />
                                </svg>
                            </div>
                            Check APIs
                        </button>
                    </router-link>
                </div>
                <div class="sync_details_btn">
                    <router-link to="/">
                        <button class="sync_details_btns">
                            <div class="icon">
                                <svg data-name="Layer 1" id="Layer_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M218.39,320.61,246.77,349H157a93,93,0,0,1,0-186h18V133H157a123,123,0,0,0,0,246h89.77l-28.38,28.38,21.22,21.23L304.22,364l-64.61-64.61Z" />
                                    <path d="M355,133H265.23l28.38-28.38L272.39,83.39,207.78,148l64.61,64.61,21.22-21.22L265.23,163H355a93,93,0,0,1,0,186H336.44v30H355a123,123,0,0,0,0-246Z" />
                                </svg>
                            </div>
                            Show
                        </button>
                    </router-link>
                </div>
                <div class="dropdown_user">
                    <div class="active"></div>
                    <Multiselect v-model="admin.value" v-bind="admin" placeholder="Repli Admin" />
                </div>
            </div>
        </div>
    </div>
</template>

    
    <script>
    import Multiselect from '@vueform/multiselect'
    export default {
    components: {
      Multiselect,
    },
    methods:{
  
    },
    data() {
      return {
        show:false,
        //Company Dropdown
        company: {
            mode: 'single',
            value: 'Weidnier',
            options: [
                { value: 'Weidnier', label: 'Weidnier' },
                { value: 'Coastal Ridge', label: 'Coastal Ridge' },
                { value: 'Audubon', label: 'Audubon' },
                { value: 'Waterton', label: 'Waterton' },
                { value: 'Pegasus Residential', label: 'Pegasus Residential' },
                { value: 'AlphaCapital Partners', label: 'AlphaCapital Partners' }
            ],
            searchable: false,
            createTag: false
        },
        //Sites Dropdown
        Sites: {
            mode: 'single',
            value: '222Hennepin',
            options: [
                { value: '222Hennepin', label: '222Hennepin' },
                { value: 'Daymark Uptown', label: 'Daymark Uptown' },
                { value: 'Tower 12 Apartments', label: 'Tower 12 Apartments' },
                { value: 'Waterton', label: 'Waterton' },
                { value: 'Woodin Creek Village', label: 'Woodin Creek Village' },
                { value: 'The Collection at Highland Bridge', label: 'The Collection at Highland Bridge' }
            ],
            searchable: false,
            createTag: false
        },
        //Repli Admin Dropdown
        admin: {
            mode: 'single',
            value: 'admin',
            options: [
                { value: 'Profile', label: 'Profile' },
                { value: 'Logout', label: 'Logout' }
            ],
            searchable: false,
            createTag: false
        },
      }
    }
  }
    </script>
    <style src="@vueform/multiselect/themes/default.css"></style>