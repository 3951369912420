<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="general_configuration">
                    <div class="general_configuration_details">
                        <div class="top_pannel">
                            <div class="heading">
                                <h3>Entrata Single Site ID</h3>
                            </div>
                            <button type="button" class="default_btn">Add More Property ID:</button>
                        </div>
                        <div class="row add_property">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-header align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">Add Property ID:</h4>
                                        <span class="remove">
                                            <svg fill="#000000" width="800px" height="800px" viewBox="-2.94 0 31.716 31.716" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="translate(-355.957 -579)">
                                                    <path
                                                        d="M376.515,610.716H361.231a2.361,2.361,0,0,1-2.358-2.359V584.1a1,1,0,0,1,2,0v24.255a.36.36,0,0,0,.358.359h15.284a.36.36,0,0,0,.358-.359V584.1a1,1,0,0,1,2,0v24.255A2.361,2.361,0,0,1,376.515,610.716Z"
                                                    ></path>
                                                    <path d="M365.457,604.917a1,1,0,0,1-1-1v-14a1,1,0,0,1,2,0v14A1,1,0,0,1,365.457,604.917Z"></path>
                                                    <path d="M372.29,604.917a1,1,0,0,1-1-1v-14a1,1,0,0,1,2,0v14A1,1,0,0,1,372.29,604.917Z"></path>
                                                    <path d="M380.79,585.1H356.957a1,1,0,0,1,0-2H380.79a1,1,0,0,1,0,2Z"></path>
                                                    <path d="M372.79,581h-7.917a1,1,0,1,1,0-2h7.917a1,1,0,0,1,0,2Z"></path>
                                                </g>
                                            </svg>
                                        </span>
                                    </div>
                                    <div class="card-body">
                                        <div class="Page_content rent-cafe-multiple-site">
                                            <div class="row">
                                                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label>Entrata Property ID: (to remove leave it blank) </label>
                                                        <input type="text" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label>Entrata Property ID: </label>
                                                        <input type="text" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label>Entrata Property ID: </label>
                                                        <input type="text" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label>Entrata Property ID: </label>
                                                        <input type="text" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-header align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">API Type:</h4>
                                        <span class="badge bg-danger align-middle fs-10 text-white ml-1">Required *</span>
                                    </div>
                                    <div class="card-body">
                                        <div class="Page_content rent-cafe-multiple-site">
                                            <div class="row">
                                                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label>
                                                            API Url:
                                                            <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                            <svg
                                                                version="1.1"
                                                                id="Layer_1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                x="0px"
                                                                y="0px"
                                                                width="427px"
                                                                height="427px"
                                                                viewBox="0 0 427 427"
                                                                enable-background="new 0 0 427 427"
                                                                xml:space="preserve"
                                                            >
                                                                <g>
                                                                    <polyline
                                                                        fill="none"
                                                                        stroke="#E89C9D"
                                                                        stroke-width="15"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        stroke-miterlimit="10"
                                                                        points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                    />
                                                                    <circle cx="208" cy="129.5" r="15.5" />
                                                                    <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                </g>
                                                            </svg>
                                                            </span>
                                                            <span class="badge bg-danger align-middle fs-10 text-white ml-1">Required *</span>
                                                        </label>
                                                        <input type="text" placeholder="https://coastalridge.entrata.com/api/v1" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label>
                                                            API Username:
                                                            <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                            <svg
                                                                version="1.1"
                                                                id="Layer_1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                x="0px"
                                                                y="0px"
                                                                width="427px"
                                                                height="427px"
                                                                viewBox="0 0 427 427"
                                                                enable-background="new 0 0 427 427"
                                                                xml:space="preserve"
                                                            >
                                                                <g>
                                                                    <polyline
                                                                        fill="none"
                                                                        stroke="#E89C9D"
                                                                        stroke-width="15"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        stroke-miterlimit="10"
                                                                        points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                    />
                                                                    <circle cx="208" cy="129.5" r="15.5" />
                                                                    <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                </g>
                                                            </svg>
                                                            </span>
                                                            <span class="badge bg-danger align-middle fs-10 text-white ml-1">Required *</span>
                                                        </label>
                                                        <input type="text" placeholder="repli_api@coastalridge" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label>
                                                            API Password:
                                                            <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                            <svg
                                                                version="1.1"
                                                                id="Layer_1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                x="0px"
                                                                y="0px"
                                                                width="427px"
                                                                height="427px"
                                                                viewBox="0 0 427 427"
                                                                enable-background="new 0 0 427 427"
                                                                xml:space="preserve"
                                                            >
                                                                <g>
                                                                    <polyline
                                                                        fill="none"
                                                                        stroke="#E89C9D"
                                                                        stroke-width="15"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        stroke-miterlimit="10"
                                                                        points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                    />
                                                                    <circle cx="208" cy="129.5" r="15.5" />
                                                                    <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                </g>
                                                            </svg>
                                                            </span>
                                                            <span class="badge bg-danger align-middle fs-10 text-white ml-1">Required *</span>
                                                        </label>
                                                        <input type="password" placeholder="1234589" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label>
                                                            Entrata Leadsource ID:
                                                            <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                            <svg
                                                                version="1.1"
                                                                id="Layer_1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                x="0px"
                                                                y="0px"
                                                                width="427px"
                                                                height="427px"
                                                                viewBox="0 0 427 427"
                                                                enable-background="new 0 0 427 427"
                                                                xml:space="preserve"
                                                            >
                                                                <g>
                                                                    <polyline
                                                                        fill="none"
                                                                        stroke="#E89C9D"
                                                                        stroke-width="15"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        stroke-miterlimit="10"
                                                                        points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                    />
                                                                    <circle cx="208" cy="129.5" r="15.5" />
                                                                    <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                </g>
                                                            </svg>
                                                            </span>
                                                            <span class="badge bg-danger align-middle fs-10 text-white ml-1">Required *</span>
                                                        </label>
                                                        <input type="text" placeholder="97780" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label>
                                                            Property ID:
                                                            <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                            <svg
                                                                version="1.1"
                                                                id="Layer_1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                x="0px"
                                                                y="0px"
                                                                width="427px"
                                                                height="427px"
                                                                viewBox="0 0 427 427"
                                                                enable-background="new 0 0 427 427"
                                                                xml:space="preserve"
                                                            >
                                                                <g>
                                                                    <polyline
                                                                        fill="none"
                                                                        stroke="#E89C9D"
                                                                        stroke-width="15"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        stroke-miterlimit="10"
                                                                        points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                    />
                                                                    <circle cx="208" cy="129.5" r="15.5" />
                                                                    <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                </g>
                                                            </svg>
                                                            </span>
                                                            <span class="badge bg-danger align-middle fs-10 text-white ml-1">Required *</span>
                                                        </label>
                                                        <input type="text" placeholder="1214832" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label>
                                                            Request ID:
                                                            <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                            <svg
                                                                version="1.1"
                                                                id="Layer_1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                x="0px"
                                                                y="0px"
                                                                width="427px"
                                                                height="427px"
                                                                viewBox="0 0 427 427"
                                                                enable-background="new 0 0 427 427"
                                                                xml:space="preserve"
                                                            >
                                                                <g>
                                                                    <polyline
                                                                        fill="none"
                                                                        stroke="#E89C9D"
                                                                        stroke-width="15"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        stroke-miterlimit="10"
                                                                        points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                    />
                                                                    <circle cx="208" cy="129.5" r="15.5" />
                                                                    <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                </g>
                                                            </svg>
                                                            </span>
                                                            <span class="badge bg-danger align-middle fs-10 text-white ml-1">Required *</span>
                                                        </label>
                                                        <input type="text" placeholder="15" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label>
                                                            Available Url:
                                                            <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                            <svg
                                                                version="1.1"
                                                                id="Layer_1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                x="0px"
                                                                y="0px"
                                                                width="427px"
                                                                height="427px"
                                                                viewBox="0 0 427 427"
                                                                enable-background="new 0 0 427 427"
                                                                xml:space="preserve"
                                                            >
                                                                <g>
                                                                    <polyline
                                                                        fill="none"
                                                                        stroke="#E89C9D"
                                                                        stroke-width="15"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        stroke-miterlimit="10"
                                                                        points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                    />
                                                                    <circle cx="208" cy="129.5" r="15.5" />
                                                                    <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                </g>
                                                            </svg>
                                                            </span>
                                                            <span class="badge bg-danger align-middle fs-10 text-white ml-1">Required *</span>
                                                        </label>
                                                        <input type="text" placeholder="https://entratasnipit.edisongordonsquare.com/cleveland/edison-at-gordon-square" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label>Lease Now Type:</label>
                                                        <div class="custom_radio">
                                                            <input type="radio" name="size" id="size_13" value="small" checked />
                                                            <label for="size_13">Unit Base</label>
                                                            <input type="radio" name="size" id="size_14" value="small" />
                                                            <label for="size_14">Unit Base</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label>Lease Now Open On Iframe:</label>
                                                        <Checkbox />
                                                    </div>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label>Lease Term Wise Price Info:</label>
                                                        <Checkbox />
                                                    </div>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label>Lease Term Type:</label>
                                                        <div class="custom_radio">
                                                            <input type="radio" name="size" id="size_13" value="small" checked />
                                                            <label for="size_13">Static</label>
                                                            <input type="radio" name="size" id="size_14" value="small" />
                                                            <label for="size_14">Dynamic</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label>Lease Term Wise Price (Tooltip):</label>
                                                        <div class="custom_radio">
                                                            <input type="radio" name="size" id="size_13" value="small" checked />
                                                            <label for="size_13">Informatic Only</label>
                                                            <input type="radio" name="size" id="size_14" value="small" />
                                                            <label for="size_14">Clickable</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label>If Clickable Lease Now Page Open In :</label>
                                                        <div class="custom_radio">
                                                            <input type="radio" name="size" id="size_13" value="small" checked />
                                                            <label for="size_13">Same Page</label>
                                                            <input type="radio" name="size" id="size_14" value="small" />
                                                            <label for="size_14">New Page</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="update_bottom">
                <ul>
                    <li><span>*</span> Press update button to save this changes</li>
                    <li>
                        <router-link to="/"><button class="update_btn">Update</button></router-link>
                    </li>
                </ul>
            </div>
        </div>
        <!-- <LeftPreviewPanel/> -->
    </div>
</template>

<script>
    import LeftPreviewPanel from "@/views/LeftPreviewPanel.vue";
    import Checkbox from "@/components/Checkbox.vue";
    import Multiselect from "@vueform/multiselect";

    export default {
        components: {
            LeftPreviewPanel,
            Checkbox,
            Multiselect,
        },
        data() {
            return {
                //Lease Term Month
                LeaseTermMonth: {
                    mode: "single",
                    value: "12 Months",
                    options: [
                        { value: "2 Months", label: "2 Months" },
                        { value: "3 Months ", label: "3 Months" },
                        { value: "4 Months ", label: "4 Months " },
                        { value: "5 Months ", label: "5 Months " },
                        { value: "5 Months ", label: "6 Months " },
                    ],
                    searchable: false,
                    createTag: false,
                },
            };
        },
    };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
