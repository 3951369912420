<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="general_configuration">
                    <div class="general_configuration_details">
                        <div class="top_pannel">
                            <div class="heading">
                                <h3>Cron Setup</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                                <div class="form-group">
                                                    <label>Cron Enable</label>
                                                    <div class="check-box"><input type="checkbox"></div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                                <div class="form-group">
                                                    <label>Cron Run Per/Day (No of time):</label>
                                                    <div class="select_fld">
                                                        <Multiselect v-model="CornRun.value" v-bind="CornRun" placeholder="Select" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                                <div class="form-group">
                                                    <label class="d-flex flex-wrap align-items-center">Cron Run With Content Script: <span class="small text-danger ml-auto">Required *</span></label>
                                                    <div class="check-box"><input type="checkbox"></div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                                <div class="form-group">
                                                    <label>Cron Run With Content Script:</label>
                                                    <input type="text"/>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                                <div class="form-group">
                                                    <label>FloorPlan Auo Sync Toggle:</label>
                                                    <div class="check-box"><input type="checkbox"></div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                                <div class="form-group">
                                                    <label>Set FloorPlan Auo Sync (in days):</label>
                                                    <div class="select_fld">
                                                        <Multiselect v-model="FloorpalnSync.value" v-bind="FloorpalnSync" placeholder="Select" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="update_bottom">
                    <ul>
                        <li><span>*</span> Press update button to save this changes</li>
                        <li>
                            <router-link to="/"><button class="update_btn">Update</button></router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

          
<script>
    import Multiselect from '@vueform/multiselect'; 
    export default {
        components: {
            Multiselect,
        },
        data() {
            return {
                CornStatus: {
                    mode: 'single',
                    value: 'Disable',
                    options: [
                        { value: 'Disable', label: 'Disable' },
                        { value: 'Enable', label: 'Enable' }
                    ],
                    searchable: false,
                    createTag: false
                },
                CornRun: {
                    mode: 'single',
                    value: 'Select',
                    options: [
                        { value: 'Once Per Day', label: 'Once Per Day' },
                        { value: 'Twice Per Day', label: 'Twice Per Day' },
                        { value: 'Once Every Six Hours', label: 'Once Every Six Hours' },
                        { value: 'Once Every Two Hours', label: 'Once Every Two Hours' },
                        { value: 'Once Per Hour', label: 'Once Per Hour' }
                    ],
                    searchable: false,
                    createTag: false
                },
                FloorpalnSync: {
                    mode: 'single',
                    value: 'Select',
                    options: [
                        { value: '1', label: '1' },
                        { value: '7', label: '7' },
                        { value: '15', label: '15' },
                        { value: '30', label: '30' },
                    ],
                    searchable: false,
                    createTag: false
                },

            };
        },
    };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>