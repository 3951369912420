<template>
    <div class="container-fluid crondetails">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="general_configuration">
                    <div class="general_configuration_details">
                        <div class="top_pannel">
                            <div class="heading">
                                <h3>Cron Details</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-header align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">Single Cron Run URL</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div class="clipboard">
                                                    <div class="clipboard_content" @click.stop.prevent="copyTestingCode">
                                                        {{ testingCode }}
                                                        <input type="hidden" id="testing-code" :value="testingCode">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-header align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">30 Days of Cron Log Showing</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div class="dataTable">
                                                    <div class="dataTable_top">
                                                        <ul>
                                                            <li>
                                                                <div class="list_length"><Multiselect v-model="list.value" v-bind="list" placeholder="5" /></div>
                                                            </li>
                                                            <li>
                                                                <div class="dataTables_length">Showing 1 to 10 of <span>1,087</span></div>
                                                            </li>
                                                            <li>
                                                                <div class="search">
                                                                    <input type="text" placeholder="Search Here...." />
                                                                    <div class="search_icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                            <path
                                                                                d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"
                                                                            />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="date_filter">
                                                                    <div class="from_date">
                                                                        <Datepicker v-model="date" calendar-cell-class-name="dp-custom-cell" :enable-time-picker="false" placeholder="From Date" />
                                                                    </div>
                                                                    <div class="to_date">
                                                                        <Datepicker v-model="date" calendar-cell-class-name="dp-custom-cell" :enable-time-picker="false" placeholder="To Date" />
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="table-responsive">
                                                        <table class="table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Sl No.</th>
                                                                    <th scope="col">Cron Status</th>
                                                                    <th scope="col">Cron Response</th>
                                                                    <th scope="col">Date</th>
                                                                    <th scope="col">Time</th>
                                                                    <th scope="col">Statement</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="items in apartmentsyncsitelist" :key="items">
                                                                    <td><span class="res-label">Sl No:</span>{{items.Slno}}</td>
                                                                    <td><span class="res-label">Cron Status:</span>{{items.CronStatus}}</td>
                                                                    <td><span class="res-label">Cron Response:</span>{{items.CronResponse}}</td>
                                                                    <td><span class="res-label">Date:</span>{{items.Date}}</td>
                                                                    <td><span class="res-label">Time:</span>{{items.Time}}</td>
                                                                    <td><span class="res-label">Statement:</span>{{items.Statement}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <nav class="data-pagination" data-pagination>
                                                        <a a href="#" disabled>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                                <path
                                                                    d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
                                                                />
                                                            </svg>
                                                        </a>
                                                        <ul>
                                                            <li><a href="#">1</a></li>
                                                            <li><a href="#">2</a></li>
                                                            <li><a href="#">3</a></li>
                                                            <li><a href="#">4</a></li>
                                                            <li class="current"><a a href="#">5</a></li>
                                                            <li><a a href="#">…</a></li>
                                                            <li><a a href="#">55</a></li>
                                                        </ul>
                                                        <a a href="#">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                                <path
                                                                    d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
                                                                />
                                                            </svg>
                                                        </a>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from "@vueform/multiselect";
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';

    export default {
        components: {
            Multiselect,
            Datepicker
        },
        data() {
            return {
                //Company Dropdown
                list: {
                    mode: "single",
                    value: "5",
                    options: [
                        { value: "10", label: "10" },
                        { value: "25", label: "25" },
                        { value: "50", label: "50" },
                        { value: "100", label: "100" },
                    ],
                    searchable: false,
                    createTag: false,
                },
                apartmentsyncsitelist: [
                    {
                        Slno: "1",
                        CronStatus: "End",
                        CronResponse: "Yes",
                        Date: "2023-02-13",
                        Time: "09:19:19 PM",
                        Statement: "Edison at Gordon Square LP cron End Time Monday 13th of February 2023 09:19:19 PM",
                    },
                    {
                        Slno: "2",
                        CronStatus: "Start",
                        CronResponse: "Yes",
                        Date: "2023-02-13",
                        Time: "07:04:39 PM",
                        Statement: "Edison at Gordon Square LP cron Start Time Monday 13th of February 2023 07:04:39 PM",
                    },
                ],
                date: null,
                testingCode: "https://app.repli360.com/admin/rrac-cron?site_id=eyJpdiI6IklDV1hxeFpxc0I3Ty9mSDNUcnZBcHc9PSIsInZhbHVlIjoiM0V0SWhETEJRUEMrb3NoaWM1YXhIUT09IiwibWFjIjoiYjk5MmY1YTFiOGYwMDFjNDg5MzJhNDMxNjg2YWU5ZjVjZjI3ODIyOTFmYjc0YmU5MmM0MTM1ZTQ3YTY3ZmM0NyJ9&manual=yes",
            };
        },
        methods: {
            //Clipboard
            copyTestingCode () {
                let testingCodeToCopy = document.querySelector('#testing-code')
                testingCodeToCopy.setAttribute('type', 'text')
                testingCodeToCopy.select()

                try {
                    var successful = document.execCommand('copy');
                    var msg = successful ? 'Successful' : 'Unsuccessful';
                    alert('Code Copied ' + msg);
                } catch (err) {
                    alert('Oops, unable to copy');
                }

                /* unselect the range */
                testingCodeToCopy.setAttribute('type', 'hidden')
                window.getSelection().removeAllRanges()
            },

            //Action btn panle
            openAction_btn: function () {
                const x = document.getElementById("action_btn_panl");
                if (x.classList.contains("action_btn_panle_active")) {
                    x.classList.remove("action_btn_panle_active");
                } else {
                    x.classList.add("action_btn_panle_active");
                }
            },
        },
    };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
