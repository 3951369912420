<template>
    <div class="container-fluid d-flex">
        <div class="general_configuration">
            <div class="general_configuration_details">
                <div class="top_pannel">
                    <div class="heading">
                        <h3>API User Guide</h3>
                    </div>
                    <div class="check-box"><label>Show Preview</label><input type="checkbox" v-model="has_preview"/></div>
                </div>
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">Listing Page code</h4>
                                <span class="badge bg-danger align-middle fs-10 text-white ml-1">Please copy and paste *</span>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="clipboard">
                                                    <pre id="rrac_listing_select">&lt;div class="rrac_script"&gt;&lt;/div&gt;</pre>
                                                    <div class="clipboard_path"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">Details Page code</h4>
                                <span class="badge bg-danger align-middle fs-10 text-white ml-1">Please copy and paste *</span>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="clipboard">
                                                    <pre id="rrac_details_select">&lt;div class="rrac_floorplanDetails"&gt;&lt;/div&gt;</pre>
                                                    <div class="clipboard_path"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">Floor Plan Grid View code</h4>
                                <span class="badge bg-danger align-middle fs-10 text-white ml-1">Please copy and paste *</span>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div class="clipboard">
                                                    <pre id="rrac_grid_view_select">&lt;div class="rrac_script_grid_view"&gt;&lt;/div&gt;</pre>
                                                    <div class="clipboard_path"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">Min Price</h4>
                                <span class="badge bg-danger align-middle fs-10 text-white ml-1">Please copy and paste *</span>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div class="clipboard">
                                                    <pre id="rrac_min_price_select" onclick="rrac_selectText('rrac_min_price_select');">&lt;span class="rrac_min_unit_price" data-bedroom="1/2/3/0" data-floorplan="floorplan_id"&gt;&lt;/span&gt;</pre>
                                                    <div class="clipboard_path"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">Floorplan SQFT</h4>
                                <span class="badge bg-danger align-middle fs-10 text-white ml-1">Please copy and paste *</span>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div class="clipboard">
                                                    <pre id="rrac_sqft_select" onclick="rrac_selectText('rrac_sqft_select');">&lt;span class="rrac_sqft_access" data-bedroom="1/2/3/0" data-range="1/0"&gt;&lt;/span&gt;</pre>
                                                    <div class="clipboard_path"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">Amenities Page Code</h4>
                                <span class="badge bg-danger align-middle fs-10 text-white ml-1">Please copy and paste *</span>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div class="clipboard">
                                                    <pre id="rrac_amenities_select" onclick="rrac_selectText('rrac_amenities_select');">&lt;div class="rrac_amenities_view"&gt;&lt;/div&gt;</pre>
                                                    <div class="clipboard_path"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">Amenities Page Code</h4>
                                <span class="badge bg-danger align-middle fs-10 text-white ml-1">Please copy and paste *</span>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div class="clipboard">
                                                    <pre readonly="true" rows="4" cols="50" id="rrac_duda_popup_select" onclick="rrac_selectText('rrac_duda_popup_select');">&lt;style type="text/css"&gt;.rrac-loader{margin: 0 auto;border:6px solid #f3f3f3;border-radius:50%;border-top:6px solid #3498db;width:50px;height:50px;-webkit-animation:spin 2s linear infinite;animation:spin 2s linear infinite}@-webkit-keyframes spin{0%{-webkit-transform:rotate(0deg)}100%{-webkit-transform:rotate(360deg)}}@keyframes    spin{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}&lt;/style&gt;&lt;div class="rrac-loader"&gt;&lt;/div&gt;&lt;div class="rrac_script"&gt;&lt;/div&gt;&lt;script type="text/javascript"&gt;$.getScript("https://phpstack-431065-1410338.cloudwaysapps.com/admin/rrac-website-script/eyJpdiI6Imk4TUkwbG15bXBFUUorWTNlOFFWV3c9PSIsInZhbHVlIjoiU3pNeHYvelcydXFBWVg3YUhmSS9iZz09IiwibWFjIjoiNDM5ODBjMjkyODlhNmRkMDQzOWM0N2NmOGJlM2I0NGIxODI2ZDNmNmRlNGNhMGUxNzkxYmYzMTU2NjI2NmFhNyJ9?action=popUp").done(function(data, textStatus, jqxhr) {console.log(textStatus);}).fail(function(jqxhr, settings, exception) {console.log("error");});&lt;/script&gt;</pre>
                                                    <div class="clipboard_path"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">Interactive Leasing Page Code</h4>
                                <span class="badge bg-danger align-middle fs-10 text-white ml-1">Please copy and paste *</span>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div class="clipboard">
                                                    <pre id="rrac_interactive_leasing_select" onclick="rrac_selectText('rrac_interactive_leasing_select');">&lt;div class="rrac_interactive_leasing_view"&gt;&lt;/div&gt;</pre>
                                                    <div class="clipboard_path"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">Footer area code(Without Jquery Library)</h4>
                                <span class="badge bg-danger align-middle fs-10 text-white ml-1">Please copy and paste *</span>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div class="clipboard">
                                                    <pre id="rrac_script_select" onclick="rrac_selectText('rrac_script_select');">&lt;script type="text/javascript" src="https://phpstack-431065-1410338.cloudwaysapps.com/admin/rrac-website-script/eyJpdiI6IkFtU3FtMWRxVVV6Y0hYREE5Z1E5TlE9PSIsInZhbHVlIjoiSWhHOG1KUmNUQ29iY2tHTE1kaGNHZz09IiwibWFjIjoiNDliMGI0NTkwYjBiMWU3MmQzZmYxN2RjYWMxYzEzZWY4ODM0NzZjNjAwZDNmNzE3NzgzN2QwY2JjZjQ0Njk0NyJ9"&gt;&lt;/script&gt;</pre>
                                                    <div class="clipboard_path"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">Footer area code(With Jquery Library)</h4>
                                <span class="badge bg-danger align-middle fs-10 text-white ml-1">Please copy and paste *</span>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div class="clipboard">
                                                    <pre id="rrac_script_select_with_jquery" onclick="rrac_selectText('rrac_script_select_with_jquery');">&lt;script type="text/javascript" src="https://code.jquery.com/jquery-1.11.3.min.js"&gt;&lt;/script&gt;&lt;script type="text/javascript" src="https://phpstack-431065-1410338.cloudwaysapps.com/admin/rrac-website-script/eyJpdiI6IlF2Sk10MWVVdk9BOVBkV1RwTnVQQ0E9PSIsInZhbHVlIjoieFA5N3I1REhsL0NkakI4UUpRUDdvZz09IiwibWFjIjoiOWI2NWZjZWU5M2VjZTdkZWVmYzMxZWU2YWYzM2IzNjI1ZGJiNjQzNTIyMmUzMjJjOTlkNzU1NTE3OGE4Mzg3MyJ9"&gt;&lt;/script&gt;</pre>
                                                    <div class="clipboard_path"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="update_bottom">
                <ul>
                    <li><span>*</span> Press update button to save this changes</li>
                    <li>
                        <router-link to="/"><button class="update_btn">Update</button></router-link>
                    </li>
                </ul>
            </div>
        </div>
        <LeftPreviewPanel v-model="has_preview"/>
    </div>
</template>

          
<script>
    import LeftPreviewPanel from "@/views/LeftPreviewPanel.vue"

    export default {
        components:{
            LeftPreviewPanel
        },
        data() {
            return {
                has_preview: false,
            };
        },
    };
</script>