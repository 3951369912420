<template>
   <div class="app-sidebar h-100">
    <nav class="first-sidemenu">
        <div class="toggle-menu" @click="openNav()" id="toggle-menu">
            <div class="toggle_barsec" >
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
        <ul>
            <li class="tooltip_menu">
                <router-link to="/" :class="{ 'active' : $route.meta.title == 'Dashboard' }"><svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M3 5C3 3.89543 3.89543 3 5 3H9C10.1046 3 11 3.89543 11 5V9C11 10.1046 10.1046 11 9 11H5C3.89543 11 3 10.1046 3 9V5ZM9 5H5V9H9V5Z"/><path clip-rule="evenodd" d="M3 15C3 13.8954 3.89543 13 5 13H9C10.1046 13 11 13.8954 11 15V19C11 20.1046 10.1046 21 9 21H5C3.89543 21 3 20.1046 3 19V15ZM9 15H5V19H9V15Z"/><path clip-rule="evenodd" d="M13 5C13 3.89543 13.8954 3 15 3H19C20.1046 3 21 3.89543 21 5V9C21 10.1046 20.1046 11 19 11H15C13.8954 11 13 10.1046 13 9V5ZM19 5H15V9H19V5Z"/><path clip-rule="evenodd" d="M13 15C13 13.8954 13.8954 13 15 13H19C20.1046 13 21 13.8954 21 15V19C21 20.1046 20.1046 21 19 21H15C13.8954 21 13 20.1046 13 19V15ZM19 15H15V19H19V15Z"/></svg></router-link>
                <span class="tooltiptext">Dashboard</span>
            </li>
            <li class="tooltip_menu">
                <router-link to="/repliapartmentsyncs/apartmentsyncsitelist" :class="{ 'active' : $route.meta.title == 'Apartment Sync' }"><svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 5" id="Layer_5"><path d="M21.84,39.46a.51.51,0,0,0-.7,0L16.5,44.11V16.5H32a.5.5,0,0,0,.5-.5V12.19a.5.5,0,0,0-.5-.5H12.19a.5.5,0,0,0-.5.5V44.11L7,39.46a.52.52,0,0,0-.36-.14.5.5,0,0,0-.35.14l-2.68,2.7a.48.48,0,0,0,0,.7L13.74,53a.52.52,0,0,0,.35.14.54.54,0,0,0,.36-.14l10.09-10.1a.5.5,0,0,0,0-.7Z"/><path d="M60.35,21.14,50.26,11a.51.51,0,0,0-.71,0L39.46,21.14a.5.5,0,0,0,0,.7l2.7,2.7a.51.51,0,0,0,.7,0l4.64-4.65V47.5H32a.5.5,0,0,0-.5.5v3.81a.5.5,0,0,0,.5.5H51.81a.5.5,0,0,0,.5-.5V19.89L57,24.54a.52.52,0,0,0,.36.14.5.5,0,0,0,.35-.14l2.68-2.7A.48.48,0,0,0,60.35,21.14Z"/></g></svg></router-link>
                <span class="tooltiptext"> Apartment Sync </span>
            </li>
            <li class="tooltip_menu">
                <router-link to="/companymanagement/companies" :class="{ 'active' : $route.meta.title == 'Company Management' }"><svg data-name="Layer 1" height="32" id="Layer_1" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg"><defs></defs><path d="M18,23H16V21a3.0033,3.0033,0,0,0-3-3H9a3.0033,3.0033,0,0,0-3,3v2H4V21a5.0059,5.0059,0,0,1,5-5h4a5.0059,5.0059,0,0,1,5,5Z" transform="translate(0 0)"/><path d="M11,6A3,3,0,1,1,8,9a3,3,0,0,1,3-3m0-2a5,5,0,1,0,5,5A5,5,0,0,0,11,4Z" transform="translate(0 0)"/><rect height="2" width="28" x="2" y="26.0001"/><polygon points="22 4 22 6 26.586 6 20 12.586 21.414 14 28 7.414 28 12 30 12 30 4 22 4"/><rect class="cls-1" data-name="&lt;Transparent Rectangle&gt;" height="32" id="_Transparent_Rectangle_" width="32"/></svg></router-link>
                <span class="tooltiptext">Company Management </span>
            </li>
            <li class="tooltip_menu">
                <router-link to="/prospectguide/pospects" :class="{ 'active' : $route.meta.title == 'Prospect Guide' }"><svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M19 2.01H6c-1.206 0-3 .799-3 3v14c0 2.201 1.794 3 3 3h15v-2H6.012C5.55 19.998 5 19.815 5 19.01c0-.101.009-.191.024-.273.112-.575.583-.717.987-.727H20c.018 0 .031-.009.049-.01H21V4.01c0-1.103-.897-2-2-2zm0 14H5v-11c0-.806.55-.988 1-1h7v7l2-1 2 1v-7h2v12z"/></svg></router-link>
                <span class="tooltiptext">Prospect Guide </span>
            </li>
            <li class="tooltip_menu">
                <router-link to="/"><svg fill="#fff" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M1.25 6.5C1.25 3.60051 3.6005 1.25 6.5 1.25H17.5C20.3995 1.25 22.75 3.6005 22.75 6.5V13C22.75 13.4142 22.4142 13.75 22 13.75C21.5858 13.75 21.25 13.4142 21.25 13V6.5C21.25 4.42893 19.5711 2.75 17.5 2.75H6.5C4.42893 2.75 2.75 4.42893 2.75 6.5V17.5C2.75 19.5711 4.42893 21.25 6.5 21.25H17.5C19.5711 21.25 21.25 19.5711 21.25 17.5V17C21.25 16.5858 21.5858 16.25 22 16.25C22.4142 16.25 22.75 16.5858 22.75 17V17.5C22.75 20.3995 20.3995 22.75 17.5 22.75H6.5C3.60051 22.75 1.25 20.3995 1.25 17.5V6.5Z" fill="#fff" fill-rule="evenodd"/><path clip-rule="evenodd" d="M6.80709 6.21299C6.92318 5.93273 7.19665 5.75 7.5 5.75H17.5C17.9142 5.75 18.25 6.08579 18.25 6.5V16.5C18.25 16.8033 18.0673 17.0768 17.787 17.1929C17.5068 17.309 17.1842 17.2448 16.9697 17.0303L14.5 14.5607L11.0303 18.0303C10.7374 18.3232 10.2626 18.3232 9.96967 18.0303L5.96967 14.0303C5.67678 13.7374 5.67678 13.2626 5.96967 12.9697L9.43934 9.5L6.96967 7.03033C6.75517 6.81583 6.691 6.49324 6.80709 6.21299ZM9.31066 7.25L11.0303 8.96967C11.3232 9.26256 11.3232 9.73744 11.0303 10.0303L7.56066 13.5L10.5 16.4393L13.9697 12.9697C14.2626 12.6768 14.7374 12.6768 15.0303 12.9697L16.75 14.6893V7.25H9.31066Z" fill="#fff" fill-rule="evenodd"/><path clip-rule="evenodd" d="M8.03033 5.96967C8.32322 6.26256 8.32322 6.73744 8.03033 7.03033L2.53033 12.5303C2.23744 12.8232 1.76256 12.8232 1.46967 12.5303C1.17678 12.2374 1.17678 11.7626 1.46967 11.4697L6.96967 5.96967C7.26256 5.67678 7.73744 5.67678 8.03033 5.96967ZM18.0303 15.9697C18.3232 16.2626 18.3232 16.7374 18.0303 17.0303L12.5303 22.5303C12.2374 22.8232 11.7626 22.8232 11.4697 22.5303C11.1768 22.2374 11.1768 21.7626 11.4697 21.4697L16.9697 15.9697C17.2626 15.6768 17.7374 15.6768 18.0303 15.9697Z" fill="#fff" fill-rule="evenodd"/></svg></router-link>
                <span class="tooltiptext">Media </span>
            </li>
            <li class="tooltip_menu">
                <router-link to="/"><svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><rect fill="none" height="256" width="256"/><line fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" x1="40" x2="216" y1="112" y2="112"/><path d="M102.8,152H56a16,16,0,0,1-16-16V64A32,32,0,0,1,72,32H216V136a16,16,0,0,1-16,16H153.2a8,8,0,0,0-7.9,9.1L152,208a24,24,0,0,1-48,0l6.7-46.9A8,8,0,0,0,102.8,152Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/><line fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" x1="184" x2="184" y1="32" y2="80"/></svg></router-link>
                <span class="tooltiptext">Appearance </span>
            </li>
            <li class="tooltip_menu">
                <router-link to="/"><svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><title/><g data-name="Layer 3" id="Layer_3"><path d="M29.59,9.91,21.41,2.26A.93.93,0,0,0,20.69,2a1,1,0,0,0-.7.31L16.08,6.48l-.91-.84a1,1,0,0,0-1.41,0l-7.85,8.4a5.16,5.16,0,0,0-.86,5.84L2.43,22.71a1,1,0,0,0,0,1.42L9,30.25a1,1,0,0,0,.68.27h0a1,1,0,0,0,.7-.31l2.62-2.8a5.17,5.17,0,0,0,5.77-1.24l7.85-8.4a1,1,0,0,0,.27-.72,1,1,0,0,0-.32-.69l-.9-.85,3.91-4.18A1,1,0,0,0,29.59,9.91ZM9.66,28.11,4.58,23.35l1.69-1.81,5.09,4.76Zm7.71-3.31a3.2,3.2,0,0,1-4.52.15h0L7.52,20a3.2,3.2,0,0,1-.15-4.52l7.17-7.66,1.8,1.69,7.29,6.82h0l.91.84Zm6.9-10.66L17.54,7.85,20.77,4.4l6.73,6.29Z"/><path d="M19.77,7.47a1,1,0,0,0,.05,1.41,1,1,0,0,0,.68.27,1,1,0,0,0,.73-.32L22,8a1,1,0,0,0-1.47-1.36Z"/><path d="M23.75,12.24a1,1,0,0,0,.73-.32l.76-.81a1,1,0,1,0-1.46-1.37l-.76.82a1,1,0,0,0,0,1.41A1,1,0,0,0,23.75,12.24Z"/></g></svg></router-link>
                <span class="tooltiptext">Plugins</span>
            </li>
            <li class="tooltip_menu">
                <router-link to="/"><svg height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><title/><path d="M262.29,192.31a64,64,0,1,0,57.4,57.4A64.13,64.13,0,0,0,262.29,192.31ZM416.39,256a154.34,154.34,0,0,1-1.53,20.79l45.21,35.46A10.81,10.81,0,0,1,462.52,326l-42.77,74a10.81,10.81,0,0,1-13.14,4.59l-44.9-18.08a16.11,16.11,0,0,0-15.17,1.75A164.48,164.48,0,0,1,325,400.8a15.94,15.94,0,0,0-8.82,12.14l-6.73,47.89A11.08,11.08,0,0,1,298.77,470H213.23a11.11,11.11,0,0,1-10.69-8.87l-6.72-47.82a16.07,16.07,0,0,0-9-12.22,155.3,155.3,0,0,1-21.46-12.57,16,16,0,0,0-15.11-1.71l-44.89,18.07a10.81,10.81,0,0,1-13.14-4.58l-42.77-74a10.8,10.8,0,0,1,2.45-13.75l38.21-30a16.05,16.05,0,0,0,6-14.08c-.36-4.17-.58-8.33-.58-12.5s.21-8.27.58-12.35a16,16,0,0,0-6.07-13.94l-38.19-30A10.81,10.81,0,0,1,49.48,186l42.77-74a10.81,10.81,0,0,1,13.14-4.59l44.9,18.08a16.11,16.11,0,0,0,15.17-1.75A164.48,164.48,0,0,1,187,111.2a15.94,15.94,0,0,0,8.82-12.14l6.73-47.89A11.08,11.08,0,0,1,213.23,42h85.54a11.11,11.11,0,0,1,10.69,8.87l6.72,47.82a16.07,16.07,0,0,0,9,12.22,155.3,155.3,0,0,1,21.46,12.57,16,16,0,0,0,15.11,1.71l44.89-18.07a10.81,10.81,0,0,1,13.14,4.58l42.77,74a10.8,10.8,0,0,1-2.45,13.75l-38.21,30a16.05,16.05,0,0,0-6.05,14.08C416.17,247.67,416.39,251.83,416.39,256Z" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/></svg></router-link>
                <span class="tooltiptext">Settings</span>
            </li>
            <li class="tooltip_menu">
                <router-link to="/"><svg height="32" id="icon" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg"><defs></defs><path d="M21,22H19V20a1.0011,1.0011,0,0,0-1-1H14a1.0011,1.0011,0,0,0-1,1v2H11V20a3.0033,3.0033,0,0,1,3-3h4a3.0033,3.0033,0,0,1,3,3Z" transform="translate(0 0)"/><path d="M16,16a3.5,3.5,0,1,1,3.5-3.5A3.5041,3.5041,0,0,1,16,16Zm0-5a1.5,1.5,0,1,0,1.5,1.5A1.5017,1.5017,0,0,0,16,11Z" transform="translate(0 0)"/><path d="M30.4141,17.4141a1.9995,1.9995,0,0,0,0-2.8282L24.6272,8.7993l2.9006-2.8628a2.0018,2.0018,0,1,0-1.4416-1.3872L23.2129,7.3848,17.4141,1.5859a1.9995,1.9995,0,0,0-2.8282,0L8.7993,7.3726,5.9368,4.4717A2.002,2.002,0,1,0,4.55,5.9136l2.835,2.8735L1.5859,14.5859a1.9995,1.9995,0,0,0,0,2.8282l5.7989,5.7988L4.55,26.0864a1.9977,1.9977,0,1,0,1.387,1.4419l2.8625-2.9009,5.7866,5.7867a1.9995,1.9995,0,0,0,2.8282,0l5.7988-5.7989,2.8733,2.8355a1.998,1.998,0,1,0,1.4416-1.3872l-2.9006-2.8628ZM16,29,3,16,16,3,29,16Z" transform="translate(0 0)"/><rect class="cls-1" data-name="&lt;Transparent Rectangle&gt;" height="32" id="_Transparent_Rectangle_" width="32"/></svg></router-link>
                <span class="tooltiptext">Platform Administration </span>
            </li>         
        </ul>
    </nav>
    </div>  
</template>
    

<script>
    export default {
        name:"Sidebar",
        components: {
        },

        data() {
            return {
                isToggleBtn : false,
            }
        },
        
        methods:{
            //Second Sidemenu
            openNav() {
                const bar = document.getElementById('mySidenav');
                const pnl = document.getElementById('synchronize');
                const btn = document.getElementById('toggle-menu');
                

                if(bar.classList.contains('second-sidemenu--active')){
                    bar.classList.remove('second-sidemenu--active');
                    pnl.classList.remove('main_content_synchronize');
                    btn.classList.remove('active');
                }else{
                    bar.classList.add('second-sidemenu--active');
                    pnl.classList.add('main_content_synchronize');
                    btn.classList.add('active');
                }
            }   
        }  
    }
</script>