<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="general_configuration">
                    <div class="general_configuration_details">
                        <div class="top_pannel">
                            <div class="heading">
                                <h3>Custom Script</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-body">
                                        <div class="row">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <textarea />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="update_bottom">
                    <ul>
                        <li><span>*</span> Press update button to save this changes</li>
                        <li>
                            <router-link to="/"><button class="update_btn">Update</button></router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

          
<script>
    export default {
        components: {
        },
        data() {
            return {

            };
        },
    };
</script>