<template>
    <div class="container-fluid site_configuration">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="grid_list">
                    <router-link to="/" class="grid-icon"><span class="icon"><svg height="512px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g><path d="M160,153.3c0,3.7-3,6.7-6.7,6.7h-50.5c-3.7,0-6.7-3-6.7-6.7v-50.5c0-3.7,3-6.7,6.7-6.7h50.5c3.7,0,6.7,3,6.7,6.7V153.3z"/><path d="M288,153.3c0,3.7-3,6.7-6.7,6.7h-50.5c-3.7,0-6.7-3-6.7-6.7v-50.5c0-3.7,3-6.7,6.7-6.7h50.5c3.7,0,6.7,3,6.7,6.7V153.3z"/><path d="M416,153.3c0,3.7-3,6.7-6.7,6.7h-50.5c-3.7,0-6.7-3-6.7-6.7v-50.5c0-3.7,3-6.7,6.7-6.7h50.5c3.7,0,6.7,3,6.7,6.7V153.3z"/></g><g><path d="M160,281.3c0,3.7-3,6.7-6.7,6.7h-50.5c-3.7,0-6.7-3-6.7-6.7v-50.5c0-3.7,3-6.7,6.7-6.7h50.5c3.7,0,6.7,3,6.7,6.7V281.3z"/><path d="M288,281.3c0,3.7-3,6.7-6.7,6.7h-50.5c-3.7,0-6.7-3-6.7-6.7v-50.5c0-3.7,3-6.7,6.7-6.7h50.5c3.7,0,6.7,3,6.7,6.7V281.3z"/><path d="M416,281.3c0,3.7-3,6.7-6.7,6.7h-50.5c-3.7,0-6.7-3-6.7-6.7v-50.5c0-3.7,3-6.7,6.7-6.7h50.5c3.7,0,6.7,3,6.7,6.7V281.3z"/></g><g><path d="M160,409.3c0,3.7-3,6.7-6.7,6.7h-50.5c-3.7,0-6.7-3-6.7-6.7v-50.5c0-3.7,3-6.7,6.7-6.7h50.5c3.7,0,6.7,3,6.7,6.7V409.3z"/><path d="M288,409.3c0,3.7-3,6.7-6.7,6.7h-50.5c-3.7,0-6.7-3-6.7-6.7v-50.5c0-3.7,3-6.7,6.7-6.7h50.5c3.7,0,6.7,3,6.7,6.7V409.3z"/><path d="M416,409.3c0,3.7-3,6.7-6.7,6.7h-50.5c-3.7,0-6.7-3-6.7-6.7v-50.5c0-3.7,3-6.7,6.7-6.7h50.5c3.7,0,6.7,3,6.7,6.7V409.3z"/></g></g></svg></span></router-link>
                    <router-link to="/" class="list-icon"><span class="icon"><svg enable-background="new 0 0 32 32" id="Editable-line" version="1.1" viewBox="0 0 32 32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><circle cx="5" cy="6" fill="none" id="XMLID_303_" r="1" stroke="#c3c1c1" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/><circle cx="5" cy="16" fill="none" id="XMLID_305_" r="1" stroke="#c3c1c1" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/><circle cx="5" cy="26" fill="none" id="XMLID_304_" r="1" stroke="#c3c1c1" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/><line fill="none" id="XMLID_29_" stroke="#c3c1c1" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" x1="10" x2="28" y1="6" y2="6"/><line fill="none" id="XMLID_30_" stroke="#c3c1c1" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" x1="10" x2="28" y1="16" y2="16"/><line fill="none" id="XMLID_31_" stroke="#c3c1c1" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" x1="10" x2="28" y1="26" y2="26"/></svg></span></router-link>
                </div>
            </div>
        </div>
        <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <ul>
                <li v-for="items in siteconfigurationlist" :key="items">
                    <router-link :to="items.url">
                    <div class="items">
                        <div class="half-circle" v-bind:style='{backgroundColor: items.halfcircle}'></div>                           
                        <h5>{{items.MainHeading}}</h5>
                        <h6>{{items.Subheading}}</h6>
                    </div>
                    </router-link>
                </li>
            </ul>
        </div>
        </div>
    </div>
</template>
    
<script>
    export default {
        components: {
        }, 
        data () {
            return {
                siteconfigurationlist:[
                    {
                        url:'/repliapartmentsyncs/general-configuration',
                        MainHeading:'General',
                        Subheading:'Configuration',
                        halfcircle:'#363c95'
                    },
                    {
                        url:'/repliapartmentsyncs/template-configuration',
                        MainHeading:'Template',
                        Subheading:'Configuration',
                        halfcircle:'#601f79'
                    },
                    {
                        url:'/repliapartmentsyncs/floorPlan-details',
                        MainHeading:'Floor Plan Details',
                        Subheading:'Template Configuration',
                        halfcircle:'#ae5e9a'
                    },
                    {
                        url:'/repliapartmentsyncs/floorPlan-sorting',
                        MainHeading:'Floor Plan',
                        Subheading:'Sorting',
                        halfcircle:'#df4a71'
                    },
                    {
                        url:'/repliapartmentsyncs/penthouse-urban-studio-configuration',
                        MainHeading:'Penthouse / Urban Studio',
                        Subheading:'Configuration',
                        halfcircle:'#ef8088'
                    },
                    {
                        url:'/repliapartmentsyncs/price-configuration',
                        MainHeading:'Price',
                        Subheading:'Configuration',
                        halfcircle:'#efa280'
                    },
                    {
                        url:'/repliapartmentsyncs/deposit-price-configuration',
                        MainHeading:'Deposit Price',
                        Subheading:'Configuration (Unit Wise)',
                        halfcircle:'#efa280'
                    },
                    {
                        url:'/repliapartmentsyncs/call-for-pricing-manual-configuration',
                        MainHeading:'Call for Pricing Manual',
                        Subheading:'Configuration ( Price Independent )',
                        halfcircle:'#888888'
                    },
                    {
                        url:'/repliapartmentsyncs/call-for-pricing-configuration',
                        MainHeading:'Call for Pricing',
                        Subheading:'configuration ( Price Dependent - when price is 0 )',
                        halfcircle:'#737731'
                    },
                    {
                        url:'/repliapartmentsyncs/leasing-configuration',
                        MainHeading:' Leasing',
                        Subheading:'Configuration',
                        halfcircle:'#a77e0e'
                    },
                    {
                        url:'/repliapartmentsyncs/all-popUp-configuration',
                        MainHeading:'All Pop Up',
                        Subheading:'Configuration',
                        halfcircle:'#f0b618'
                    },
                    {
                        url:'/repliapartmentsyncs/join-our-waitlist-section',
                        MainHeading:'Join Our Waitlist Section:',
                        Subheading:'No Availibility',
                        halfcircle:'#ffda0d'
                    },
                    {
                        url:'/repliapartmentsyncs/availibility-tab-section',
                        MainHeading:'Availibility',
                        Subheading:'Tab Section',
                        halfcircle:'#ef8088'
                    },
                    {
                        url:'/repliapartmentsyncs/availibility-message-section',
                        MainHeading:'Availibility',
                        Subheading:'Message Section',
                        halfcircle:'#601f79'
                    },
                    {
                        url:'/repliapartmentsyncs/extra-availibility-message-section',
                        MainHeading:'Extra Availibility',
                        Subheading:'Message Section',
                        halfcircle:'#ae5e9a'
                    },
                    {
                        url:'/repliapartmentsyncs/no-availibility-message-section',
                        MainHeading:'No Availibility',
                        Subheading:'Message Section',
                        halfcircle:'#df4a71'
                    },
                    {
                        url:'/repliapartmentsyncs/landing-page-configuration',
                        MainHeading:'Landing Page',
                        Subheading:'Configuration',
                        halfcircle:'#ef8088'
                    },
                    {
                        url:'/repliapartmentsyncs/maximum-available-unit-configuration',
                        MainHeading:'Maximum Available',
                        Subheading:'Unit Configuration',
                        halfcircle:'#df9135'
                    },
                    {
                        url:'/repliapartmentsyncs/amenities-page-settings',
                        MainHeading:'Amenities',
                        Subheading:'Page Settings',
                        halfcircle:'#df9135'
                    },
                    {
                        url:'/repliapartmentsyncs/details-page-settings',
                        MainHeading:'Details Page',
                        Subheading:'Settings',
                        halfcircle:'#df9135'
                    },
                    {
                        url:'/repliapartmentsyncs/floor-plan-fields-show-hide',
                        MainHeading:'Floorplan Fields',
                        Subheading:'Show/Hide',
                        halfcircle:'#df9135'
                    },
                    {
                        url:'/repliapartmentsyncs/lease-now-configuration',
                        MainHeading:'Lease Now Configuration',
                        Subheading:'Unit Base',
                        halfcircle:'#df9135'
                    },
                    {
                        url:'/repliapartmentsyncs/unit-list-column-show-hide',
                        MainHeading:'Unit List Column',
                        Subheading:'Show/Hide',
                        halfcircle:'#df9135'
                    },
                    {
                        url:'/repliapartmentsyncs/interactive-leasing',
                        MainHeading:'Interactive',
                        Subheading:'Leasing',
                        halfcircle:'#df9135'
                    },
                    {
                        url:'/repliapartmentsyncs/other-settings',
                        MainHeading:'Other',
                        Subheading:'Settings',
                        halfcircle:'#df9135'
                    },
                    {
                        url:'/repliapartmentsyncs/list-view-sorting-settings',
                        MainHeading:'List View',
                        Subheading:'Sorting Settings',
                        halfcircle:'#df9135'
                    },
                    {
                        url:'/repliapartmentsyncs/show-active-viewes',
                        MainHeading:'Show Active',
                        Subheading:'Viewes',
                        halfcircle:'#df9135'
                    },
                    {
                        url:'/repliapartmentsyncs/display-custom-message-floor-plan-list',
                        MainHeading:'Display Custom Message',
                        Subheading:'Floor Plan List',
                        halfcircle:'#df9135'
                    },
                    {
                        url:'/repliapartmentsyncs/display-custom-message-unit-list',
                        MainHeading:'Display Custom Message',
                        Subheading:'Unit List',
                        halfcircle:'#df9135'
                    },
                    {
                        url:'/repliapartmentsyncs/floor-plan-details-from-real-page',
                        MainHeading:'Floorplan Details From',
                        Subheading:'Real Page',
                        halfcircle:'#df9135'
                    },
                    {
                        url:'/repliapartmentsyncs/floor-plan-group-configuration',
                        MainHeading:'Floorplan Group',
                        Subheading:'Configuration',
                        halfcircle:'#df9135'
                    }
                ]
            }
        }
    };
</script>