<template>
    <div class="404_page">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <h3 class="text-center">404 Page</h3>
          </div>
        </div>
      </div>
    </div>      
    </template>
    
    <script>
    
    export default {
        name:"PageNotFound",
        components: {
      }
    }
    </script>