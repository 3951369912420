<template>
    <div class="container-fluid floorplangroupmanagement">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="general_configuration">
                    <div class="general_configuration_details">
                        <div class="top_pannel">
                            <div class="heading">
                                <h3>Floor Plan Group Management</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        data() {
            return {

            };
        },
        methods: {
            //Action btn panle
            openAction_btn: function () {
                const x = document.getElementById("action_btn_panl");
                if (x.classList.contains("action_btn_panle_active")) {
                    x.classList.remove("action_btn_panle_active");
                } else {
                    x.classList.add("action_btn_panle_active");
                }
            },
        },
    };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
