<template>
    <div class="main_content" id="synchronize">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                </div>
            </div>
        </div>        
    </div>  
     </template>
     
 
     <script>
     export default {
         name:"MainContent",
         components: {
         },
         methods:{

         }     
     }
     </script>