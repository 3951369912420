<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="general_configuration">
                    <div class="general_configuration_details">
                        <div class="top_pannel">
                            <div class="heading">
                                <h3>Real Page RPX</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-header align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">API Type:</h4>
                                        <span class="badge bg-danger align-middle fs-10 text-white ml-1">Required *</span>
                                    </div>
                                    <div class="card-body">
                                        <div class="Page_content">
                                            <div class="row">
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-15">
                                                    <label>API PmcID
                                                        <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                            <svg
                                                                version="1.1"
                                                                id="Layer_1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                x="0px"
                                                                y="0px"
                                                                width="427px"
                                                                height="427px"
                                                                viewBox="0 0 427 427"
                                                                enable-background="new 0 0 427 427"
                                                                xml:space="preserve"
                                                            >
                                                                <g>
                                                                    <polyline
                                                                        fill="none"
                                                                        stroke="#E89C9D"
                                                                        stroke-width="15"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        stroke-miterlimit="10"
                                                                        points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                    />
                                                                    <circle cx="208" cy="129.5" r="15.5" />
                                                                    <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </label>
                                                    <span class="badge bg-danger align-middle fs-10 text-white ml-1">Required *</span>
                                                    <input type="text" placeholder="123456789" />
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-15">
                                                    <label>RPX API Token
                                                        <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                            <svg
                                                                version="1.1"
                                                                id="Layer_1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                x="0px"
                                                                y="0px"
                                                                width="427px"
                                                                height="427px"
                                                                viewBox="0 0 427 427"
                                                                enable-background="new 0 0 427 427"
                                                                xml:space="preserve"
                                                            >
                                                                <g>
                                                                    <polyline
                                                                        fill="none"
                                                                        stroke="#E89C9D"
                                                                        stroke-width="15"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        stroke-miterlimit="10"
                                                                        points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                    />
                                                                    <circle cx="208" cy="129.5" r="15.5" />
                                                                    <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </label>
                                                    <span class="badge bg-danger align-middle fs-10 text-white ml-1">Required *</span>
                                                    <input type="text" placeholder="7EC17120-8C73" />
                                                </div>
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-15">
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                                            <div class="unit_show_group">
                                                                <label>Available Unit Show By (Optional)
                                                                    <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                        <svg
                                                                            version="1.1"
                                                                            id="Layer_1"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                            x="0px"
                                                                            y="0px"
                                                                            width="427px"
                                                                            height="427px"
                                                                            viewBox="0 0 427 427"
                                                                            enable-background="new 0 0 427 427"
                                                                            xml:space="preserve"
                                                                        >
                                                                            <g>
                                                                                <polyline
                                                                                    fill="none"
                                                                                    stroke="#E89C9D"
                                                                                    stroke-width="15"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-miterlimit="10"
                                                                                    points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                                />
                                                                                <circle cx="208" cy="129.5" r="15.5" />
                                                                                <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                </label>
                                                                <div class="custom_check-box"><input type="checkbox" id="applicable" /><label for="applicable">Value of Available Bit</label></div>
                                                                <div class="radio-box">
                                                                    <ul>
                                                                        <li>
                                                                            <input type="radio" id="test1" name="radio-group" checked>
                                                                            <label for="test1">True</label>
                                                                        </li>
                                                                        <li>
                                                                            <input type="radio" id="test2" name="radio-group">
                                                                            <label for="test2">False</label>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                                                            <div class="unit_show_group">
                                                                <label>Available Unit Show By (Optional)
                                                                    <span class="info" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                                        <svg
                                                                            version="1.1"
                                                                            id="Layer_1"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                            x="0px"
                                                                            y="0px"
                                                                            width="427px"
                                                                            height="427px"
                                                                            viewBox="0 0 427 427"
                                                                            enable-background="new 0 0 427 427"
                                                                            xml:space="preserve"
                                                                        >
                                                                            <g>
                                                                                <polyline
                                                                                    fill="none"
                                                                                    stroke="#E89C9D"
                                                                                    stroke-width="15"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    stroke-miterlimit="10"
                                                                                    points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                                                />
                                                                                <circle cx="208" cy="129.5" r="15.5" />
                                                                                <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                </label>
                                                                <div class="custom_check-box"><input type="checkbox" id="applicable" /><label for="applicable">Value of Vacant Bit</label></div>
                                                                <div class="radio-box">
                                                                    <ul>
                                                                        <li>
                                                                            <input type="radio" id="test1" name="radio-group" checked>
                                                                            <label for="test1">True</label>
                                                                        </li>
                                                                        <li>
                                                                            <input type="radio" id="test2" name="radio-group">
                                                                            <label for="test2">False</label>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="update_bottom">
                    <ul>
                        <li><span>*</span> Press update button to save this changes</li>
                        <li>
                            <router-link to="/"><button class="update_btn">Update</button></router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <LeftPreviewPanel/> -->
        </div>
    </div>
</template>

          
<script>
    import LeftPreviewPanel from "@/views/LeftPreviewPanel.vue";
    import Multiselect from '@vueform/multiselect'

    export default {
        components: {
          LeftPreviewPanel,
          Multiselect,
        },
        data() {
            return {
              //Tabs
              tab: 'tab1',
              tab: 'tab2',
  
              //FloorPlan
              FloorPlan: {
                mode: 'single',
                value: 'Custom',
                options: [
                    { value: 'Custom', label: 'Custom' },
                    { value: 'API - FloorPlanName', label: 'API - FloorPlanName' },
                    { value: 'API - FloorPlanNameMarketing', label: 'API - FloorPlanNameMarketing' },
                    { value: 'API - FloorPlanGroupName', label: 'API - FloorPlanGroupName' }
                ],
                searchable: false,
                createTag: false
            },
            //Lease Term
            LeaseTerm: {
                mode: 'single',
                value: 'Custom',
                options: [
                    { value: 'Lease Term - 12 (LeaseTermMin - 09 | LeaseTermMax - 15)', label: 'Lease Term - 12 (LeaseTermMin - 09 | LeaseTermMax - 15)' },
                    { value: 'Lease Term - 11 (LeaseTermMin - 08 | LeaseTermMax - 14)', label: 'Lease Term - 11 (LeaseTermMin - 08 | LeaseTermMax - 14)' },
                    { value: 'Lease Term - 10 (LeaseTermMin - 07 | LeaseTermMax - 13)', label: 'Lease Term - 10 (LeaseTermMin - 07 | LeaseTermMax - 13)' },
                    { value: 'Lease Term - 09 (LeaseTermMin - 06 | LeaseTermMax - 12)', label: 'Lease Term - 09 (LeaseTermMin - 06 | LeaseTermMax - 12)' },
                    { value: 'Lease Term - 08 (LeaseTermMin - 05 | LeaseTermMax - 11)', label: 'Lease Term - 08 (LeaseTermMin - 05 | LeaseTermMax - 11)' }
                ],
                searchable: false,
                createTag: false
            },
            //Lease Term Month
            LeaseTermMonth: {
                mode: 'single',
                value: '12 Months',
                options: [
                    { value: '2 Months', label: '2 Months' },
                    { value: '3 Months ', label: '3 Months' },
                    { value: '4 Months ', label: '4 Months ' },
                    { value: '5 Months ', label: '5 Months ' },
                    { value: '5 Months ', label: '6 Months ' }
                ],
                searchable: false,
                createTag: false
            },
            };
        },
    };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>