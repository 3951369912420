<template>
    <div class="container-fluid floorplantype">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="general_configuration">
                    <div class="general_configuration_details">
                        <div class="top_pannel">
                            <div class="heading">
                                <h3>Floorplan Type List</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="card card-height-100">
                                    <div class="card-header align-items-center d-flex">
                                        <div class="action_btn">
                                            <ul>
                                                <li><router-link to="" class="company_btn">Add New Floorplan Type</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div class="dataTable">
                                                    <div class="dataTable_top">
                                                        <ul>
                                                            <li>
                                                                <div class="list_length"><Multiselect v-model="list.value" v-bind="list" placeholder="5" /></div>
                                                            </li>
                                                            <li>
                                                                <div class="dataTables_length">Showing 1 to 10 of <span>1,087</span></div>
                                                            </li>
                                                            <li>
                                                                <div class="search">
                                                                    <input type="text" placeholder="Search Here...." />
                                                                    <div class="search_icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                            <path
                                                                                d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"
                                                                            />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="sorting">
                                                                    <div class="assanding">
                                                                        <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect fill="none" height="256" width="256" />
                                                                            <polyline fill="none" points="144 168 184 208 224 168" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" />
                                                                            <line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" x1="184" x2="184" y1="112" y2="208" />
                                                                            <line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" x1="48" x2="120" y1="128" y2="128" />
                                                                            <line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" x1="48" x2="184" y1="64" y2="64" />
                                                                            <line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" x1="48" x2="104" y1="192" y2="192" />
                                                                        </svg>
                                                                    </div>
                                                                    <div class="disanding">
                                                                        <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect fill="none" height="256" width="256" />
                                                                            <polyline fill="none" points="144 88 184 48 224 88" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" />
                                                                            <line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" x1="184" x2="184" y1="144" y2="48" />
                                                                            <line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" x1="48" x2="120" y1="128" y2="128" />
                                                                            <line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" x1="48" x2="104" y1="64" y2="64" />
                                                                            <line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" x1="48" x2="184" y1="192" y2="192" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="show_action_icon">
                                                                    <div class="show_action_icon_panle">
                                                                        <input class="styled-checkbox" id="styled-checkbox-2" type="checkbox" value="value2" />
                                                                        <label for="styled-checkbox-2">Show All Action Icons</label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="table-responsive">
                                                        <table class="table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Sl No</th>
                                                                    <th scope="col">Plan Name</th>
                                                                    <th scope="col">Description</th>
                                                                    <th scope="col">Status</th>
                                                                    <th scope="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="items in apartmentsyncsitelist" :key="items">
                                                                    <td>{{items.SlNo}}</td>
                                                                    <td>{{items.PlanName}}</td>
                                                                    <td>{{items.Description}}</td>
                                                                    <td>{{items.Status}}</td>
                                                                    <td>
                                                                        <div class="action_btn" @click="openAction_btn()">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                                                <path
                                                                                    d="M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zM120 96c0 30.9-25.1 56-56 56S8 126.9 8 96S33.1 40 64 40s56 25.1 56 56z"
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                        <div class="action_btn_panle" id="action_btn_panl">
                                                                            <div class="action_btn_group">
                                                                                <ul>
                                                                                    <li><div class="icons"><svg viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg"><path d="M416 31.94C416 21.75 408.1 0 384.1 0c-13.98 0-26.87 9.072-30.89 23.18l-128 448c-.8404 2.935-1.241 5.892-1.241 8.801C223.1 490.3 232 512 256 512c13.92 0 26.73-9.157 30.75-23.22l128-448C415.6 37.81 416 34.85 416 31.94zM176 143.1c0-18.28-14.95-32-32-32c-8.188 0-16.38 3.125-22.62 9.376l-112 112C3.125 239.6 0 247.8 0 255.1S3.125 272.4 9.375 278.6l112 112C127.6 396.9 135.8 399.1 144 399.1c17.05 0 32-13.73 32-32c0-8.188-3.125-16.38-9.375-22.63L77.25 255.1l89.38-89.38C172.9 160.3 176 152.2 176 143.1zM640 255.1c0-8.188-3.125-16.38-9.375-22.63l-112-112C512.4 115.1 504.2 111.1 496 111.1c-17.05 0-32 13.73-32 32c0 8.188 3.125 16.38 9.375 22.63l89.38 89.38l-89.38 89.38C467.1 351.6 464 359.8 464 367.1c0 18.28 14.95 32 32 32c8.188 0 16.38-3.125 22.62-9.376l112-112C636.9 272.4 640 264.2 640 255.1z"></path></svg></div></li>
                                                                                    <li>
                                                                                        <div class="icons">
                                                                                            <svg viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path
                                                                                                    d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"
                                                                                                />
                                                                                            </svg>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            <div class="right_action_panle">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                                                                                    <path
                                                                                        d="M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zM120 96c0 30.9-25.1 56-56 56S8 126.9 8 96S33.1 40 64 40s56 25.1 56 56z"
                                                                                    />
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <nav class="data-pagination" data-pagination>
                                                        <a a href="#" disabled>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                                <path
                                                                    d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
                                                                />
                                                            </svg>
                                                        </a>
                                                        <ul>
                                                            <li><a href="#">1</a></li>
                                                            <li><a href="#">2</a></li>
                                                            <li><a href="#">3</a></li>
                                                            <li><a href="#">4</a></li>
                                                            <li class="current"><a a href="#">5</a></li>
                                                            <li><a a href="#">…</a></li>
                                                            <li><a a href="#">55</a></li>
                                                        </ul>
                                                        <a a href="#">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                                <path
                                                                    d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
                                                                />
                                                            </svg>
                                                        </a>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from "@vueform/multiselect";

    export default {
        components: {
            Multiselect,
        },
        data() {
            return {
                //Company Dropdown
                list: {
                    mode: "single",
                    value: "5",
                    options: [
                        { value: "10", label: "10" },
                        { value: "25", label: "25" },
                        { value: "50", label: "50" },
                        { value: "100", label: "100" },
                    ],
                    searchable: false,
                    createTag: false,
                },
                apartmentsyncsitelist: [
                    {
                        SlNo: "1",
                        PlanName: "	Apartment",
                        Description: "Apartment Home",
                        Status: "Active",
                    },
                    {
                        SlNo: "2",
                        PlanName: "Penthouse",
                        Description: "Penthouse",
                        Status: "Active",
                    },
                    {
                        SlNo: "3",
                        PlanName: "Townhome",
                        Description: "Townhome",
                        Status: "Active",
                    },
                ],
            };
        },
        methods: {
            //Action btn panle
            openAction_btn: function () {
                const x = document.getElementById("action_btn_panl");
                if (x.classList.contains("action_btn_panle_active")) {
                    x.classList.remove("action_btn_panle_active");
                } else {
                    x.classList.add("action_btn_panle_active");
                }
            },
        },
    };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
