<template>
    <div class="container-fluid d-flex">
        <div class="general_configuration">
            <div class="general_configuration_details">
                <div class="top_pannel">
                    <div class="heading">
                        <h3>CTA Management</h3>
                    </div>
                    <div class="check-box"><label>Show Preview</label><input type="checkbox" v-model="has_preview"/></div>
                </div>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div class="card card-height-100">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                                        <div class="form-group">
                                            <label class="d-flex flex-wrap align-items-center">
                                                Contact Us and View Button Toggle:
                                                <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                    <svg
                                                        version="1.1"
                                                        id="Layer_1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        x="0px"
                                                        y="0px"
                                                        width="427px"
                                                        height="427px"
                                                        viewBox="0 0 427 427"
                                                        enable-background="new 0 0 427 427"
                                                        xml:space="preserve"
                                                    >
                                                        <g>
                                                            <polyline
                                                                fill="none"
                                                                stroke="#E89C9D"
                                                                stroke-width="15"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-miterlimit="10"
                                                                points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                            />
                                                            <circle cx="208" cy="129.5" r="15.5" />
                                                            <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <span class="small text-danger ml-auto">Required *</span>
                                            </label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                                        <div class="form-group">
                                            <label>View Details Button Enable:</label>
                                            <div class="check-box"><input type="checkbox" v-model="ViewButton"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                                        <div class="form-group">
                                            <label>View Details Button Text:</label>
                                            <input type="text" placeholder="View Details" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                                        <div class="form-group">
                                            <label>Open in New window:</label>
                                            <div class="check-box"><input type="checkbox"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                                        <div class="form-group">
                                            <label>Contact Us Button Enable:</label>
                                            <div class="check-box"><input type="checkbox" v-model="ButtonStatus"></div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                                        <div class="form-group">
                                            <label>Contact Us Button Text:</label>
                                            <input type="text" placeholder="Request More Info " />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div class="card">
                            <div class="card-header align-items-center d-flex">
                                <h4 class="card-title mb-0 flex-grow-1">Only For Template 4 Left Section</h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group mb-0">
                                            <label class="d-flex flex-wrap align-items-center">
                                                Info:
                                                <span class="info ml-2" flow="right" tooltip="This is the number of link clicks that we estimate you can get each day based on your campaign performance and estimated daily reach. The actual number of link clicks that you get may be higher or lower than this estimate.">
                                                <svg
                                                    version="1.1"
                                                    id="Layer_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    width="427px"
                                                    height="427px"
                                                    viewBox="0 0 427 427"
                                                    enable-background="new 0 0 427 427"
                                                    xml:space="preserve"
                                                >
                                                    <g>
                                                        <polyline
                                                            fill="none"
                                                            stroke="#E89C9D"
                                                            stroke-width="15"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            stroke-miterlimit="10"
                                                            points="191.5,200 212.5,200 212.5,309 232.5,309 	"
                                                        />
                                                        <circle cx="208" cy="129.5" r="15.5" />
                                                        <circle fill="none" stroke="#E89C9D" stroke-width="20" stroke-miterlimit="10" cx="212" cy="216.5" r="187.5" />
                                                    </g>
                                                </svg>
                                                </span>
                                                <span class="small text-danger ml-3">Required *</span>
                                            </label>
                                            <div class="d-flex mt-3">
                                                <div class="custom_check-box mr-4"><input type="checkbox" id="gallery_cta"><label for="gallery_cta">Gallery CTA</label></div>
                                                <div class="custom_check-box mr-4"><input type="checkbox" id="virtual_tour_cta"><label for="virtual_tour_cta">Virtual Tour CTA</label></div>
                                                <div class="custom_check-box mr-4"><input type="checkbox" id="video_icon"><label for="video_icon">Video Icon On Image</label></div>
                                                <div class="custom_check-box mr-4"><input type="checkbox" id="3d_on_img"><label for="3d_on_img">3D On Image</label></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row position-relative" v-for="(cta, c) in cta_list" :key="c" :class="{'mt-4' : c !== 0}">
                                    <div class="remove_btn" @click="removeCTA(c)" v-if="c !== 0">Remove</div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                        <div class="form-group">
                                            <label>CTA type:</label>
                                            <div class="select_fld">
                                                <Multiselect v-model="cta.cta_type.value" v-bind="cta.cta_type" placeholder="Select" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                        <div class="form-group">
                                            <label>CTA Button Text:</label>
                                            <input type="text" placeholder="Contact Options" v-model="cta.cta_btn_text"/>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                        <div class="form-group">
                                            <label>Enter CTA URL/Javascript function/duda popup id:</label>
                                            <input type="text" placeholder="Contact Options " v-model="cta.cta_config"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="add_more mt-3">
                                    <button class="default_btn" @click="addNewCTA">
                                        + Add More CTA 
                                    </button>
                                </div>

                                <!-- <div class="more_cta">
                                    <div class="row">
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                            <label>CTA type:</label>
                                            <div class="select_fld">
                                                <Multiselect v-model="CtaType.value" v-bind="CtaType" placeholder="Select" />
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                            <label>CTA Button Text:</label>
                                            <input type="text" placeholder="Contact Options " />
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                            <label>Enter CTA URL/Javascript function/duda popup id:</label>
                                            <input type="text" placeholder="Contact Options " />
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="update_bottom">
                <ul>
                    <li><span>*</span> Press update button to save this changes</li>
                    <li>
                        <router-link to="/"><button class="update_btn">Update</button></router-link>
                    </li>
                </ul>
            </div>
        </div>
        <LeftPreviewPanel v-model="has_preview"/>
    </div>
</template>

          
<script>
    import LeftPreviewPanel from "@/views/LeftPreviewPanel.vue"
    import Multiselect from '@vueform/multiselect'; 
    export default {
        components: {
            Multiselect,
            LeftPreviewPanel
        },
        data() {
            return {
                ViewButton: false,
                ButtonStatus: false,
                has_preview: false,
                cta_list:[
                    {
                        cta_type: {
                            mode: 'single',
                            value: 'Select',
                            options: [
                                { value: 'HTML', label: 'HTML' },
                                { value: 'Javascript Function', label: 'Javascript Function' },
                                { value: 'Duda Site Popup', label: 'Duda Site Popup' },
                                { value: 'API', label: 'API' },
                            ],
                            searchable: false,
                        },
                        cta_btn_text: '',
                        cta_config: ''
                    }
                ]
            };
        },
        methods:{
            addNewCTA(){
                const vm = this;

                vm.cta_list.push({
                    cta_type: {
                        mode: 'single',
                        value: 'Select',
                        options: [
                            { value: 'HTML', label: 'HTML' },
                            { value: 'Javascript Function', label: 'Javascript Function' },
                            { value: 'Duda Site Popup', label: 'Duda Site Popup' },
                            { value: 'API', label: 'API' },
                        ],
                        searchable: false,
                    },
                    cta_btn_text: '',
                    cta_config: ''
                })
            },
            removeCTA(el){
                const vm = this;

                vm.cta_list.splice(el, 1);
            },
        }
    };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>